
export const scrollingAnimation = (a, b) => {
  // let u = navigator.userAgent;
  // let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
  // 判断当前是否在安卓手机中，和是否在钉钉中

  // console.log($myGlobalValue ) 
  // alert(b)
  // return
  // console.log(a, b)
  if (b) {

    let stop = {}
    // 执行您希望在这里执行的操作
    document.body.addEventListener("scroll", function () {
      // window.addEventListener("scroll", function () {
      // console.log(132456789)
      //页面滚动时候调用配置动画函数
      var elements = document.querySelectorAll('[data-class],[data-animation]');
      var elementsArray = Array.prototype.slice.call(elements);
      elementsArray.forEach(function (element) {
        isInViewPortOfOne(element)
      });
      // 页面滚动时候调用判断ip人物的显示隐藏函数
      var hides = document.querySelectorAll('[data-hide]');
      var hidesArray = Array.prototype.slice.call(hides);
      hideIp(hidesArray)


      //页面滚动时候调用配置动画函数
      var pages = document.querySelectorAll('[data-pages]');
      var pagesArray = Array.prototype.slice.call(pages);
      pagesArray.forEach(function (s) {
        pagesStop(s)
      });

      let progres_x = document.getElementsByClassName('progres_x')[0]
      progre(progres_x)
    });


    // 页面中事件缓冲
    let stp
    function pagesStop(s) {

      // 获取当前的可视窗口高度 (viewPortHeight 兼容所有浏览器写法)
      const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      const scoordinate = s.getBoundingClientRect()
      let coordinateStop = (viewPortHeight - scoordinate.height) / 2

      // console.log(coordinateStop)
      // if((-scoordinate.y)>(scoordinate.height-200)){
      //   stop[s.getAttribute('data-pages')]=false
      // }
      // data-pages-shifting
      // 默认偏移值为0// 如何用户设置了偏移值就给元素赋值
      let shifting = 0
      if (s.getAttribute('data-pages-shifting')) {
        shifting = Number(s.getAttribute('data-pages-shifting'))
      }
      if (stop[s.getAttribute('data-pages')]) {


      } else {
        if ((scoordinate.y + 300 > coordinateStop + shifting) && (scoordinate.y - 300 < coordinateStop + shifting)) {


          stop[s.getAttribute('data-pages')] = true
          let tops = document.body.scrollTop + (scoordinate.y - coordinateStop - shifting)

          document.body.scrollTo({
            top: tops,
            behavior: 'smooth'
          })

          // let ua = navigator.userAgent.toLowerCase(); //判断浏览器的类型
          // if (ua.indexOf("micromessenger") > -1) {
          // document.body.addEventListener("scroll", function () {
          //   // console.log(document.body.scrollTop,tops)
          //   if(document.body.scrollTop+2>tops&&document.body.scrollTop-2<tops){

          //     if(stop[s.getAttribute('data-pages')+'a']){

          //     }else{
          //       stop[s.getAttribute('data-pages')+'a'] = true
          //       document.body.style.overflow = 'hidden'
          //       // document.getElementsByClassName('preventSliding')[0].style.display='none'
          //       setTimeout(() => {
          //         document.body.style.overflowY = 'auto'
          //       }, 2000)
          //     }

          //   }
          // })
          // } else {
          document.body.style.overflow = 'hidden'
          setTimeout(() => {
            document.body.style.overflowY = 'auto'
          }, 2000)
          // }



        }
      }

    }
    function transitionDuration(a) {
      // console.log(document.body.scrollTop, stp)
      if (document.body.scrollTop > stp) {
        if (document.body.scrollTop + 1 > stp && document.body.scrollTop - 1 < stp) {
          // document.body.scrollTop= stp
          document.body.scrollTo({
            top: stp,
            behavior: 'smooth',
          });
        } else {
          if (document.body.scrollTop != 0) {
            if ((document.body.scrollTop - stp) < 10) {
              document.body.scrollTop = document.body.scrollTop - 1
              document.body.requestAnimationFrame(transitionDuration);
            } else {
              document.body.scrollTop = document.body.scrollTop - 3
              document.body.requestAnimationFrame(transitionDuration);
            }

          }

        }

      }
      if (document.body.scrollTop < stp) {
        if (document.body.scrollTop + 1 > stp && document.body.scrollTop - 1 < stp) {
          // document.body.scrollTop= stp
          document.body.scrollTo({
            top: stp,
            behavior: 'smooth',
          });
        } else {
          if ((stp - document.body.scrollTop) < 10) {
            document.body.scrollTop = document.body.scrollTop + 1
            document.body.requestAnimationFrame(transitionDuration);
          } else {
            document.body.scrollTop = document.body.scrollTop + 3
            document.body.requestAnimationFrame(transitionDuration);
          }

        }
      }

    }

    // 
    function progre(a) {
      // 获取当前的可视窗口高度 (viewPortHeight 兼容所有浏览器写法)
      const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      // 当前页面中内容的高度
      const homeElement = document.getElementById('home').scrollHeight
      // 当前页面的滚动的高度
      const scrollTop = document.body.scrollTop
      // 视频页面的高度
      let pages0 = document.getElementsByClassName('pages0')[0].getBoundingClientRect().height
      // console.log((homeElement-viewPortHeight),scrollTop)
      let height = (scrollTop - pages0) / ((homeElement - viewPortHeight - pages0) / 100)
      // console.log(height)
      // if (scrollTop <= homeElement - viewPortHeight - pages0) {
      a.style.height = `${100 - height}%`
      // }
      // console.log((document.getElementsByClassName('pages0')[0].getBoundingClientRect().y)-pages0)
      // if (((document.getElementsByClassName('pages0')[0].getBoundingClientRect().y) + pages0) < 0) {
      //   document.getElementsByClassName('progresa')[0].classList.add(`progresa_s`)
      // } else {
      //   document.getElementsByClassName('progresa')[0].classList.remove(`progresa_s`)
      // }
      let tt = (viewPortHeight - document.getElementsByClassName('pages1')[0].getBoundingClientRect().height) / 2
      // let ua = navigator.userAgent.toLowerCase(); //判断浏览器的类型
      // if (ua.indexOf("micromessenger") > -1) {

      // }else{
      if (((document.getElementsByClassName('pages1')[0].getBoundingClientRect().y)) <= tt + 20) {
        document.getElementsByClassName('progresa')[0].classList.add(`progresa_s`)
      } else {
        document.getElementsByClassName('progresa')[0].classList.remove(`progresa_s`)
      }
      // }



    }


    // 判断是否要隐藏ip
    function hideIp(item) {
      const ips = document.getElementsByClassName('logoIp')[0]
      const ip = document.getElementsByClassName('logoIp')[0].getBoundingClientRect()
      let display = true
      for (var i = 0; i < item.length; i++) {
        let coordinate = item[i].getBoundingClientRect()
        let ipOffset = 0
        ipOffset = Number(item[i].getAttribute('data-hide'))
        if (((coordinate.y + coordinate.height) >= (ip.y - ipOffset)) && ((ip.height + ip.y) >= (coordinate.y - ipOffset))) {
          display = false
        } else {
        }
      }
      if (!display) {
        ips.style.opacity = 0.5
        ips.style.transition = `opacity 1s`
      } else {
        ips.style.opacity = 1
        ips.style.transition = `opacity 1s`
      }
    }

    // 动画配置
    function isInViewPortOfOne(el) {
      // 获取当前的可视窗口高度 (viewPortHeight 兼容所有浏览器写法)
      const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      const offsetTop = el.getBoundingClientRect()
      // 动画名赋值
      let animation = ''
      if (el.getAttribute('data-animation')) {
        animation = el.getAttribute('data-animation')
      }

      // class名赋值
      let dclass = ''
      if (el.getAttribute('data-class')) {
        dclass = el.getAttribute('data-class')
      }

      // 动画执行的时间
      let time = '1'
      if (el.getAttribute('data-time')) {
        time = el.getAttribute('data-time')
      }

      // 默认偏移值为0// 如何用户设置了偏移值就给元素赋值
      let offset = 0
      if (el.getAttribute('data-offset')) {
        offset = Number(el.getAttribute('data-offset'))
      }

      // if ((offsetTop.y + offsetTop.height > offset) && (offsetTop.y < viewPortHeight)) {
      if (a == 'upward') {
        if ((offsetTop.y + offsetTop.height > offset)) {
          // 执行动画
          if (dclass != '') {
            el.classList.remove(`remove_${dclass}`) // 移除
            el.classList.add(`start_${dclass}`) //添加
          } else {
            el.style.animation = `start_${animation} ${time}s  linear forwards`
          }
          if (dclass == 'village_box') {
            setTime(true)
          }
          if (dclass == 'trafficLight') {
            setTime2s(true)
          }
        } else {
          // 取消动画
          if (dclass != '') {
            el.classList.remove(`start_${dclass}`) // 移除
            el.classList.add(`remove_${dclass}`)//添加
          } else {
            el.style.animationName = `remove_${animation}`
          }
          if (dclass == 'village_box') {
            setTime(false)

          }
          if (dclass == 'trafficLight') {
            setTime2s(false)
          }
        }
      } else {
        if ((offsetTop.y + offset < viewPortHeight)) {
          // 执行动画
          if (dclass != '') {
            el.classList.remove(`remove_${dclass}`) // 移除
            el.classList.add(`start_${dclass}`) //添加
          } else {
            el.style.animation = `start_${animation} ${time}s  linear forwards`
          }
          if (dclass == 'village_box') {
            setTime(true)
          }
          if (dclass == 'trafficLight') {
            setTime2s(true)
          }
        } else {
          // 取消动画
          if (dclass != '') {
            el.classList.remove(`start_${dclass}`) // 移除
            el.classList.add(`remove_${dclass}`)//添加
          } else {
            el.style.animationName = `remove_${animation}`
          }
          if (dclass == 'village_box') {
            setTime(false)

          }
          if (dclass == 'trafficLight') {
            setTime2s(false)
          }
        }
      }

    }
    let timer2s = null
    let progress2s = false
    function setTime2s(a) {
      if (a && !progress2s) {
        progress2s = true
        timer2s = setInterval(() => {
          // console.log(document.getElementById('kilometers').innerHTML)
          document.getElementById('lightsSum').innerHTML = (Number(document.getElementById('lightsSum').innerHTML) - 1)
          if (document.getElementById('lightsSum').innerHTML <= 0) {
            document.getElementById('lightsSum').innerHTML = 60
          }
          if (document.getElementById('lightsSum').innerHTML == 55) {
            clearInterval(timer2s)
            progress2s = false
          }
        }, 1000)
      }
      if (!a) {
        clearInterval(timer2s)
        document.getElementById('lightsSum').innerHTML = 3
        progress2s = false

      }
    }

    let timer = null
    let progress = false
    function setTime(a) {
      if (a && !progress) {
        progress = true
        timer = setInterval(() => {
          // console.log(document.getElementById('kilometers').innerHTML)
          document.getElementById('kilometers').innerHTML = (Number(document.getElementById('kilometers').innerHTML) - 0.1).toFixed(1)
          if (document.getElementById('kilometers').innerHTML <= 0) {
            document.getElementById('kilometers').innerHTML = 0
            clearInterval(timer)
            progress = false
          }
        }, 200)
      }
      if (!a) {
        clearInterval(timer)
        document.getElementById('kilometers').innerHTML = 2.1
        progress = false

      }
      // console.log(a, 123)
    }

  } else {

    // console.log(123)
    let stop = {}
    // 执行您希望在这里执行的操作
    // document.body.addEventListener("scroll", function () {
    window.addEventListener("scroll", function () {
      // console.log(132456789)
      //页面滚动时候调用配置动画函数
      var elements = document.querySelectorAll('[data-class],[data-animation]');
      var elementsArray = Array.prototype.slice.call(elements);
      elementsArray.forEach(function (element) {
        isInViewPortOfOne(element)
      });
      // 页面滚动时候调用判断ip人物的显示隐藏函数
      var hides = document.querySelectorAll('[data-hide]');
      var hidesArray = Array.prototype.slice.call(hides);
      hideIp(hidesArray)


      //页面滚动时候调用配置动画函数
      var pages = document.querySelectorAll('[data-pages]');
      var pagesArray = Array.prototype.slice.call(pages);
      pagesArray.forEach(function (s) {
        pagesStop(s)
      });

      let progres_x = document.getElementsByClassName('progres_x')[0]
      progre(progres_x)
    });


    // 页面中事件缓冲
    let stp
    function pagesStop(s) {

      // 获取当前的可视窗口高度 (viewPortHeight 兼容所有浏览器写法)
      const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      const scoordinate = s.getBoundingClientRect()
      let coordinateStop = (viewPortHeight - scoordinate.height) / 2
      // if((-scoordinate.y)>(scoordinate.height-200)){
      //   stop[s.getAttribute('data-pages')]=false
      // }
      // data-pages-shifting
      // 默认偏移值为0// 如何用户设置了偏移值就给元素赋值
      let shifting = 0
      if (s.getAttribute('data-pages-shifting')) {
        shifting = Number(s.getAttribute('data-pages-shifting'))
      }
      if (stop[s.getAttribute('data-pages')]) {


      } else {
        if ((scoordinate.y + 300 > coordinateStop + shifting) && (scoordinate.y - 300 < coordinateStop + shifting)) {
          // console.log( document.documentElement.scrollTop)
          console.log(s.getAttribute('data-pages'))
          stop[s.getAttribute('data-pages')] = true
          let tops = document.documentElement.scrollTop + (scoordinate.y - coordinateStop - shifting)
          // document.documentElement.scrollTop=tops


          let ua = navigator.userAgent.toLowerCase(); //判断浏览器的类型
          let u = navigator.userAgent;
          let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
          if (ua.indexOf("micromessenger") > -1&&isAndroid) {

          } else {
            window.scrollTo({
              top: tops,
              behavior: 'smooth',
              // transitionDuration: '3s' // 设置滚动动画的持续时间为2秒  
            })
            document.body.style.overflow = 'hidden'
            setTimeout(() => {
              document.body.style.overflowY = 'auto'
            }, 2000)
          }


          // return
          // let ua = navigator.userAgent.toLowerCase(); //判断浏览器的类型
          // if (ua.indexOf("micromessenger") > -1) {
          // setTimeout(()=>{
          //   window.scrollTo({
          //     top: tops,
          //     behavior: 'smooth',
          //     // transitionDuration: '3s' // 设置滚动动画的持续时间为2秒  
          //   });
          // },10)
          //   $("html,body").animate({
          //     scrollTop: tops
          // }, 500)

          // stp = tops
          // window.requestAnimationFrame(transitionDuration);



        }
      }

    }
    function transitionDuration(a) {
      // console.log(document.documentElement.scrollTop, stp)
      if (document.documentElement.scrollTop > stp) {
        if (document.documentElement.scrollTop + 1 > stp && document.documentElement.scrollTop - 1 < stp) {
          // document.documentElement.scrollTop= stp
          window.scrollTo({
            top: stp,
            behavior: 'smooth',
          });
        } else {
          if (document.documentElement.scrollTop != 0) {
            if ((document.documentElement.scrollTop - stp) < 10) {
              document.documentElement.scrollTop = document.documentElement.scrollTop - 1
              window.requestAnimationFrame(transitionDuration);
            } else {
              document.documentElement.scrollTop = document.documentElement.scrollTop - 3
              window.requestAnimationFrame(transitionDuration);
            }

          }

        }

      }
      if (document.documentElement.scrollTop < stp) {
        if (document.documentElement.scrollTop + 1 > stp && document.documentElement.scrollTop - 1 < stp) {
          // document.documentElement.scrollTop= stp
          window.scrollTo({
            top: stp,
            behavior: 'smooth',
          });
        } else {
          if ((stp - document.documentElement.scrollTop) < 10) {
            document.documentElement.scrollTop = document.documentElement.scrollTop + 1
            window.requestAnimationFrame(transitionDuration);
          } else {
            document.documentElement.scrollTop = document.documentElement.scrollTop + 3
            window.requestAnimationFrame(transitionDuration);
          }

        }
      }

    }

    // 
    function progre(a) {
      // 获取当前的可视窗口高度 (viewPortHeight 兼容所有浏览器写法)
      const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      // 当前页面中内容的高度
      const homeElement = document.getElementById('home').scrollHeight
      
      
      
      // 当前页面的滚动的高度
      let scrollTop = document.documentElement.scrollTop
      let ua = navigator.userAgent.toLowerCase(); //判断浏览器的类型
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      if (ua.indexOf("micromessenger") > -1&&isAndroid) {
        scrollTop=document.body.scrollTop
      }
      
      // 视频页面的高度
      let pages0 = document.getElementsByClassName('pages0')[0].getBoundingClientRect().height
      // console.log(((homeElement - viewPortHeight-pages0) / 100),scrollTop)
      let height = (scrollTop - pages0) / ((homeElement - viewPortHeight - pages0) / 100)
      // console.log(height)
      // if (scrollTop <= homeElement - viewPortHeight - pages0) {
      a.style.height = `${100 - height}%`
      // }
      // console.log((document.getElementsByClassName('pages0')[0].getBoundingClientRect().y)-pages0)

      let tt = (viewPortHeight - document.getElementsByClassName('pages1')[0].getBoundingClientRect().height) / 2
      if (((document.getElementsByClassName('pages1')[0].getBoundingClientRect().y)) <= tt + 20) {
        document.getElementsByClassName('progresa')[0].classList.add(`progresa_s`)
      } else {
        document.getElementsByClassName('progresa')[0].classList.remove(`progresa_s`)
      }

      // if (((document.getElementsByClassName('pages0')[0].getBoundingClientRect().y) + pages0) < 0) {
      //   document.getElementsByClassName('progresa')[0].classList.add(`progresa_s`)
      // } else {
      //   document.getElementsByClassName('progresa')[0].classList.remove(`progresa_s`)
      // }


    }


    // 判断是否要隐藏ip
    function hideIp(item) {
      const ips = document.getElementsByClassName('logoIp')[0]
      const ip = document.getElementsByClassName('logoIp')[0].getBoundingClientRect()
      let display = true
      for (var i = 0; i < item.length; i++) {
        let coordinate = item[i].getBoundingClientRect()
        let ipOffset = 0
        ipOffset = Number(item[i].getAttribute('data-hide'))
        if (((coordinate.y + coordinate.height) >= (ip.y - ipOffset)) && ((ip.height + ip.y) >= (coordinate.y - ipOffset))) {
          display = false
        } else {
        }
      }
      if (!display) {
        ips.style.opacity = 0.5
        ips.style.transition = `opacity 1s`
      } else {
        ips.style.opacity = 1
        ips.style.transition = `opacity 1s`
      }
    }

    // 动画配置
    function isInViewPortOfOne(el) {
      // 获取当前的可视窗口高度 (viewPortHeight 兼容所有浏览器写法)
      const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      const offsetTop = el.getBoundingClientRect()
      // 动画名赋值
      let animation = ''
      if (el.getAttribute('data-animation')) {
        animation = el.getAttribute('data-animation')
      }

      // class名赋值
      let dclass = ''
      if (el.getAttribute('data-class')) {
        dclass = el.getAttribute('data-class')
      }

      // 动画执行的时间
      let time = '1'
      if (el.getAttribute('data-time')) {
        time = el.getAttribute('data-time')
      }

      // 默认偏移值为0// 如何用户设置了偏移值就给元素赋值
      let offset = 0
      if (el.getAttribute('data-offset')) {
        offset = Number(el.getAttribute('data-offset'))
      }

      // if ((offsetTop.y + offsetTop.height > offset) && (offsetTop.y < viewPortHeight)) {
      if (a == 'upward') {
        if ((offsetTop.y + offsetTop.height > offset)) {
          // 执行动画
          if (dclass != '') {
            el.classList.remove(`remove_${dclass}`) // 移除
            el.classList.add(`start_${dclass}`) //添加
          } else {
            el.style.animation = `start_${animation} ${time}s  linear forwards`
          }
          if (dclass == 'village_box') {
            setTime(true)
          }
          if (dclass == 'trafficLight') {
            setTime2s(true)
          }
        } else {
          // 取消动画
          if (dclass != '') {
            el.classList.remove(`start_${dclass}`) // 移除
            el.classList.add(`remove_${dclass}`)//添加
          } else {
            el.style.animationName = `remove_${animation}`
          }
          if (dclass == 'village_box') {
            setTime(false)

          }
          if (dclass == 'trafficLight') {
            setTime2s(false)
          }
        }
      } else {
        if ((offsetTop.y + offset < viewPortHeight)) {
          // 执行动画
          if (dclass != '') {
            el.classList.remove(`remove_${dclass}`) // 移除
            el.classList.add(`start_${dclass}`) //添加
          } else {
            el.style.animation = `start_${animation} ${time}s  linear forwards`
          }
          if (dclass == 'village_box') {
            setTime(true)
          }
          if (dclass == 'trafficLight') {
            setTime2s(true)
          }
        } else {
          // 取消动画
          if (dclass != '') {
            el.classList.remove(`start_${dclass}`) // 移除
            el.classList.add(`remove_${dclass}`)//添加
          } else {
            el.style.animationName = `remove_${animation}`
          }
          if (dclass == 'village_box') {
            setTime(false)

          }
          if (dclass == 'trafficLight') {
            setTime2s(false)
          }
        }
      }

    }
    let timer2s = null
    let progress2s = false
    function setTime2s(a) {
      if (a && !progress2s) {
        progress2s = true
        timer2s = setInterval(() => {
          // console.log(document.getElementById('kilometers').innerHTML)
          document.getElementById('lightsSum').innerHTML = (Number(document.getElementById('lightsSum').innerHTML) - 1)
          if (document.getElementById('lightsSum').innerHTML <= 0) {
            document.getElementById('lightsSum').innerHTML = 60
          }
          if (document.getElementById('lightsSum').innerHTML == 55) {
            clearInterval(timer2s)
            progress2s = false
          }
        }, 1000)
      }
      if (!a) {
        clearInterval(timer2s)
        document.getElementById('lightsSum').innerHTML = 3
        progress2s = false

      }
    }

    let timer = null
    let progress = false
    function setTime(a) {
      if (a && !progress) {
        progress = true
        timer = setInterval(() => {
          // console.log(document.getElementById('kilometers').innerHTML)
          document.getElementById('kilometers').innerHTML = (Number(document.getElementById('kilometers').innerHTML) - 0.1).toFixed(1)
          if (document.getElementById('kilometers').innerHTML <= 0) {
            document.getElementById('kilometers').innerHTML = 0
            clearInterval(timer)
            progress = false
          }
        }, 200)
      }
      if (!a) {
        clearInterval(timer)
        document.getElementById('kilometers').innerHTML = 2.1
        progress = false

      }
      // console.log(a, 123)
    }
  }

};











// ············································《《滚动动画》》·························································


// import { scrollingAnimation } from './scrollingAnimation.js';  //在要使用的组件中引入此方法
// scrollingAnimation('downward')  //在使用此方法的组件中调用此方法并传递滚动的方向  downward  向下滚动   upward  向上滚动


//----------------------------------------------- 一、css过度-------------------------------------------------------

// data-class（写执行过度时css的名称   .start_名称{ 开始 }     .remove_名称{ 取消 } ）
// data-offset（写触发过度的向下或向上偏移值   非必填  默认值0）



// -----------------------------------------------二、css动画-----------------------------------------------------------

// data-animation（写执行动画时的动画名称   @keyframes start_名称{ 开始 }     @keyframes remove_名称{ 取消 } ）
// data-time（写动画执行的时间   非必填  默认值1）
// data-offset（写触发动画的向下或向上偏移值   非必填  默认值0）





//  使用实例--------------------------------------------------------- 
{/* <template>
  <div class="home" id="home">
    <div class="pages">
      <div style="text-align: center;line-height: 0.5rem;" v-for="(val,i) in 40" :key="i">{{i}}</div>
      <div style="width: 2rem;" data-class="d1" data-offset="100">我是过度动画</div>
      <div style="width: 2rem;" data-animation="d2" data-offset="100" data-time="2">我是css动画</div>
      <div style="text-align: center;line-height: 0.5rem;" v-for="(val,i) in 40" :key="i">{{i}}</div>
    </div>
  </div>
</template>
<script>
import { scrollingAnimation } from './scrollingAnimation.js';
export default {
  data() {
    return {
    };
  },
  watch: {

  },
  mounted() {
    // 向上滑动--------------------------------------------------------
    // window.addEventListener('load', () => {
    //   const homeElement = document.getElementById('home');
    //   if (homeElement) {
    //     window.scrollTo(0, homeElement.scrollHeight);
    //   }
    // });
    // scrollingAnimation('upward')

    // 向下滑动
    scrollingAnimation('downward')
  },
  methods: {
  },

}

</script>
<style>

@keyframes start_d2 {
    0% {
      transform: translate(3rem)
    }
  
    100% {
      transform: translate(0rem)
    }
  }
  
  @keyframes remove_d2 {
    0% {
      transform: translate(0rem);
    }
  
    100% {
      transform: translate(3rem);
    }
  }

.start_d1 {
  transform: translate(3rem) rotate(720deg);
  transition: all 1.5s;
}
.remove_d1 {
  transform: translate(0rem, 0rem) rotate(0deg);
  transition: all 1.5s;
}
.pages{
      height: auto;
      background-color: #ffa86cc4;
      width: 100%;
      overflow: hidden;
}

</style> */}




