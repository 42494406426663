<template>
    <div>
        <!-- 首页封面 -->
        <div class="cover" id="cover">
            <!-- 文案 -->
            <div class="title">
                <div>
                    <div>亲爱的</div>
                </div>
                <!-- <div>
                    <div>新年将近 归期将至</div>
                </div>
                <div>
                    <div>有太多的话想对你说</div>
                </div> -->
                <div>
                    <div> <span class="bold">这一年在高德</span></div>
                </div>
                <!-- <div>
                    <div>我努力奔跑的理由</div>
                </div> -->
                <div>
                    <div>一起走过的路 看见的景 同行的人</div>
                </div>
                <div>
                    <div>想用这一封家书 说给你听</div>
                </div>
                <!-- <div>
                    <div>纸短情长 见字如晤</div>
                </div> -->
            </div>
            <!-- 首页背景 -->
            <div class="cover_img">
                <img :style='`display: block;width:${img_w};height:${img_h}`'
                    src="https://img.alicdn.com/imgextra/i4/O1CN01K7xHee1LEn7OOTrHT_!!6000000001268-0-tps-1125-2436.jpg"
                    alt="">

                <!-- 信 -->
                <img class="envelope"
                    src="https://img.alicdn.com/imgextra/i4/O1CN01nSTzGF1v1LfCq0o7O_!!6000000006112-2-tps-264-271.png"
                    alt="">
                <!-- 小人静态图 -->
                <div class="character">
                </div>
                <!-- 小人gif动态图 -->
                <div class="character_d">
                </div>
            </div>
            <!-- 首页登录弹窗 -->
            <!-- <transition name="van-fade"> -->
            <div class="mask" v-show="mask">
                <div class="mask_zis">
                <div class="notification_box">
                    <img class="notification_img"
                        src="https://img.alicdn.com/imgextra/i4/O1CN01yznAR725UKtgFxaLN_!!6000000007529-2-tps-998-1079.png"
                        alt="">
                        <div class="name_input_ts" v-if="name==''">输入高德同学姓名<span>(全名)</span></div>
                    <input class="name_input" type="text" v-model="name" placeholder="">
                    
                    <img class="btn" @click="submitName"
                        src="https://img.alicdn.com/imgextra/i2/O1CN01Uma56p1DHyxI2ftIR_!!6000000000192-2-tps-813-151.png"
                        alt="">
                    <img class="btn_t" @click="cover('https://mapdownload.autonavi.com/voiceip/new_year/video_common.mp4')"
                        src="https://img.alicdn.com/imgextra/i3/O1CN01f9CiDE1YdbaTttN84_!!6000000003082-2-tps-711-133.png"
                        alt="">
                </div>
                <!-- 白光 -->
                <div class="awhiteLight">
                </div>
            </div>
            </div>
            <!-- </transition> -->
            <!-- <Toast></Toast> -->
        </div>
    </div>
</template>
  
<script>
import md5 from 'js-md5';
import { showFailToast } from 'vant';
import { Toast } from 'vant';
export default {
    components: {
        [Toast.name]: Toast,
    },
    name: 'Cover',
    data() {
        return {
            img_w: 0,//首页背景的宽度
            img_h: 0,//首页背景的高度
            mask: false,//是否显示弹窗，默认显示
            name: '',
        };
    },
    watch: {

    },
    mounted() {
        // 调用图片自适应方法
        this.size_img('https://img.alicdn.com/imgextra/i4/O1CN01K7xHee1LEn7OOTrHT_!!6000000001268-0-tps-1125-2436.jpg')
        let $this = this
        window.addEventListener('resize', function () {
            // 调用图片自适应方法
            $this.size_img('https://img.alicdn.com/imgextra/i4/O1CN01K7xHee1LEn7OOTrHT_!!6000000001268-0-tps-1125-2436.jpg')
        });
        // this.mask = true
        // document.getElementsByClassName('mask')[0].classList.add(`masks`) //弹窗显示动画
        // this.startAnimation()
    },
    methods: {
        startAnimation() {

            setTimeout(() => {
                document.getElementsByClassName('title')[0].classList.add(`titles`) //文字渐显动画

                setTimeout(() => {
                    document.getElementsByClassName('envelope')[0].classList.add(`envelopes`) //信封飘落动画

                    setTimeout(() => {
                        document.getElementsByClassName('character')[0].classList.add(`characters`) //小人接信动画
                        document.getElementsByClassName('character_d')[0].classList.add(`character_ds`) //小人接信动画

                    }, 2000)
                    setTimeout(() => {
                        this.mask = true
                        document.getElementsByClassName('mask')[0].classList.add(`masks`) //弹窗显示动画
                        let names = window.localStorage.getItem('username')
                        if (names && names != '') {
                            this.name = names
                        }
                    }, 4500)
                }, 4000)




            }, 100)


        },
        //提交姓名按钮 
        submitName() {

            if (this.name == '') {
                // console.log(1);
                showFailToast('未输入姓名 !')
                return
            }
            window.localStorage.setItem('username', this.name)
            // console.log(md5('key=be42fd5ac9a512b9d45766f0706391b452a18cf4119bc70a6f0fbb62832c2cb0'))
            fetch(`https://restapi.amap.com/rest/aos/new/year/resource/get?sig=${md5('key=82e6c8f81f42cbd6aa1569f8e2a7431907c779483fdc50a88a59b91a8c0ab937')}&key=82e6c8f81f42cbd6aa1569f8e2a74319`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: this.name
                }),
                // params: {
                //     sig: md5('key=be42fd5ac9a512b9d45766f0706391b452a18cf4119bc70a6f0fbb62832c2cb0'),
                //     key: 'be42fd5ac9a512b9d45766f0706391b4'
                // }
            })
                .then(response => response.json())
                .then(data => {

                    if (data.code === 1 && data.message === '成功') {
                        const happyNewYearUrl = data.data.happyNewYearUrl;
                        this.cover(happyNewYearUrl)
                        // console.log('获取到的资源URL:', happyNewYearUrl);
                        // 在这里处理获取到的资源URL，例如将其显示在页面上或进行其他操作  
                    } else {
                        // console.log('请求失败:', data);
                        this.cover('https://mapdownload.autonavi.com/voiceip/new_year/video_common.mp4')
                    }
                })
                .catch(error => {
                    console.error('请求出错:', error);
                    this.cover('https://mapdownload.autonavi.com/voiceip/new_year/video_common.mp4')
                });
            // return
            // this.mask = false
            // this.$emit('coverFun', 'load')
            // document.getElementsByClassName('cover')[0].classList.add(`covers`) //页面隐藏动画
            // document.getElementsByClassName('whiteLight')[0].classList.add(`whiteLights`) //白光出现动画
        },
        cover(a) {
            // this.mask = false
            this.$emit('coverFun', a)
            document.getElementsByClassName('cover')[0].classList.add(`covers`) //页面隐藏动画
            document.getElementsByClassName('awhiteLight')[0].classList.add(`awhiteLights`) //白光出现动画
        },

        //让图片自适应当前可视区域的大小， 页面中的元素要用百分比来展位
        size_img(a) {
            let h = 0, w = 0
            let image = new Image()
            image.src = a
            image.onload = () => {
                h = image.height
                w = image.width
                const container = document.getElementById('cover');
                const containerWidth = container.clientWidth;
                const containerHeight = container.clientHeight;
                const widthRatio = containerWidth / w;
                const heightRatio = containerHeight / h;
                // console.log(widthRatio, heightRatio)
                if (widthRatio > heightRatio) {
                    this.img_w = `${containerWidth}px`
                    this.img_h = `auto`
                } else {
                    this.img_w = `auto`
                    this.img_h = `${containerHeight}px`
                }
            }

        },
        // --------------------------------------------------------------------------------
    },

}

</script>

  
<style lang="scss">
.cover {
    position: fixed;
    width: 7.5rem;
    height: 100%;
    top: 0;
    left: calc(50% - 3.75rem);
    z-index: 8;
    overflow: hidden;
    min-height: 13.34rem;
    // overflow-y: auto;
    /* animation: cover_animation 1s 7.5s linear forwards; */

    .cover_img {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        display: inline-block;


        .envelope {
            width: 15%;
            position: absolute;
            top: 25%;
            left: 20%;
            z-index: 2;
            opacity: 0;
            // transform: translate(100%, 1100%) rotate(0deg);
        }

        .character {
            background: url('https://img.alicdn.com/imgextra/i4/O1CN01UBH5B91KqyX1amyep_!!6000000001216-2-tps-1124-732.png') no-repeat;
            background-size: cover;
            background-position: center;
            position: absolute;
            width: 78%;
            height: 23%;
            top: 65%;
            left: 5%;
            // transform: translate(-108%, -33%)
        }

        .character_d {
            background: url('https://img.alicdn.com/imgextra/i2/O1CN01ALjlwm1Vkz7fPJWsl_!!6000000002692-1-tps-1124-732.gif') no-repeat !important;
            background-size: cover !important;
            background-position: center !important;
            position: absolute;
            width: 78%;
            height: 23%;
            top: 65%;
            left: 5%;
            opacity: 0;
            // transform: translate(-108%, -33%)
        }

    }
.mask_zis{
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 13.34rem;
}
    .mask {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        z-index: 4;
        overflow: hidden;
        overflow-y:auto;
        .notification_box {
            width: 7.5rem;
            height: auto;
            // background-color: #fff;
            position: absolute;
            left: 50%;
            top: 40%;
            transform: translate(-50%, -50%);

            // padding: 0.25rem;
            .notification_img {
                width: 6.65rem;
                position: relative;
                height: auto;
                left: 0.25rem;
            }


            .name_input_ts{
                width: 4.15rem;
                position: absolute;
                color: #a9b5e5;
                font-size: 0.35rem;
                left: 50%;
                transform: translate(-50%);
                top: 5.22rem;
                font-weight: bold;
                height: 1.05rem;
                line-height: 1.05rem;
                pointer-events: none;
                letter-spacing: 0.02rem;

                span{
                    font-size: 0.22rem;
                }
            }

            .name_input {
                position: absolute;
                width: 4.15rem;
                height: 1.05rem;
                border: none;
                // border-bottom: 0.02rem solid #000;
                text-align: center;
                background-color: rgba(169, 163, 163, 0);
                left: 50%;
                transform: translate(-50%);
                top: 5.22rem;
                font-size: 0.35rem;
                font-weight: bold;
                letter-spacing: 0.02rem;
            }

            .name_input:focus {
                outline: 0;
            }

            .name_input::-webkit-input-placeholder {
                color: #a9b5e5;
            }

            .name_input::-moz-placeholder {
                /* Mozilla Firefox 19+ */
                color: #a9b5e5;
            }

            .name_input:-moz-placeholder {
                /* Mozilla Firefox 4 to 18 */
                color: #a9b5e5;
            }

            .name_input:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: #a9b5e5;
            }


            .btn {
                position: absolute;
                bottom: -0.7rem;
                width: 5rem;
                height: auto;
                left: 50%;
                transform: translate(-50%);
                cursor: pointer;
            }

            .btn_t {
                position: absolute;
                bottom: -1.8rem;
                width: 5rem;
                height: auto;
                left: 50%;
                transform: translate(-50%);
                cursor: pointer;
            }
        }

        .awhiteLight {
            width: 0rem;
            height: 0rem;
            position: absolute;
            top: 51%;
            left: 50%;
            z-index: 3;
            // background-color: rgb(252, 0, 0);
            opacity: 1;
            transform: scale(0);
            box-shadow: 0px 0px 1rem 1rem rgb(255, 255, 255), 0px 0px 1rem 1rem rgba(255, 255, 255, 1) inset;
            ;
            // box-shadow: 
            border: none;
        }
    }

    .title {
        font-size: 0.29rem;
        position: absolute;
        top: 1.3rem;
        text-align: left;
        width: 7rem;
        left: 0.5rem;
        z-index: 3;
        // font-weight: bold;
        color: #2368cc;
        letter-spacing: 0.02rem;
        font-family: nameR;

        div {
            height: 0.600rem;

            div {
                opacity: 0;
                height: 0.600rem;
                transform: translateY(0.2rem) skew(-3deg);
                overflow: hidden;
                line-height: 0.600rem; 
                transition: all 1s;
            }

            span {
                font-family: nameH;
                color: #ffffff;
                font-size: 0.37rem;
                // font-style: italic;
                display: inline-block;
                transform: skew(-4deg);
            }
        }
        :nth-child(4) {
            div {
                opacity: 0;
                height: 0.5rem;
                transform: translateY(0.2rem) skew(-3deg);
                overflow: hidden;
                line-height: 0.500rem; 
                transition: all 1s;
            }
        }
    }

    .titles {

        :nth-child(1) {
            div {
                height: 0.600rem;
                opacity: 1;
                transform: translateY(0rem) skew(-3deg);
                transition: all 0.8s;
            }
        }

        :nth-child(2) {
            div {
                height: 0.600rem;
                opacity: 1;
                transform: translateY(0rem) skew(-3deg);
                transition: all 0.8s 1s;
            }
        }

        :nth-child(3) {
            div {
                height: 0.600rem;
                opacity: 1;
                transform: translateY(0rem) skew(-3deg);
                transition: all 0.8s 2s;
            }
        }

        :nth-child(4) {
            div {
                height: 0.5rem;
                opacity: 1;
                transform: translateY(0rem) skew(-3deg);
                transition: all 0.8s 3s;
            }
        }

        :nth-child(5) {
            div {
                height: 0.600rem;
                opacity: 1;
                transform: translateY(0rem);
                transition: all 0.8s 4s;
            }
        }

        :nth-child(6) {
            div {
                height: 0.600rem;
                opacity: 1;
                transform: translateY(0rem);
                transition: all 0.8s 5s;
            }
        }

        :nth-child(7) {
            div {
                height: 0.600rem;
                opacity: 1;
                transform: translateY(0rem);
                transition: all 0.8s 6s;
            }
        }

        :nth-child(8) {
            div {
                height: 0.600rem;
                opacity: 1;
                transform: translateY(0rem);
                transition: all 0.8s 7s;
            }
        }

        // div {
        //     div {
        //         height: 0.600rem;
        //         opacity: 1;
        //         transition: all 1s;
        //     }

        // }
    }


}

.awhiteLights {
    animation: awhiteLights_a 1.5s 0s linear forwards;

    @keyframes awhiteLights_a {
        0% {

            transform: scale(0);


        }

        100% {
            transform: scale(20);
        }
    }
}


.characters {
    opacity: 0;

}
.character_ds {
    opacity: 1 !important;

}



.envelopes {
    // transform: translate(45%, 600%) rotate(0deg);
    // transform: translate(100%, 1100%) rotate(0deg);
    animation: fmxf 4s linear forwards;

    @keyframes fmxf {
        0% {
            transform: translate(0%) rotate(0deg) scale(1.2);
            opacity: 0;
            animation-timing-function: cubic-bezier(0.41, 0.24, 0.50, 0.75)
        }

        25% {
            transform: translate(-60%, 165%) rotate(80deg) scale(1.2);
            opacity: 1;
            /* 添加了cubic-bezier函数 */
            animation-timing-function: cubic-bezier(0.41, 0.24, 0.50, 0.75)
        }

        50% {
            transform: translate(50%, 330%) rotate(0deg) scale(1.2);
            /* 添加了cubic-bezier函数 */
            animation-timing-function: cubic-bezier(0.41, 0.24, 0.50, 0.75)
        }

        75% {
            transform: translate(-50%, 495%) rotate(80deg) scale(1.2);
            /* 添加了cubic-bezier函数 */
            animation-timing-function: cubic-bezier(0.41, 0.24, 0.50, 0.75)
        }

        100% {
            transform: translate(52%, 610%)rotate(0deg) scale(1.2);
            opacity: 1;
            animation-timing-function: cubic-bezier(0.41, 0.24, 0.50, 0.75)
        }
    }
}

.masks {
    animation: masks_a 1s linear forwards;

    @keyframes masks_a {
        0% {

            opacity: 0;


        }

        100% {
            opacity: 1;
        }
    }
}

.covers {
    animation: covers_a 1s 0.5s linear forwards;

    @keyframes covers_a {
        0% {

            opacity: 1;


        }

        100% {
            opacity: 0;
        }
    }
}
</style>
