<template>
    <div class="excess_box">
        <div class="excess_bg">
            <img :style='`display: block;width:${img_w};height:${img_h}`'
                src="https://img.alicdn.com/imgextra/i1/O1CN01cp6SLS20VdwkX7bZL_!!6000000006855-0-tps-1125-2436.jpg" alt="">

            <img class="aircraft"
                src="https://img.alicdn.com/imgextra/i3/O1CN01RAZlB21NY962FWM4i_!!6000000001581-2-tps-233-249.png" alt="">

            <!-- 文案 -->
            <div class="excess_title">
                <div>
                    <div>这一年</div>
                </div>
                <div>
                    <div>我在太阳升起时为热爱奔走</div>
                </div>
                <div>
                    <div>太阳落山时与生活相拥</div>
                </div>

                <div>
                    <div>努力成为更好的自己</div>
                </div>
                <div>
                    <div>和喜欢的人看喜欢的风景</div>
                </div>
            </div>
        </div>
        <!-- 白光 -->
        <!-- <div class="whiteLighta">
        </div> -->
    </div>
</template>
<script>
export default {
    name: 'Excess',
    data() {
        return {
            img_w: 0,//首页背景的宽度
            img_h: 0,//首页背景的高度
        };
    },
    mounted() {
        // 调用图片自适应方法
        this.size_img('https://img.alicdn.com/imgextra/i1/O1CN01cp6SLS20VdwkX7bZL_!!6000000006855-0-tps-1125-2436.jpg')
        let $this = this
        window.addEventListener('resize', function () {
            // 调用图片自适应方法
            $this.size_img('https://img.alicdn.com/imgextra/i1/O1CN01cp6SLS20VdwkX7bZL_!!6000000006855-0-tps-1125-2436.jpg')
        });
        // this.sexecuteAnimation()
    },
    methods: {
        //让图片自适应当前可视区域的大小， 页面中的元素要用百分比来展位
        size_img(a) {
            let h = 0, w = 0
            let image = new Image()
            image.src = a
            image.onload = () => {
                h = image.height
                w = image.width
                const container = document.getElementsByClassName('excess_box')[0];
                const containerWidth = container.clientWidth;
                const containerHeight = container.clientHeight;
                const widthRatio = containerWidth / w;
                const heightRatio = containerHeight / h;
                // console.log(widthRatio, heightRatio)
                if (widthRatio > heightRatio) {
                    this.img_w = `${containerWidth}px`
                    this.img_h = `auto`
                } else {
                    this.img_w = `auto`
                    this.img_h = `${containerHeight}px`
                }
            }

        },
        sexecuteAnimation() {

            document.getElementsByClassName('excess_bg')[0].classList.add(`excess_bgs`) //高小德
            document.getElementsByClassName('aircraft')[0].classList.add(`aircrafts`) //纸飞机飞过
            setTimeout(() => {
                // document.getElementsByClassName('whiteLighta')[0].classList.add(`whiteLightas`) //白光出现动画

                document.getElementsByClassName('excess_title')[0].classList.add(`excess_titles`) //白光出现动画


                setTimeout(() => {
                    // return
                    document.getElementsByClassName('excess_box')[0].classList.add(`excess_boxs`) //白光出现动画

                    this.endTransition()
                }, 4000)
            }, 3000)

        },
        endTransition() {
            // setTimeout(() => {
            this.$emit('excessFun', '')
            // }, 2000)


        },

    }
}
</script>
<style lang="scss">
.excess_box {
    width: 7.5rem;
    height: 100vh;
    top: 0;
    left: 0;
    position: absolute;
    overflow: hidden;
    min-height: 13.34rem;
z-index: 2;
    .whiteLighta {
        width: 0rem;
        height: 0rem;
        position: absolute;
        top: 51%;
        left: 50%;
        z-index: 3;
        // background-color: rgb(252, 0, 0);
        opacity: 1;

        box-shadow: 0px 0px 1rem 1rem rgb(255, 255, 255), 0px 0px 1rem 1rem rgba(255, 255, 255, 1) inset;
        ;
        // box-shadow: 
        border: none;
        transform: scale(10) rotate(0deg);
    }

    .whiteLightas {
        animation: whiteLights_a 2s 1s linear forwards;

        @keyframes whiteLights_a {
            0% {

                opacity: 1;

                transform: scale(20) rotate(0deg);

            }

            100% {
                opacity: 0;
                transform: scale(0) rotate(720deg);
            }
        }
    }

    .excess_bg {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        display: inline-block;
        transform: scale(5) rotate(720deg);
        opacity: 0.5;
        .aircraft {
            position: absolute;
            width: 20%;
            left: 20%;
            top: 42%;
            opacity: 1;
            transform: translate(150%, 50%) rotate(120deg);
            // transform: translate(2rem,7rem) rotate(150deg);
            // transform: translate(-1rem,6.5rem) rotate(190deg);
            // transform: translate(-2.5rem,4rem) rotate(240deg);
            // transform: translate(0rem,0rem) rotate(360deg);
            transform-origin: right 260%;

        }

        .aircrafts {
            animation: aircrafts_a 3s 2s ease-out forwards;

            @keyframes aircrafts_a {
                0% {
                    transform: translate(150%, 50%) rotate(120deg);
                    opacity: 0;
                }

                20% {
                    // transform: rotate(120deg);
                    opacity: 1;
                }

                100% {
                    transform: translate(0%, 0%) rotate(360deg);
                    opacity: 1;
                }
            }
        }

        .excess_title {
            font-size: 0.28rem;
            position: absolute;
            top: 3rem;
            text-align: center;
            width: 7rem;
            left: 0.25rem;
            z-index: 3;
            // font-weight: bold;
            color: #ffffff;
            letter-spacing: 0.07rem;
            font-family: nameR;

            div {
                height: 0.653rem;

                div {
                    opacity: 0;
                    height: 0.653rem;
                    transform: translateY(0.65rem);
                    overflow: hidden;
                    line-height: 0.653rem;
                    transition: all 1s;
                    text-shadow: 0rem 0rem 0.05rem rgba(91, 179, 242, 0.5);
                }
            }
        }

        .excess_titles {

            div{
                div{
                    height: 0.653rem;
                    opacity: 1;
                    transform: translateY(0rem);
                    transition: all 2s 0.5s;
                }
            }
            // :nth-child(1) {
            //     div {
            //         height: 0.653rem;
            //         opacity: 1;
            //         transform: translateY(0rem);
            //         transition: all 1s;
            //     }
            // }

            // :nth-child(2) {
            //     div {
            //         height: 0.653rem;
            //         opacity: 1;
            //         transform: translateY(0rem);
            //         transition: all 1s 1.2s;
            //     }
            // }

            // :nth-child(3) {
            //     div {
            //         height: 0.653rem;
            //         opacity: 1;
            //         transform: translateY(0rem);
            //         transition: all 1s 2.4s;
            //     }
            // }

            // :nth-child(4) {
            //     div {
            //         height: 0.653rem;
            //         opacity: 1;
            //         transform: translateY(0rem);
            //         transition: all 1s 3.6s;
            //     }
            // }

            // :nth-child(5) {
            //     div {
            //         height: 0.653rem;
            //         opacity: 1;
            //         transform: translateY(0rem);
            //         transition: all 1s 4.8s;
            //     }
            // }
        }
    }


    .excess_bgs {

        animation: excess_bgs_a 2s 0s linear forwards;

        @keyframes excess_bgs_a {
            0% {
                transform:translate(-50%, -50%) scale(5) rotate(0deg);
                opacity: 0.5;
            }

            // 20% {
            //     transform: translate(-50%, -50%) scale(4) rotate(0deg);

            // }

            100% {
                opacity: 1;
                transform:translate(-50%, -50%) scale(1) rotate(0deg);
            }
        }
    }
}

.excess_boxs {
    opacity: 0;
    transition: all 2s;
}
</style>