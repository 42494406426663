<template>
    <div class="WallPage_box">
        <div class="WallPage_bg" v-show="WallPage1">
            <img :style='`display: block;width:${img_w};height:${img_h}`'
                src="https://img.alicdn.com/imgextra/i3/O1CN01718d7h29RpBY3iDtC_!!6000000008065-0-tps-1125-2436.jpg" alt="">

            <div class="element_box">
                <img class="ele1"
                    src="https://img.alicdn.com/imgextra/i2/O1CN014bVrpD1SzEbKAJQbS_!!6000000002317-2-tps-101-101.png"
                    alt="">
                <img class="ele2"
                    src="https://img.alicdn.com/imgextra/i4/O1CN01YEkt6V1xVEkU1Tqjk_!!6000000006448-2-tps-412-402.png"
                    alt="">
                <img class="ele3"
                    src="https://img.alicdn.com/imgextra/i1/O1CN01AMnEm81U2jF0Vv9I7_!!6000000002460-2-tps-401-401.png"
                    alt="">
                <img class="ele4"
                    src="https://img.alicdn.com/imgextra/i1/O1CN01m63YQg1ik9PQKIJDa_!!6000000004450-2-tps-169-168.png"
                    alt="">
                <img class="ele5"
                    src="https://img.alicdn.com/imgextra/i3/O1CN01IuUILB1qZdEq1WwXY_!!6000000005510-2-tps-456-456.png"
                    alt="">
                <img class="ele6"
                    src="https://img.alicdn.com/imgextra/i1/O1CN013XBsdm1UNL3roTLca_!!6000000002505-2-tps-168-169.png"
                    alt="">
                <!-- 文案 -->
                <div class="WallPage_title">
                    <div>
                        <div>这一年</div>
                    </div>
                    <div>
                        <div>我收获了一群有情有义的小伙伴</div>
                    </div>
                    <div>
                        <div>我们并肩同行 共同成长</div>
                    </div>
                    <div>
                        <div>一起为热爱出发</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="picture_image_box" id="picture_image_box">
            <img :style='`display: block;width:${img_w};height:${img_h}`'
                src="https://img.alicdn.com/imgextra/i3/O1CN01718d7h29RpBY3iDtC_!!6000000008065-0-tps-1125-2436.jpg" alt="">

            <div class="icture_image" id="icture_image">

                <img class="icture_image_img"
                    src="https://img.alicdn.com/imgextra/i4/O1CN01n6huVo1nAUekDE2Rs_!!6000000005049-2-tps-1275-6719.png"
                    alt="">

            </div>
            <img class="top_mask"
                src="https://img.alicdn.com/imgextra/i3/O1CN01SVwGRl1S5ogJPZsbr_!!6000000002196-2-tps-1125-224.png" alt="">
            <img class="bottom_mask"
                src="https://img.alicdn.com/imgextra/i1/O1CN019VhAzf1H2Bls0yubt_!!6000000000699-2-tps-1125-733.png" alt="">
                <div class="Wall_glide_box">
            <img class="glide_ico1"
                src="https://img.alicdn.com/imgextra/i3/O1CN013szXy527NaHzzm40P_!!6000000007785-2-tps-169-169.png" alt="">
            <img class="glide_ico2"
                src="https://img.alicdn.com/imgextra/i1/O1CN01NuFhLW1N5I9BX7J95_!!6000000001518-2-tps-87-163.png" alt="">
        </div>
    </div>

    </div>
</template>
<script>
export default {
    name: 'WallPage',
    data() {
        return {
            img_w: 0,//首页背景的宽度
            img_h: 0,//首页背景的高度
            WallPage1: true,
            shoow: false,
        };
    },
    mounted() {
        // 调用图片自适应方法
        this.size_img('https://img.alicdn.com/imgextra/i3/O1CN01718d7h29RpBY3iDtC_!!6000000008065-0-tps-1125-2436.jpg')
        let $this = this
        window.addEventListener('resize', function () {
            // 调用图片自适应方法
            $this.size_img('https://img.alicdn.com/imgextra/i3/O1CN01718d7h29RpBY3iDtC_!!6000000008065-0-tps-1125-2436.jpg')
            $this.loads()
        });


        // 页面滚动到最底部
        window.addEventListener('load', () => {

            //     const icture_image = document.getElementById('icture_image');
            //     const picture_image_box = document.getElementById('picture_image_box')

            //     setTimeout(() => {
            //         picture_image_box.scrollTo(0, 0);

            //     }, 10)

        });


        // this.wallPageAnimation()

    },
    methods: {
        loads() {
            const icture_image = document.getElementsByClassName('icture_image')[0];
            if (icture_image.getBoundingClientRect().y < 0) {
                let to = -icture_image.getBoundingClientRect().y
                let he = document.getElementById('icture_image').getBoundingClientRect().height - (-icture_image.getBoundingClientRect().y)
                document.getElementById('icture_image').style.top = `${to}px`
                document.getElementById('icture_image').style.height = `${he}px`
                document.getElementsByClassName('top_mask')[0].style.top = `${to}px`
            }
        },
        end() {
            let photoWall_box = document.getElementsByClassName('icture_image')[0]
            let $this = this
            photoWall_box.addEventListener("scroll", function (a) {
                // console.log(photoWall_box.scrollHeight, photoWall_box.scrollTop, photoWall_box.clientHeight)
                if (photoWall_box.scrollTop + 50 < (photoWall_box.scrollHeight - photoWall_box.clientHeight)) {
                    // console.log(123)
                    document.getElementsByClassName('Wall_glide_box')[0].classList.add('Wall_glide_boxs')
                }
                if (photoWall_box.scrollHeight - 70 < (photoWall_box.scrollTop + photoWall_box.clientHeight)) {
                    if (!$this.shoow) {
                        // return
                        $this.shoow = true
                        setTimeout(()=>{
                        // console.log(12345697)
                        $this.$emit('wallPagFun', '')
                        document.getElementsByClassName('WallPage_box')[0].classList.add(`WallPage_boxs`)
                        },1000)
              
                    }

                }

            })

        },
        wallPageAnimation() {
            const icture_image = document.getElementById('icture_image');
            const picture_image_box = document.getElementById('picture_image_box')
            if (icture_image) {
                picture_image_box.scrollTo(0, 0);
                setTimeout(() => {

                    // return

                    setTimeout(() => {
                        document.getElementsByClassName('element_box')[0].classList.add(`element_boxs`) //白光出现动画
                        document.getElementsByClassName('WallPage_title')[0].classList.add(`WallPage_titles`) //白光出现动画

                        setTimeout(() => {
                            // return
                            // // document.getElementsByClassName('whiteLighta')[0].classList.add(`whiteLightas`) //白光出现动画
                            document.getElementsByClassName('WallPage_bg')[0].classList.add(`WallPage_bgs`)
                            document.getElementsByClassName('picture_image_box')[0].classList.add(`picture_image_boxs`)
                            // // document.getElementsByClassName('leftSliding')[0].classList.add(`leftSlidings`)
                            this.end()
                            setTimeout(() => {
                                this.WallPage1 = false
                            }, 1800)
                            
                        }, 5000)
                        setTimeout(()=>{
                            this.loads()
                        },2000)
                    }, 10)




                }, 10)
            }



        },
        //让图片自适应当前可视区域的大小， 页面中的元素要用百分比来展位
        size_img(a) {
            let h = 0, w = 0
            let image = new Image()
            image.src = a
            image.onload = () => {
                h = image.height
                w = image.width
                const container = document.getElementsByClassName('WallPage_box')[0];
                const containerWidth = container.clientWidth;
                const containerHeight = container.clientHeight;
                const widthRatio = containerWidth / w;
                const heightRatio = containerHeight / h;
                // console.log(widthRatio, heightRatio)
                if (widthRatio > heightRatio) {
                    this.img_w = `${containerWidth}px`
                    this.img_h = `auto`
                } else {
                    this.img_w = `auto`
                    this.img_h = `${containerHeight}px`
                }
            }

        },
    }
}
</script>
<style lang="scss">
.WallPage_box {
    width: 7.5rem;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    overflow: hidden;
    min-height: 13.34rem;
    left: calc(50% - 3.75rem);

    .WallPage_bg {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        display: inline-block;
        z-index: 2;
        overflow: hidden;

        .element_box {
            width: 100%;
            height: 62%;
            // background-color: rgba(0,0,0,0.5);
            position: absolute;
            top: 12%;
            overflow: hidden;

            .ele1 {
                position: absolute;
                width: 8%;
                left: 7%;
                top: 23%;
                transform: translate(-200%) scale(0.5);
            }

            .ele2 {
                position: absolute;
                position: absolute;
                width: 31%;
                left: 5%;
                top: 45%;
                transform: translate(-200%) scale(0.5);
            }

            .ele3 {
                position: absolute;
                width: 30%;
                left: 15%;
                top: 75%;
                transform: translate(-200%) scale(0.5);

            }

            .ele4 {
                position: absolute;
                width: 15%;
                right: 8%;
                top: 12%;
                transform: translate(200%) scale(0.5);
            }

            .ele5 {
                position: absolute;
                width: 35%;
                right: 3%;
                top: 48%;
                transform: translate(200%) scale(0.5);

            }

            .ele6 {
                position: absolute;
                width: 18%;
                right: 29%;
                top: 80%;
                transform: translate(400%) scale(0.5);

            }

            .WallPage_title {
                font-size: 0.3rem;
                position: absolute;
                top: 2rem;
                text-align: center;
                width: 7rem;
                left: 0.25rem;
                z-index: 3;
                // font-weight: bold;
                color: #ffffff;
                letter-spacing: 0.02rem;
                font-family: nameR;
                font-style: italic;

                div {
                    height: 0.703rem;

                    div {
                        // transform: translate(-100%, 0rem) ;
                        opacity: 0;
                        height: 0.703rem;
                        transform: translateY(0.65rem) skew(-0deg);
                        overflow: hidden;
                        line-height: 0.703rem;
                        transition: all 1s;
                        text-shadow: 0rem 0rem 0.05rem rgba(91, 179, 242, 0.5);
                    }
                }
            }

            .WallPage_titles {
                div {
                    div {
                        height: 0.703rem;
                        opacity: 1;
                        transform: translateY(0rem) skew(-0deg);
                        transition: all 2s;
                    }
                }

                // :nth-child(1) {
                //     div {
                //         height: 0.703rem;
                //         opacity: 1;
                //         transform: translateY(0rem);
                //         transition: all 1s;
                //     }
                // }

                // :nth-child(2) {
                //     div {
                //         height: 0.703rem;
                //         opacity: 1;
                //         transform: translateY(0rem);
                //         transition: all 1s 1.2s;
                //     }
                // }

                // :nth-child(3) {
                //     div {
                //         height: 0.703rem;
                //         opacity: 1;
                //         transform: translateY(0rem);
                //         transition: all 1s 2.4s;
                //     }
                // }

                // :nth-child(4) {
                //     div {
                //         height: 0.703rem;
                //         opacity: 1;
                //         transform: translateY(0rem);
                //         transition: all 1s 3.6s;
                //     }
                // }

                // :nth-child(5) {
                //     div {
                //         height: 0.55rem;
                //         opacity: 1;
                //         transform: translateY(0rem);
                //         transition: all 1s 4.8s;
                //     }
                // }

                // :nth-child(6) {
                //     div {
                //         height: 0.55rem;
                //         opacity: 1;
                //         transform: translateY(0rem);
                //         transition: all 1s 6s;
                //     }
                // }

                // :nth-child(7) {
                //     div {
                //         height: 0.55rem;
                //         opacity: 1;
                //         transform: translateY(0rem);
                //         transition: all 1s 7.2s;
                //     }
                // }

                // :nth-child(8) {
                //     div {
                //         height: 0.55rem;
                //         opacity: 1;
                //         transform: translateY(0rem);
                //         transition: all 1s 8.4s;
                //     }
                // }

                // :nth-child(9) {
                //     div {
                //         height: 0.55rem;
                //         opacity: 1;
                //         transform: translateY(0rem);
                //         transition: all 1s 9.6s;
                //     }
                // }

                // :nth-child(10) {
                //     div {
                //         height: 0.55rem;
                //         opacity: 1;
                //         transform: translateY(0rem);
                //         transition: all 1s 10.8s;
                //     }
                // }
            }
        }

        .element_boxs {

            .ele1,
            .ele2,
            .ele3,
            .ele4,
            .ele5,
            .ele6 {
                transform: translate(0rem) scale(1);
                transition: all 2s 0s;
            }

            // .ele1,
            // .ele4 {
            //     transform: translate(0rem);
            //     transition: all 2s 4s;
            // }

            // .ele2,
            // .ele5 {
            //     transform: translate(0rem);
            //     transition: all 2s 2s;
            // }

            // .ele3,
            // .ele6 {
            //     transform: translate(0rem);
            //     transition: all 2s 0s;
            // }
        }

        .element_box_opacity {
            opacity: 0;
            transition: all 2s 1s;
        }

        .leftSliding {
            position: absolute;
            width: 20%;
            top: 60%;
            left: 40%;
            transform: rotate(40deg);
            animation: leftSliding_a 2s 0s linear infinite;
            transform-origin: center bottom;
            opacity: 0;

            @keyframes leftSliding_a {
                0% {
                    transform: rotate(40deg);
                }

                50% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(40deg);
                }
            }
        }

        .leftSlidings {
            opacity: 1;
            transition: all 1s 2s;
        }

        .photoWall_box {
            width: 7.5rem;
            height: 65%;
            // background-color: rgba(0,0,0,0.5);
            position: absolute;
            top: 5%;
            left: calc(50% - 3.75rem);
            overflow-x: auto;
            overflow-y: hidden;
            opacity: 0;


            img {
                display: block;
                height: 100%;
            }

        }

        .photoWall_box::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 1px;
            /*高宽分别对应横竖滚动条的尺寸*/
            height: 10px;
            // position: fixed;
            // left: -10px;
            /* background-color: rgb(255, 255, 255); */
        }

        .photoWall_box::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            /* height: 0.5rem; */
            border-radius: 20px;
            background: linear-gradient(to right, #28acff, #3c6eff);
            // position: absolute;
        }

        .photoWall_box::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            /* box-shadow: inset 0 0 5px #EDEDED; */
            border-radius: 20px;
            background: #dedcdc;
        }

        .photoWall_boxs {
            opacity: 1;
            transition: all 2s 1s;
        }
    }

    .WallPage_bgs {
        opacity: 0;
        transition: all 2s;
    }

    .picture_image_box {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        display: inline-block;
        // z-index: 2;
        overflow: hidden;



        .icture_image {
            width: 7.5rem;
            height: 78%;
            position: absolute;
            top: 0;
            // background: url('https://img.alicdn.com/imgextra/i1/O1CN01vzWTxg1HbSGI4dehI_!!6000000000776-0-tps-1125-7752.jpg') no-repeat;
            // background-size: cover;
            // background-position: center;
            overflow-x: hidden;
            // background-color: rgba(0, 0, 0, 0.5);


            .icture_image_img {
                position: relative;
                width: 7.5rem;
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
            }


        }

        .top_mask {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            // z-index: 2;
            pointer-events: none;
        }

        .bottom_mask {
            position: absolute;
            width: 100%;
            // top: 61.5%;
            bottom: 0;
            left: 0;
            pointer-events: none;

        }

        .Wall_glide_box {
            position: absolute;
            top: 29%;
    right: 14%;
            width: 1.5rem;
            height: 1.7rem;
            transform: scale(2);
            pointer-events: none;
            .glide_tit {
                width: 1.42rem;
                position: absolute;
                top: 0;
                left: -0.1rem;
                opacity: 0;
                animation: glide_tit_a 0.8s 0s linear infinite;

                @keyframes glide_tit_a {
                    0% {
                        opacity: 0;
                    }

                    50% {
                        opacity: 1;
                    }

                    100% {
                        opacity: 0;
                    }
                }
            }

            .glide_ico1 {
                width: 1.12rem;
                position: absolute;
                right: -0.3rem;
                bottom: 0.7rem;
                transform: rotate(-60deg);
                transform-origin: bottom right;
                animation: glide_ico1_a 1.5s 0s linear infinite;

                @keyframes glide_ico1_a {
                    0% {
                        transform: rotate(-60deg);
                        opacity: 1;
                    }

                    60% {
                        opacity: 1;
                    }

                    100% {
                        transform: rotate(-20deg);
                        opacity: 0;
                    }
                }
            }

            .glide_ico2 {
                width: 0.58rem;
                position: absolute;
                left: 0;
                bottom: 0.2rem;
                transform: rotate(180deg) rotateY(180deg);

            }
        }

        .Wall_glide_boxs {
            opacity: 0;
            transition: all 2s;
        }
    }

    .picture_image_boxs {
        opacity: 1;
        transition: all 2s;
    }

}

.WallPage_boxs {
    opacity: 0;
    transition: all 2s;
}</style>