<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <div id="app">
    <audio src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024011913cd47d1bdc115c5125193.mp3"
      id="yinyue" style="display: none;" loop preload></audio>
    <img class="pc-bg"
      src="https://img.alicdn.com/imgextra/i4/O1CN01K7xHee1LEn7OOTrHT_!!6000000001268-0-tps-1125-2436.jpg" alt="">
    <img :class="audioState ? 'audio-icon audio-play' : 'audio-icon'" @click="audioPlay"
      src="https://res.xiaoxxx.com/urlGather/20221230287b86501a606641933717.png" alt="">
    <img class="gdlogo"
      src="https://img.alicdn.com/imgextra/i4/O1CN01kWeMhp25IQbG7vXLN_!!6000000007503-2-tps-1322-308.png" alt="">
    <div class="home-box">
      <router-view />
    </div>

  </div>
</template>
<script>
import store from './store';
export default {

  provide() {
    return {
    };
  },
  data() {
    return {
      audioState: false,
      zui: false,
    };
  },
  mounted() {
    // sessionStorage.setItem("key", "");
    //     var new_element = document.createElement("script");
    //     new_element.setAttribute("type", "text/javascript");
    //     new_element.setAttribute(
    //       "src",
    //       "http://g.alicdn.com/code/lib/vConsole/3.4.0/vconsole.min.js"
    //     );
    //     document.body.appendChild(new_element);
    //     window.onload = function () {
    //       new window.VConsole();
    //     };



    this.suo()
    window.createjs.Sound.alternateExtensions = ["mp3"]
    if (navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1) {
      window.createjs.Sound.registerPlugins([window.createjs.HTMLAudioPlugin])
    } else {
      window.createjs.Sound.registerPlugins([window.createjs.WebAudioPlugin])
    }
    window.createjs.Sound.on("fileload", this.loadHandler)
    window.createjs.Sound.registerSound("https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024011913cd47d1bdc115c5125193.mp3", "bgSound")
    // this.audioPlay()
    this.share()
  },
  methods: {

    audioPlay(type) {
      console.log(type, 123)
      // console.log(type)
      if (type == 'play') {
        // this.$refs.music.play()
        window.createjs.Sound.play("bgSound");
        this.audioState = true
      } else if (type == 'pause') {
        // this.$refs.music.pause()
        window.createjs.Sound.stop("bgSound");
        this.audioState = false
        // console.log()
        var spbf = document.getElementById("yinyue");
        spbf.pause();
      } else {
        if (this.audioState) {
          // this.$refs.music.pause()
          window.createjs.Sound.stop("bgSound");
          this.audioState = false
          var spbf = document.getElementById("yinyue");
          spbf.pause();
        } else {
          // this.$refs.music.play()
          window.createjs.Sound.play("bgSound", { loop: 100 });
          this.audioState = true
          if (this.zui) {
            var spbf = document.getElementById("yinyue");
            spbf.play();
          }
        }
      }
    },
    loadHandler(a) {
      if (a.src == 'https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/202401198a13cf3bf235c7f6391680.mp3') {
        console.log('执行了1')
        this.zui = true
        var spbf = document.getElementById("yinyue");
        // spbf.play();
        spbf.pause();
        setTimeout(() => {
          spbf.play();
        }, 10)
      }
      if (a.src == 'https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024011913cd47d1bdc115c5125193.mp3') {
        store.commit('shows', true)
        //  判断是否为移动端
        if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
          let ua = navigator.userAgent.toLowerCase(); //判断浏览器的类型
          if (ua.indexOf("micromessenger") > -1) {

          } else {
            window.createjs.Sound.play("bgSound", { loop: 100 })
            this.audioState = true
          }

        } else {
        }
      } else {
        console.log('执行了2')
        window.createjs.Sound.play("bgSound", { loop: 100 })
        this.audioState = true
      }

    },

    suo() {
      var deviceWidth = document.documentElement.clientWidth;
      if (deviceWidth > 750) {
        deviceWidth = 7.5 * 50;
      }
      document.documentElement.style.fontSize = deviceWidth / 7.5 + "px";
      var lastTouchEnd = 0;
      document.documentElement.addEventListener(
        "touchend",
        function (event) {
          var now = Date.now();
          if (now - lastTouchEnd <= 300) {
            event.preventDefault();
          }
          lastTouchEnd = now;
        },
        false
      );
      if (document.getElementsByTagName("meta")["viewport"]) {
        document.getElementsByTagName("meta")["viewport"].content =
          "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0";
      } else {
        var meta = document.createElement("meta");
        meta.content =
          "width=device-width, initial-scale=1.0, maximum-scale=1.0,minimum-scale=1.0, user-scalable=0";
        meta.name = "viewport";
        document.getElementsByTagName("head")[0].appendChild(meta);
      }
    },
    //钉钉分享
    share() {
      // -------钉钉分享--------------------------------------------------------------------
      window.dd.ready(function () {
        window.dd.biz.navigation.setRight({
          show: true,
          control: true,
          text: "分享",
          onSuccess: function () {
            window.dd.biz.util.share({
              style: 1,
              url: "https://gaodejiashu.xiaoxxx.cn",
              title: "2024高德家书",
              content: "我德心意 跨越山海 寄给最亲爱的人！",
              image:
                "https://img.alicdn.com/imgextra/i4/O1CN01H9gP021R2mR38GyBE_!!6000000002054-0-tps-300-300.jpg",
              onSuccess: function () {

              },
            });
          },
          onFail: function () {

          },
        });
      });
      // return
      // --------------------------------
      // 微信jsdk
      // this.$axios
      //   .post('https://h5api.xiaoxxx.cn/h5/huaFaPeople/getJsSDKConfig', {
      //     url: location.href.split("#")[0]
      //     // url:"https://h5case4.xiaoxxx.cn"
      //   })
      //   .then((res) => {
      fetch('https://h5api.xiaoxxx.cn/h5/huaFaPeople/getJsSDKConfig', {
        method: "POST",
        headers: { 'Content-Type': "application/x-www-form-urlencoded" },
        body: `url=${location.href.split("#")[0]}`

      })
        .then(data => data.json())
        .then(resa => {
          let res = {
            data: resa
          }
          // console.log(res);
          // console.log(location.href.split("#")[1]);
          window.wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.appId, // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名，见附录1
            jsApiList: [
              "updateAppMessageShareData",
              "updateTimelineShareData",
            ],
          });

          window.wx.ready(function () {
            // 转发
            window.wx.updateAppMessageShareData({
              title: "2024高德家书", // 分享标题
              desc: "我德心意 跨越山海 寄给最亲爱的人！", // 分享描述
              link: "https://gaodejiashu.xiaoxxx.cn", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl:
                "https://img.alicdn.com/imgextra/i4/O1CN01H9gP021R2mR38GyBE_!!6000000002054-0-tps-300-300.jpg", // 分享图标
              success: function () { },
            });
            // 分享朋友圈
            window.wx.updateTimelineShareData({
              title: "2024高德家书", // 分享标题
              link: "https://gaodejiashu.xiaoxxx.cn", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl:
                "https://img.alicdn.com/imgextra/i4/O1CN01H9gP021R2mR38GyBE_!!6000000002054-0-tps-300-300.jpg", // 分享图标
              success: function () { },
            });
          });
        });
    },
  },
  created() {
  },
}
</script>

<style lang="scss">
.home-box {
  width: 100%;
  max-width: 7.5rem;
  // min-height: 100%;
  margin: 0 auto;
  position: relative;
}

.pc-bg {
  width: 100vw;
  height: 100%;
  object-fit: cover;
  filter: blur(20px);
  -webkit-filter: blur(20px);
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
}

.gdlogo {
  width: 1.5rem;
  position: fixed;
  left: 0.2rem;
  top: 0.2rem;
  z-index: 99;
  opacity: 0.8;
}

.audio-icon {
  width: 0.8rem;
  position: fixed;
  right: 0.2rem;
  top: 0.5rem;
  z-index: 99;
}

.audio-play {
  animation: rotate 2s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

#app {

  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
