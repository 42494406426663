<template>
    <div class="TrailerPage_box">
        <div class="TrailerPage_bg">
            <img :style='`display: block;width:${img_w};height:${img_h}`'
                src="https://img.alicdn.com/imgextra/i2/O1CN01YcMB091fEbHV49h0N_!!6000000003975-0-tps-1125-2436.jpg" alt="">

            <!-- 烟花 -->
            <img class="pyrotechnics1"
                src="https://img.alicdn.com/imgextra/i3/O1CN01S4Qspd1Ty9HJPalFe_!!6000000002450-2-tps-343-812.png" alt="">
            <img class="pyrotechnics2"
                src="https://img.alicdn.com/imgextra/i4/O1CN01QhPlNW1lwvIgILGUY_!!6000000004884-2-tps-419-678.png" alt="">

            <!-- 星星 -->
            <img class="tinySpot1"
                src="https://img.alicdn.com/imgextra/i3/O1CN016MXudH1KXHVAeS7HW_!!6000000001173-2-tps-56-56.png" alt="">
            <img class="tinySpot2"
                src="https://img.alicdn.com/imgextra/i3/O1CN016MXudH1KXHVAeS7HW_!!6000000001173-2-tps-56-56.png" alt="">
            <img class="tinySpot3"
                src="https://img.alicdn.com/imgextra/i3/O1CN016MXudH1KXHVAeS7HW_!!6000000001173-2-tps-56-56.png" alt="">
            <img class="tinySpot4"
                src="https://img.alicdn.com/imgextra/i3/O1CN016MXudH1KXHVAeS7HW_!!6000000001173-2-tps-56-56.png" alt="">
            <!-- 文案 -->
            <div class="TrailerPage_title">
                <div>
                    <div>回望这一年</div>
                </div>
                <div>
                    <div>追逐星辰大海和守护人间烟火的背后</div>
                </div>
                <div>
                    <div>是深夜回家后 饭菜的香气和你的叮咛</div>
                </div>
                <div>
                    <div>是不管走多远 你始终为我亮着的那盏灯</div>
                </div>
                <div>
                    <div>是可以依靠的肩膀和你掌心的温暖</div>
                </div>
                <div>
                    <div>是尽在不言中的信任</div>
                </div>
                <div>
                    <div>和一起奔向未来的默契</div>
                </div>
                <div>
                    <div>2023 感谢有你！</div>
                </div>
                <div>
                    <div>2024 看风和日丽</div>
                </div>

                <div>
                    <div>山河万里 和你一起！</div>
                </div>
            </div>
            <!-- 门 -->
            <img class="leftDoor"
                src="https://img.alicdn.com/imgextra/i3/O1CN01v5FRxR1Z4cxaPw5tz_!!6000000003141-2-tps-344-615.png" alt="">
            <img class="rightDoor"
                src="https://img.alicdn.com/imgextra/i3/O1CN01LKVuQy1hM2ws0qaDX_!!6000000004262-2-tps-344-615.png" alt="">

            <img class="entrance1"
                src="https://img.alicdn.com/imgextra/i3/O1CN01Q4Eul727oberpYAUV_!!6000000007844-2-tps-188-105.png" alt="">
            <img class="entrance2"
                src="https://img.alicdn.com/imgextra/i1/O1CN018fSjWb1PyN0Q1SWcQ_!!6000000001909-2-tps-349-354.png" alt="">
            <img class="gaoxiaode_hand"
                src="https://img.alicdn.com/imgextra/i4/O1CN013ha6Qa29E5Ix9sp6C_!!6000000008035-2-tps-148-184.png" alt="">
            <img class="gaoxiaode"
                src="https://img.alicdn.com/imgextra/i1/O1CN01zCToeW29K2SDh2De6_!!6000000008048-2-tps-375-661.png" alt="">

            <div class="TrailerPage_mask" v-show="TrailerPageMask">
                <div class="TrailerPage_notification_box">

                    <img class="letter_img1"
                        src="https://img.alicdn.com/imgextra/i3/O1CN01vAZKQf1x10bkhRFCc_!!6000000006382-2-tps-937-384.png"
                        alt="">
                    <img class="letter_img1-1"
                        src="https://img.alicdn.com/imgextra/i2/O1CN01uyyKeA1IqrCUrql3H_!!6000000000945-2-tps-937-195.png"
                        alt="">
                    <div class="letter_img2">
                        <div class="letter_img2_b">
                            <div class="name_inputs" v-if="false">{{ names }}</div>
                        </div>
                        <!-- <img src="https://img.alicdn.com/imgextra/i3/O1CN01IueHSQ1q377NIK6yv_!!6000000005439-2-tps-811-1129.png"
                            alt=""> -->

                    </div>
                    <img class="letter_img1-2"
                        src="https://img.alicdn.com/imgextra/i3/O1CN01cr3xEY1hyycBfzMPg_!!6000000004347-2-tps-842-268.png"
                        alt="">
                    <img class="letter_img3"
                        src="https://img.alicdn.com/imgextra/i3/O1CN01GExojp1pVDoGLN8zF_!!6000000005365-2-tps-937-349.png"
                        alt="">


                    <!-- <input class="name_input" type="text" v-model="name" placeholder="完整输入您的亲友姓名"> -->
                    <img class="btn" @click="accept(true)"
                        src="https://img.alicdn.com/imgextra/i4/O1CN01odn9rk1q9z3leXkmn_!!6000000005454-2-tps-775-197.png"
                        alt="">
                    <img class="btn_t" @click="accept(false)"
                        src="https://img.alicdn.com/imgextra/i2/O1CN01kKMOCH1NkVnAD0ABF_!!6000000001608-2-tps-711-133.png"
                        alt="">
                </div>

                <img v-if="false" class="advertisementico" @click="advertisementbtn" src="https://img.alicdn.com/imgextra/i3/O1CN01vhd4PA1a3XdesTTjz_!!6000000003274-2-tps-234-241.png" alt="">
                <img v-if="false" class="advertisementicoTit" src="https://img.alicdn.com/imgextra/i4/O1CN01p8AyvR1FbKvshsSAu_!!6000000000505-2-tps-376-159.png" alt="">
                <!-- 白光 -->
                <div class="whiteLight">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'TrailerPage',
    data() {
        return {
            img_w: 0,//首页背景的宽度
            img_h: 0,//首页背景的高度
            TrailerPageMask: true,
            names: "",
        };
    },
    mounted() {
        // 调用图片自适应方法
        this.size_img('https://img.alicdn.com/imgextra/i2/O1CN01YcMB091fEbHV49h0N_!!6000000003975-0-tps-1125-2436.jpg')
        let $this = this
        window.addEventListener('resize', function () {
            // 调用图片自适应方法
            $this.size_img('https://img.alicdn.com/imgextra/i2/O1CN01YcMB091fEbHV49h0N_!!6000000003975-0-tps-1125-2436.jpg')
        });


        let name = window.localStorage.getItem('username')
        // console.log(name)
        if (name && name != '') {
            this.names = name
        }

        // this.trailerPagAnimation()
    },
    methods: {

        trailerPagAnimation() {

            //白光出现动画

            // return
            setTimeout(() => {
                document.getElementsByClassName('TrailerPage_bg')[0].classList.add(`TrailerPage_bgs`)
                setTimeout(() => {
                    document.getElementsByClassName('TrailerPage_mask')[0].classList.add(`TrailerPage_masks`)
                }, 2000)
            }, 1000)
        },
        advertisementbtn(){
            window.location.href ='amapuri://webview/amaponline?url=https%3A%2F%2Fcache.gaode.com%2Factivity%2F2023TheBeltAndRoad%2Findex.html%3Fgd_from%3DRicon&hide_title=1'
        },
        // a等于true时收下家书//反之在看一遍
        accept(a) {
            if (a) {
                document.getElementsByClassName('TrailerPage_notification_box')[0].classList.add(`TrailerPage_notification_boxs`)
                // console.log(document.getElementsByClassName('TrailerPage_notification_box')[0]);
                // setTimeout(()=>{
                // },2000)
                // document.getElementsByClassName('letter_img1-1')[0]

            } else {
                location.reload(); // 重新加载页面
            }
        },
        //让图片自适应当前可视区域的大小， 页面中的元素要用百分比来展位
        size_img(a) {
            let h = 0, w = 0
            let image = new Image()
            image.src = a
            image.onload = () => {
                h = image.height
                w = image.width
                const container = document.getElementsByClassName('TrailerPage_box')[0];
                const containerWidth = container.clientWidth;
                const containerHeight = container.clientHeight;
                const widthRatio = containerWidth / w;
                const heightRatio = containerHeight / h;
                // console.log(widthRatio, heightRatio)
                if (widthRatio > heightRatio) {
                    this.img_w = `${containerWidth}px`
                    this.img_h = `auto`
                } else {
                    this.img_w = `auto`
                    this.img_h = `${containerHeight}px`
                }
            }

        },
    }
}
</script>
<style lang="scss">
.TrailerPage_box {
    width: 7.5rem;
    height: 100vh;
    top: 0;
    left: 0;
    position: absolute;
    overflow: hidden;
    min-height: 13.34rem;

    .TrailerPage_bg {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        display: inline-block;

        .pyrotechnics1 {
            position: absolute;
            width: 30%;
            left: 0;
            top: 5%;
            opacity: 0;
            transform: translate(-50%) scale(0);
            animation: pyrotechnics1_a 2s 0s linear infinite;

            @keyframes pyrotechnics1_a {
                0% {
                    opacity: 0;
                    transform: translate(-50%) scale(0);

                }

                50% {
                    opacity: 1;
                    transform: translate(0) scale(1);
                }

                100% {
                    opacity: 0;
                    transform: translate(0) scale(1);
                }
            }
        }

        .pyrotechnics2 {
            position: absolute;
            width: 35%;
            right: 0;
            top: 0%;
            opacity: 0;
            transform: translate(50%) scale(0);
            animation: pyrotechnics2_a 2s 1s linear infinite;

            @keyframes pyrotechnics2_a {
                0% {
                    opacity: 0;
                    transform: translate(50%) scale(0);

                }

                50% {
                    opacity: 1;
                    transform: translate(0) scale(1);
                }

                100% {
                    opacity: 0;
                    transform: translate(0) scale(1);
                }
            }
        }

        .tinySpot1 {
            position: absolute;
            width: 4%;
            left: 20%;
            top: 5%;
            opacity: 0;
            animation: tinySpot_a 2s 1s linear infinite;
        }

        .tinySpot2 {
            position: absolute;
            width: 3%;
            right: 20%;
            top: 1%;
            opacity: 0;
            animation: tinySpot_a 2s 0.5s linear infinite;
        }

        .tinySpot3 {
            position: absolute;
            width: 5%;
            left: 10%;
            top: 30%;
            opacity: 0;
            animation: tinySpot_a 2s 0s linear infinite;
        }

        .tinySpot4 {
            position: absolute;
            width: 5%;
            right: 8%;
            top: 25%;
            opacity: 0;
            animation: tinySpot_a 2s 1.5s linear infinite;
        }

        @keyframes tinySpot_a {
            0% {
                opacity: 0;
            }

            50% {
                opacity: 1;
            }

            100% {
                opacity: 0;
            }
        }

        .TrailerPage_title {
            font-size: 0.28rem;
            position: absolute;
            top: 7%;
            text-align: center;
            width: 7rem;
            left: 0.25rem;
            z-index: 3;
            // font-weight: bold;
            color: #ffffff;
            letter-spacing: 0.07rem;
            font-family: nameR;

            div {
                height: 0.553rem;

                div {
                    opacity: 0;
                    height: 0.553rem;
                    transform: translateY(0.2rem);
                    overflow: hidden;
                    line-height: 0.553rem;
                    transition: all 1s;
                    text-shadow: 0rem 0rem 0.1rem rgb(0, 0, 0);
                }
            }
        }

        .TrailerPage_titles {

            :nth-child(1) {
                div {
                    height: 0.553rem;
                    opacity: 1;
                    transform: translateY(0rem);
                    transition: all 1s;
                }
            }

            :nth-child(2) {
                div {
                    height: 0.553rem;
                    opacity: 1;
                    transform: translateY(0rem);
                    transition: all 1s 1.2s;
                }
            }

            :nth-child(3) {
                div {
                    height: 0.553rem;
                    opacity: 1;
                    transform: translateY(0rem);
                    transition: all 1s 2.4s;
                }
            }

            :nth-child(4) {
                div {
                    height: 0.553rem;
                    opacity: 1;
                    transform: translateY(0rem);
                    transition: all 1s 3.6s;
                }
            }

            :nth-child(5) {
                div {
                    height: 0.55rem;
                    opacity: 1;
                    transform: translateY(0rem);
                    transition: all 1s 4.8s;
                }
            }

            :nth-child(6) {
                div {
                    height: 0.55rem;
                    opacity: 1;
                    transform: translateY(0rem);
                    transition: all 1s 6s;
                }
            }

            :nth-child(7) {
                div {
                    height: 0.55rem;
                    opacity: 1;
                    transform: translateY(0rem);
                    transition: all 1s 7.2s;
                }
            }

            :nth-child(8) {
                div {
                    height: 0.55rem;
                    opacity: 1;
                    transform: translateY(0rem);
                    transition: all 1s 8.4s;
                }
            }

            :nth-child(9) {
                div {
                    height: 0.55rem;
                    opacity: 1;
                    transform: translateY(0rem);
                    transition: all 1s 9.6s;
                }
            }

            :nth-child(10) {
                div {
                    height: 0.55rem;
                    opacity: 1;
                    transform: translateY(0rem);
                    transition: all 1s 10.8s;
                }
            }
        }

        .leftDoor {
            position: absolute;
            width: 30%;
            top: 54.2%;
            left: 22.2%;
            // transform-origin: left center;
            // transform: rotateY(69deg);
        }

        .rightDoor {
            position: absolute;
            width: 30%;
            top: 54.2%;
            right: 20.8%;
            // transform-origin: right center;
            // transform: rotateY(-76deg);
        }

        .entrance1 {

            position: absolute;
            width: 17%;
            right: 6%;
            top: 78%;
        }

        .entrance2 {

            position: absolute;
            width: 20%;
    left: 2%;
    top: 76.5%;
        }

        .gaoxiaode {
            position: absolute;
            width: 28%;
            left: 39%;
            top: 68%;
        }

        .gaoxiaode_hand {
            position: absolute;
            width: 9.8%;
            left: 35.3%;
            top: 76.8%;
        }

        .TrailerPage_mask {
            width: 7.5rem;
            height: 100vh;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            background-color: rgba(0, 0, 0, 0.5);
            opacity: 0;
            z-index: 4;

            .TrailerPage_notification_box {
                width: 7.5rem;
                height: 7.93rem;
                // height: auto;
                // background-color: #fff;
                position: absolute;
                left: 50%;
                top: 40%;
                transform: translate(-50%, -50%);

                // https://img.alicdn.com/imgextra/i3/O1CN01IueHSQ1q377NIK6yv_!!6000000005439-2-tps-811-1129.png
                // background: url('') no-repeat;
                // background-size: cover;
                // background-position: center;
                // padding: 0.25rem;
                // .notification_img {
                //     width: 6.65rem;
                //     position: relative;
                //     height: auto;
                //     left: 0.25rem;
                // }
                .letter_img1 {
                    width: 6.24rem;
                    position: absolute;
                    height: auto;
                    // left: 0.25rem;
                    // bottom: 0;
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%);
                }

                .letter_img1-1 {
                    position: absolute;
                    width: 6.24rem;
                    bottom: 2.55rem;
                    left: 50%;
                    transform-origin: center bottom;
                    transform: translate(-50%);
                    // opacity: 0;
                    // z-index: 2;
                    // transform: translate(-50%) rotatex(-150deg);
                }

                .letter_img1-2 {
                    position: absolute;
                    width: 6.24rem;
                    bottom: 0.57rem;
                    left: 50%;
                    transform: translate(-50%);
                    z-index: 2;
                    opacity: 0;
                    // transform-origin: center bottom;
                }

                .letter_img2 {
                    width: 5.40rem;
                    height: 7.83rem;
                    position: absolute;
                    bottom: 0.1rem;
                    left: 50%;
                    transform: translate(-50%);
                    overflow: hidden;

                    .letter_img2_b {
                        background: url('https://img.alicdn.com/imgextra/i4/O1CN01oPx6zD1E2ExwrKd1w_!!6000000000293-2-tps-811-1129.png') no-repeat;
                        background-size: cover;
                        background-position: center;
                        width: 100%;
                        height: 7.53rem;
                        position: absolute;
                        bottom: 0.3rem;
                        left: 0;


                        .name_inputs {
                            width: 4rem;
                            text-align: right;
                            position: absolute;
                            bottom: 1.68rem;
                            font-size: 0.35rem;
                            // font-weight: bold;
                            letter-spacing: 0.02rem;
                            right: 0.55rem;
                            font-family: nameS;
                        }
                    }


                }


                .letter_img3 {
                    width: 6.24rem;
                    position: absolute;
                    height: auto;
                    // left: 0.25rem;
                    // bottom: 0;
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%);
                }




                .btn {
                    position: absolute;
                    bottom: -1.5rem;
                    width: 5.5rem;
                    height: auto;
                    left: 50%;
                    transform: translate(-50%);
                    cursor: pointer;
                }

                .btn_t {
                    position: absolute;
                    bottom: -2.3rem;
                    width: 5rem;
                    height: auto;
                    left: 50%;
                    transform: translate(-50%);
                    cursor: pointer;
                }
            }

            .TrailerPage_notification_boxs {
                top: 40%;
                transform: translate(-50%, -70%);
                transition: all 1s 3.5s;

                .letter_img1-1 {
                    // z-index: 2;
                    transform: translate(-50%) rotatex(-150deg);
                    opacity: 0;
                    transition: transform 2s 2s, opacity 1s 3s;
                    animation: letter_img1-1_a 0s 2s linear forwards;

                    @keyframes letter_img1-1_a {
                        0% {
                            z-index: 0;
                        }

                        100% {
                            z-index: 2;
                        }
                    }
                }

                .letter_img1-2 {
                    opacity: 1;
                    transition: all 1s 3s;
                }

                .letter_img2 {
                    .letter_img2_b {
                        transform: translateY(5.5rem);
                        transition: all 2s;
                    }
                }
                .btn {
                    opacity: 0;
                    transition: all 2s 0s;
                }

                .btn_t {
                    transform: translate(-50%,-1.1rem);
                    opacity:1;
                    transition: all 1s 1s;
                }
            }

            .advertisementico{
                width: 2rem;
    position: absolute;
    bottom: 0.2rem;
    left: 0.1rem;
            }
            .advertisementicoTit{
                width: 2rem;
    position: absolute;
    bottom: 0.05rem;
    left: 0.12rem;
            }

            .whiteLight {
                width: 0rem;
                height: 0rem;
                position: absolute;
                top: 51%;
                left: 50%;
                z-index: 3;
                // background-color: rgb(252, 0, 0);
                opacity: 1;
                transform: scale(0);
                box-shadow: 0px 0px 1rem 1rem rgb(255, 255, 255), 0px 0px 1rem 1rem rgba(255, 255, 255, 1) inset;
                ;
                // box-shadow: 
                border: none;
            }
        }

        .TrailerPage_masks {
            opacity: 1;
            transition: all 1.5s;
        }
    }

    .TrailerPage_bgs {
        .leftDoor {
            opacity: 0;
            transition: transform 1.5s, opacity 1.5s 0.8s;
            transform-origin: left center;
            transform: rotateY(69deg);
        }

        .rightDoor {
            opacity: 0;
            transition: transform 1.5s, opacity 1.5s 0.8s;
            transform-origin: right center;
            transform: rotateY(-69deg);
        }

        .gaoxiaode {
            // position: absolute;
            // width: 28%;
            // left: 39%;
            // top: 68%;
        }

        .gaoxiaode_hand {
            // position: absolute;
            // width: 10%;
            // left: 35%;
            // top: 76.5%;

            transform-origin: right bottom;
            transform: rotate(-10deg);
            animation: gaoxiaode_hand_a 2s 0s linear infinite;

            @keyframes gaoxiaode_hand_a {
                0% {
                    transform: rotate(-10deg);
                }

                50% {
                    transform: rotate(20deg);
                }

                100% {
                    transform: rotate(-10deg);
                }
            }
        }
    }


}
</style>