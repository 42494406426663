<style lang="scss">
.logoIp {
  width: 2.99rem;
  position: fixed;
  bottom: 0rem;
  left: calc(50% - 1.79rem);

  .logoIp_img {
    width: 3.58rem;
    height: 3.6rem;
    background: url('https://img.alicdn.com/imgextra/i4/O1CN01U3tWN724ckYR5kf72_!!6000000007412-54-tps-299-300.apng') no-repeat;
    background-size: cover;
    background-position: center;
    animation: logoIp_a 5s linear infinite;

    @keyframes logoIp_a {

      // 0% {
      //   transform: translate(0rem,0rem) rotate(0deg);
      //   // animation-timing-function: cubic-bezier(0.37, 0.44, 0.40, 0.92)
      // }
      0% {
        transform: translate(-0.3rem, 0rem) rotate(5deg);
        animation-timing-function: cubic-bezier(0.83, 0.94, 0.74, 0.95)
      }

      // 50%{
      //   transform: translate(0rem,0rem) rotate(0deg);
      //   animation-timing-function: cubic-bezier(0.37, 0.44, 0.40, 0.92)
      // }
      50% {
        transform: translate(0.3rem, 0rem) rotate(-3deg);
        animation-timing-function: cubic-bezier(0.83, 0.94, 0.74, 0.95)
      }

      100% {
        transform: translate(-0.3rem, 0rem) rotate(5deg);
        // animation-timing-function: cubic-bezier(0.41, 0.24, 0.50, 0.75)

      }
    }
  }

}

.logoIps {
  opacity: 1 !important;
  transform: translate(0rem, calc(-100vh + 200%)) scale(4);
  transition: transform 3s !important;
}

.homes {
  position: relative;
  z-index: 2;
  opacity: 0;
  transition: all 2s 0.5s;
}

.pages {
  // border-top: 0.02rem solid red;
}

.pages0 {
  flex-shrink: 0;
  // align-self: flex-end;
  width: 7.5rem;
  height: 13.34rem;
  position: relative;
  background: url("https://img.alicdn.com/imgextra/i3/O1CN01LwSFaE1PKWYE8ozVJ_!!6000000001822-0-tps-1125-2001.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  // ---------------------------------------------《视频页和事件1》----------------------------------------------------------
  .event0 {

    // <!-- 视频 -->
    .video_box {
      opacity: 0;
      display: none;
      width: 5.5rem;
      height: 5.27rem;
      background: url('https://img.alicdn.com/imgextra/i2/O1CN01MSSiQE28B33bMSWxE_!!6000000007893-2-tps-866-831.png') center no-repeat;
      background-size: cover;
      position: absolute;
      top: 1rem;
      left: 50%;
      transform: translate(-50%);
      overflow: hidden;
      z-index: 1;
      transition: opacity 1s;

      .video {
        width: 5.35rem;
        height: 5.17rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow: hidden;
        border-radius: 0.4rem;

        .myVideo {
          width: 100%;
          height: 100%;
        }

        .video_m {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0);
          top: 0;
          left: 0;

          img {
            width: 0.8rem;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
          }
        }
      }
    }

    .video_boxs {
      opacity: 1;
      transition: opacity 1s;
    }

    // <!-- 福字和他的底座 -->
    .blessing_box {
      position: absolute;
      width: 2.86rem;
      top: 5.5rem;
      right: calc(50% - 1.43rem);
      transform: translate(0, -5rem) scale(2);
      perspective: 10rem;
      z-index: 1;
      cursor: pointer;

      .blessing {
        width: 2.86rem;
        position: absolute;
        top: 0rem;
        right: 0rem;
        animation: blessings_a 2s 0.5s linear infinite;

        @keyframes blessings_a {
          0% {
            transform: scale(1);
          }

          50% {
            transform: scale(0.9);
          }

          100% {
            transform: scale(1);
          }
        }

      }

      // .blessing_f {
      //   z-index: 1;
      //   backface-visibility: hidden;
      // }
      .blessing_d {
        width: 0.62rem;
        position: absolute;
        top: 2.6rem;
        right: 1.12rem;
        // animation: blessing_d_a 5s linear infinite;

        // @keyframes blessing_d_a {
        //   0% {
        //     transform: rotateY(0deg);
        //   }

        //   100% {
        //     transform: rotateY(720deg);
        //   }
        // }
      }

      .handClick2 {
        width: 0.7rem;
        position: absolute;
        // width: 2.86rem;
        top: 1.1rem;
        left: 50%;
        transform: translate(-50%) scale(0);
        z-index: 2;
        animation: handClick2_a 2s 1s linear infinite;

        @keyframes handClick2_a {
          0% {
            transform: translate(-50%) scale(0.5);
            opacity: 1;
          }

          80% {
            transform: translate(-50%) scale(1);
            opacity: 1;
          }

          100% {
            transform: translate(-50%) scale(1);
            opacity: 0;
          }
        }
      }

      .handClick1 {
        width: 0.5rem;
        position: absolute;
        // width: 2.86rem;
        top: 1.2rem;
        left: 50%;
        transform: translate(-50%) scale(0);
        z-index: 2;
        animation: handClick1_a 2s 1s linear infinite;

        @keyframes handClick1_a {
          0% {
            transform: translate(-50%) scale(0);
            opacity: 1;
          }

          50% {
            transform: translate(-50%) scale(1);
            opacity: 1;
          }

          100% {
            transform: translate(-50%) scale(1);
            opacity: 0;
          }
        }
      }

      .handClick {
        width: 0.75rem;
        position: absolute;
        top: 1.35rem;
        left: 1.35rem;
        z-index: 2;
        transform: translate(0.2rem) rotate(20deg);
        animation: handClick_a 2s 0s linear infinite;

        @keyframes handClick_a {
          0% {
            transform: translate(0.2rem) rotate(20deg);

          }

          50% {
            transform: rotate(0);

          }

          100% {
            transform: translate(0.2rem) rotate(20deg);

          }
        }
      }
    }

    .guidedSliding_box {
      opacity: 0;
      // display: none;
      // position: absolute;
      // left: 50%;
      // transform: translate(-50%);
      // top: 8rem;
      // width: 1.5rem;
      z-index: 2;
      position: absolute;
      bottom: 3.5rem;
      right: 2.8rem;
      width: 1.5rem;
      height: 1.7rem;
      transform: scale(2);

      .glide_tit {
        width: 1.42rem;
        position: absolute;
        top: 0;
        left: -0.1rem;
        opacity: 0;
        animation: glide_tit_a 0.8s 0s linear infinite;

        @keyframes glide_tit_a {
          0% {
            opacity: 0;
          }

          50% {
            opacity: 1;
          }

          100% {
            opacity: 0;
          }
        }
      }

      .glide_ico1 {
                    width: 1.12rem;
                    position: absolute;
                    right: -0.3rem;
                    bottom: 0.7rem;
                    transform: rotate(-60deg);
                    transform-origin: bottom right;
                    animation: glide_ico1_a 1.5s 0s linear infinite;

                    @keyframes glide_ico1_a {
                        0% {
                            transform: rotate(-60deg);
                            opacity: 1;
                        }

                        60% {
                            opacity: 1;
                        }

                        100% {
                            transform: rotate(-20deg);
                            opacity: 0;
                        }
                    }
                }

                .glide_ico2 {
                    width: 0.58rem;
                    position: absolute;
                    left: 0;
                    bottom: 0.2rem;
                    transform:rotate(180deg) rotateY(180deg);

                }

      // .guidedSliding {
      //   position: absolute;
      //   left: 0;
      //   // transform: translate(-50%);
      //   top: 0;
      //   width: 1.5rem;
      // }

      // .guidedSliding1 {
      //   position: absolute;
      //   left: 50%;
      //   transform: translate(-50%);
      //   top: 0.3rem;
      //   width: 0.6rem;
      //   animation: guidedSliding1_a 1s 0s linear infinite;

      //   @keyframes guidedSliding1_a {
      //     0% {
      //       opacity: 0;
      //       transform: translate(-50%, 0rem);
      //     }

      //     80% {
      //       opacity: 1;
      //       transform: translate(-50%, 0.4rem);
      //     }

      //     100% {
      //       opacity: 0;
      //       transform: translate(-50%, 0.4rem);
      //     }
      //   }
      // }

      // .guidedSliding2 {
      //   position: absolute;
      //   left: 50%;
      //   transform: translate(-50%);
      //   top: 0.8rem;
      //   width: 0.6rem;
      //   animation: guidedSliding2_a 1s 0.5s linear infinite;

      //   @keyframes guidedSliding2_a {
      //     0% {
      //       opacity: 0;
      //       transform: translate(-50%, 0rem);
      //     }

      //     80% {
      //       opacity: 1;
      //       transform: translate(-50%, 0.4rem);
      //     }

      //     100% {
      //       opacity: 0;
      //       transform: translate(-50%, 0.4rem);
      //     }
      //   }
      // }
    }

    .guidedSliding_boxs {
      opacity: 1;
      transition: all 2s;
    }

    // <!-- 福字和他的底座 -->
    .blessing_boxs {
      position: absolute;
      width: 2.86rem;
      top: 6.3rem;
      right: 0.8rem;
      perspective: 10rem;
      z-index: 1;
      transform: translate(0, 0rem) scale(1);
      transition: all 1s;

      .blessing {
        width: 2.86rem;
        position: absolute;
        top: 0rem;
        right: 0rem;
        animation: blessing_a 5s linear infinite;

        @keyframes blessing_a {
          0% {
            transform: rotateY(0deg);
          }

          100% {
            transform: rotateY(720deg);
          }
        }

      }

      // .blessing_f {
      //   z-index: 1;
      //   backface-visibility: hidden;
      // }
      .blessing_d {
        width: 0.62rem;
        position: absolute;
        top: 2.6rem;
        right: 1.12rem;
        // animation: blessing_d_a 5s linear infinite;

        // @keyframes blessing_d_a {
        //   0% {
        //     transform: rotateY(0deg);
        //   }

        //   100% {
        //     transform: rotateY(720deg);
        //   }
        // }
      }

      .handClick2 {
        display: none;
      }

      .handClick1 {
        display: none;
      }

      .handClick {
        display: none;
      }
    }

    // <!-- 坐标1 -->
    .coordinate_box {
      position: absolute;
      width: 0.71rem;
      top: 6.85rem;
      left: 1.4rem;

      .coordinate {
        position: absolute;
        width: 0.71rem;
        left: 0;
        top: 0;
        transform: translateY(-0.1rem) scale(0.8);
        animation: coordinate_a 1s linear infinite;

        @keyframes coordinate_a {
          0% {
            transform: translateY(-0.1rem) scale(0.8);
          }

          50% {
            transform: translateY(0rem) scale(1);
          }

          100% {
            transform: translateY(-0.1rem) scale(0.8);
          }
        }

      }

      .coordinate_d {
        position: absolute;
        width: 0.33rem;
        left: 50%;
        transform: translate(-50%);
        top: 1.07rem;
      }
    }

    // <!-- 坐标2 -->
    .coordinate_box2 {
      position: absolute;
      width: 0.71rem;
      top: 8.9rem;
      left: 5.85rem;

      .coordinate {
        position: absolute;
        width: 0.71rem;
        left: 0;
        top: 0;
        transform: translateY(-0.1rem) scale(0.8);
        animation: coordinate_a 1s 0.3s linear infinite;

        @keyframes coordinate_a {
          0% {
            transform: translateY(-0.1rem) scale(0.8);
          }

          50% {
            transform: translateY(0rem) scale(1);
          }

          100% {
            transform: translateY(-0.1rem) scale(0.8);
          }
        }

      }

      .coordinate_d {
        position: absolute;
        width: 0.33rem;
        left: 50%;
        transform: translate(-50%);
        top: 1.07rem;
      }
    }

    // <!-- 烟花1 -->
    .fireworks {
      position: absolute;
      width: 2.08rem;
      top: 5rem;
      right: -0.2rem;
      animation: fireworks_a 2s 1s linear infinite;
      transform: translate(1rem) scale(0);
      z-index: 0;
      // background-color: red;
    }

    // <!-- 烟花2 -->
    .fireworks2 {
      position: absolute;
      width: 1.9rem;
      top: 0.8rem;
      right: 0rem;
      animation: fireworks_a 2s linear infinite;
      transform: translate(1rem) scale(0);
      z-index: 0;
      // background-color: red;
    }

    // <!-- 烟花3 -->
    .fireworks3 {
      position: absolute;
      width: 2.08rem;
      top: 3rem;
      right: 5rem;
      animation: fireworks_a 2s 0.5s linear infinite;
      transform: translate(1rem) scale(0);
      z-index: 0;
      z-index: 0;
      // background-color: red;
    }

    // 烟花动画
    @keyframes fireworks_a {
      0% {
        transform: translate(1rem) scale(0);
      }

      80% {
        transform: translate(00.2rem) scale(0.8);
        opacity: 1;
      }

      100% {
        transform: translate(0) scale(1);
        opacity: 0;
      }
    }

    .lantern1 {
      position: absolute;
      width: 2.08rem;
      top: 0rem;
      left: -0.1rem;
      z-index: 1;
      transform: rotate(5deg);
      // transform-origin: top center;
      animation: lantern1_a 5s 0s linear infinite;

      @keyframes lantern1_a {
        0% {
          transform: rotate(5deg);
        }

        50% {
          transform: rotate(-3deg);
        }

        100% {
          transform: rotate(5deg);
        }
      }
    }

    .lantern2 {
      position: absolute;
      width: 1.32rem;
      top: 3.5rem;
      right: -0.1rem;
      z-index: 1;
      transform: rotate(2deg);
      transform-origin: top right;
      animation: lantern2_a 6s 0s linear infinite;

      @keyframes lantern2_a {
        0% {
          transform: rotate(2deg);
        }

        50% {
          transform: rotate(-3deg);
        }

        100% {
          transform: rotate(2deg);
        }
      }
    }
  }



}

.pages0_s {
  flex-shrink: 0;
  // align-self: flex-end;
  width: 7.5rem;
  height: 3.45rem;
  position: relative;
  background: url("https://img.alicdn.com/imgextra/i1/O1CN01YfmSbX26m1oUKPptA_!!6000000007703-0-tps-1125-531.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  // <!-- 文案 -->
  .title0_s {
    font-size: 0.3000rem;
    position: absolute;
    top: 0.5rem;
    text-align: center;
    width: 7.5rem;
    // left: 0.4rem;
    z-index: 3;
    // font-weight: bold;
    // color: #033088;
    color: #1162c1;
    letter-spacing: 0.01rem;
    font-family: nameR;

    div {
      height: 0.6rem;

      div {
        transform: translate(0, 100%) skew(-3deg);
        opacity: 0;
        height: 0.6rem;
        overflow: hidden;
        line-height: 0.6rem;
        transition: all 1s;

      }

      span {
        font-family: nameH;
        font-size: 0.38rem;
        // font-style: italic;
        transform: skew(-4deg);
        display: inline-block;
        color: #fdfeff;

        span {
          font-family: nameR;
          font-size: 0.3000rem;
          color: #2368cc;
          font-style: normal;
          //  transform: skew(-3deg);
          //  display: inline-block;
          // color: #0489fe;
        }
      }
    }
  }

  // 文案过度动画
  .start_title0_s {
    div {
      div {
        transform: translate(0rem) skew(-3deg);
        opacity: 1;
        height: 0.6rem;
        transition: all 1.5s;
      }

    }
  }
}

// <!-- 火车开出 -->
.pages1 {
  flex-shrink: 0;
  // align-self: flex-end;
  width: 7.5rem;
  height: 13.34rem;
  position: relative;
  background: url('https://img.alicdn.com/imgextra/i2/O1CN01D0OpyO1Jr94D4KQWY_!!6000000001081-0-tps-1125-2002.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  // <!-- 火车 -->
  .train {
    position: absolute;
    left: 0rem;
    top: 2.2rem;
    width: 7.5rem;
    height: 8.54rem;
    overflow: hidden;

    img {
      position: absolute;
      left: -1rem;
      top: 0rem;
      width: 17rem;
      transform: translate(-7rem, -1.78rem) scale(0.5);
      transition: transform 2s;
      opacity: 1;
    }
  }

  // 火车过度动画
  .start_train {
    img {
      transform: translate(0, 0) scale(1);
    }

  }

}

// <!-- 伸手递咖啡 -->
.pages2 {
  flex-shrink: 0;
  // align-self: flex-end;
  width: 7.5rem;
  height: 13.34rem;
  position: relative;
  background: url('https://img.alicdn.com/imgextra/i4/O1CN01pe1uh51hUkTZHTfDO_!!6000000004281-0-tps-1125-2001.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  // <!-- 车中手《事件2》 -->
  .reachOut {
    position: absolute;
    width: 0.84rem;
    height: 2.11rem;
    left: 3.06rem;
    top: 3.12rem;
    transform: rotate(46deg);
    transition: all 1s;
    overflow: hidden;

    .reachOut_img {
      background: url('https://img.alicdn.com/imgextra/i2/O1CN019B8YTZ1wXhFrv73Ur_!!6000000006318-2-tps-139-349.png') no-repeat;
      background-size: cover;
      background-position: top center;
      position: absolute;
      width: 0.84rem;
      height: 2.11rem;
      transform: translateY(2.11rem);
      transition: all 1s;
    }
  }

  //车中手过度动画 
  .start_reachOut {
    .reachOut_img {
      transition: all 1s;
      transform: translateY(0);
    }
  }

  // <!-- 递咖啡手《事件2》 -->
  .transfer {
    position: absolute;
    width: 3rem;
    height: 3.94rem;
    right: 0rem;
    top: 1.9rem;

    overflow: hidden;

    img {
      position: absolute;
      width: 3rem;
      top: 0;
      right: 0;
      transform: translate(3rem, 2.6rem);
      transition: all 1s;
    }
  }

  // 递咖啡手过渡动画
  .start_reachOut+.transfer {
    img {
      transform: translate(0rem, 0em);
      transition: all 1s;
    }

  }

}

// <!-- 手机打出租车 -->
.pages3 {
  flex-shrink: 0;
  // align-self: flex-end;
  width: 7.5rem;
  height: 13.34rem;
  position: relative;
  background: url('https://img.alicdn.com/imgextra/i4/O1CN01qotpVn1nOEXRsydtP_!!6000000005079-0-tps-1125-2001.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  .takingTaxi {
    position: absolute;
    width: 5.04rem;
    right: 1rem;
    top: 4.3rem;
    transform: translate(6rem, -0.3rem);
    transition: all 1s;
  }

  .start_takingTaxi {
    transition: all 1s 2.5s;
    transform: translate(0rem, 0rem);
  }

  .handPhone_box {
    position: absolute;
    width: 3.7rem;
    top: 6.1rem;
    left: 1.5rem;
    transform: translateY(2rem);
    opacity: 0;
    transition: all 1s;

    .handPhone {
      position: absolute;
      width: 3.7rem;
      top: 0;
      left: 0rem;
    }

    .thumb {
      position: absolute;
      width: 0.95rem;
      top: 1.13rem;
      left: 2.28rem;

      transform: translate(0.15rem, -0.1rem) rotate(11deg);
      transition: all 0.5s;
    }

  }

  .start_takingTaxi+.handPhone_box {
    transform: translateY(0);
    opacity: 1;
    transition: all 1s;

    .thumb {
      transition: all 0.5s 1s;
      transform: translate(0rem) rotate(-8deg);
    }
  }

  .mobileNotifications {
    position: absolute;
    width: 1rem;
    top: 5.9rem;
    left: 3.2rem;
    transform: translateY(0.8rem) scale(0.3);
    opacity: 0;
    transition: all 1s;
  }

  .start_takingTaxi+.handPhone_box+.mobileNotifications {
    transform: translateY(0) scale(1);
    opacity: 1;
    transition: all 1s 1.5s;
  }


}

// <!-- 路口盲区会车 -->
.pages5 {
  flex-shrink: 0;
  // align-self: flex-end;
  width: 7.5rem;
  height: 13.34rem;
  position: relative;
  background: url('https://img.alicdn.com/imgextra/i1/O1CN01MBguIr1PqaHFjvJ8t_!!6000000001892-0-tps-1125-2001.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  .earlyWarning_box {
    position: absolute;
    width: 7.5rem;
    left: 0;
    top: 1.25rem;
    height: 10rem;
    overflow: hidden;

    .earlyWarning {
      width: 1.72rem;
      position: absolute;
      left: 2.3rem;
      top: 7rem;
      opacity: 0;
      transform: translate(-0.1rem, 1rem) scale(1);

      // transform: translate(0.6rem, -3rem) scale(0.7);
      // transform: translate(0.4rem, -2rem) scale(0.7);

      // transform: translate(0.6rem, -4rem) scale(0.6) rotate(-25deg);
      // transform: translate(-0rem, -7rem) scale(0.2) rotate(-25deg);

    }

    .earlyWarning_ico {
      width: 1.8rem;
      position: absolute;
      right: 0.6rem;
      top: 3.2rem;
      opacity: 0;
      z-index: 1;
      // transform: translate(5rem);
    }

    .warning {
      width: 1.2rem;
      position: absolute;
      top: 3.8rem;
      left: 6rem;
      opacity: 1;
      transform: translate(2rem);
      transition: all 1s;

    }
  }

  .start_earlyWarning_box {
    .earlyWarning {
      animation: earlyWarning_a 5s linear forwards;

      @keyframes earlyWarning_a {

        0% {
          opacity: 0;
          transform: translate(-0.1rem, 1rem) scale(1);

        }

        20% {
          opacity: 1;
          transform: translate(0.4rem, -2rem) scale(0.7);

        }

        40% {
          opacity: 1;
          transform: translate(0.6rem, -3rem) scale(0.7);

        }

        55% {
          opacity: 1;
          transform: translate(0.6rem, -3rem) scale(0.7);

        }

        65% {
          opacity: 1;
          transform: translate(0.6rem, -4rem) scale(0.6) rotate(-25deg);

        }

        85% {
          opacity: 1;
          transform: translate(0.22rem, -6rem) scale(0.3) rotate(-25deg);


        }

        100% {
          opacity: 0;
          transform: translate(-0rem, -7rem) scale(0.2) rotate(-25deg);


        }
      }
    }

    .earlyWarning_ico {
      animation: earlyWarning_ico_a 1.5s 0.5s linear forwards;

      @keyframes earlyWarning_ico_a {

        0% {
          opacity: 0;

        }

        16% {
          opacity: 1;

        }

        32% {
          opacity: 0;

        }

        48% {
          opacity: 1;

        }

        64% {
          opacity: 0;

        }

        80% {
          opacity: 1;

        }

        100% {
          opacity: 0;

        }
      }
    }

    .warning {
      opacity: 1;
      transform: translate(-8rem);
      transition: all 5s 1s;
    }
  }


}

// <!-- 去淄博吃烧烤 -->
.pages4 {
  flex-shrink: 0;
  // align-self: flex-end;
  width: 7.5rem;
  height: 13.34rem;
  position: relative;
  background: url('https://img.alicdn.com/imgextra/i2/O1CN01xHiVTc1GeNAqINajA_!!6000000000647-0-tps-1125-2001.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  .grilledSkewers_box {
    position: absolute;
    width: 7.5rem;
    height: 7rem;
    top: 1rem;
    left: 0rem;
    // background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;

    .grilledSkewers1 {
      width: 1.71rem;
      position: absolute;
      right: 1.8rem;
      top: 2.2rem;
      opacity: 0;
      transform: translate(5rem, 0rem);
      transition: all 1s;
    }

    .grilledSkewers2 {
      width: 6.08rem;
      position: absolute;
      left: 0.75rem;
      top: 3.95rem;
      opacity: 0;
      transform: translate(-7rem, 0rem);
      transition: all 1s;
    }
  }

  .start_grilledSkewers_box {
    .grilledSkewers1 {
      opacity: 1;
      transform: translate(0rem, 0rem);
      transition: all 2s;
    }

    .grilledSkewers2 {
      opacity: 1;
      transform: translate(0rem, 0rem);
      transition: all 2s;
    }
  }
}

// <!-- 一家三口拍照 -->
.pages6 {
  flex-shrink: 0;
  // align-self: flex-end;
  width: 7.5rem;
  height: 13.34rem;
  position: relative;
  background: url('https://img.alicdn.com/imgextra/i2/O1CN0177cQkZ1jTx1Bwk2vb_!!6000000004550-0-tps-1125-2001.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  .billboard_box {
    position: absolute;
    top: 0;
    left: 0;
    width: 7.5rem;
    height: 9rem;
    // background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;

    .billboard {
      width: 7.1rem;
      position: absolute;
      right: 0;
      top: 1.5rem;
      opacity: 0;
      transform: translate(7.5rem);
      transition: all 1s;
    }

    .clouds {
      width: 7.5rem;
      position: absolute;
      left: 0;
      top: 4.9rem;
      opacity: 0;
      transform: translate(-7.5rem);
      transition: all 1s;
    }

    .familys {
      width: 3.83rem;
      position: absolute;
      left: 0.8rem;
      top: 3.3rem;
      opacity: 0;
      transform: translate(-3.5rem) scale(0.3);
      transition: all 1s;
    }

    .column {
      width: 1.84rem;
      position: absolute;
      right: 1rem;
      top: 3.6rem;
      opacity: 0;
      transform: translate(3rem);
      transition: all 1s;
    }
  }

  .start_billboard_box {
    .billboard {
      opacity: 1;
      transform: translate(0rem);
      transition: all 2s;
    }

    .clouds {
      opacity: 1;
      transform: translate(0rem);
      transition: all 2s;
    }

    .familys {
      opacity: 1;
      transform: translate(0rem) scale(1);
      transition: all 2s 1s;
    }

    .column {
      opacity: 1;
      transform: translate(0rem);
      transition: all 2s;
    }
  }
}

// <!-- 一张地图 -->
.pages7 {
  flex-shrink: 0;
  // align-self: flex-end;
  width: 7.5rem;
  height: 13.34rem;
  position: relative;
  background: url('https://img.alicdn.com/imgextra/i4/O1CN01T5zHAh1aP4Fq5Yqq7_!!6000000003321-0-tps-1125-2001.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  .maps_box {
    position: absolute;
    top: 0.2rem;
    left: 0;
    width: 7.5rem;
    overflow: hidden;
    height: 12rem;

    .maps {
      width: 7.5rem;
      position: absolute;
      top: 1.5rem;
      left: 0;
      transform: rotateX(20deg) scale(1.5);
      transition: all 1s;
    }

    .maps_coordinate {
      width: 2.04rem;
      position: absolute;
      top: 1rem;
      left: 2.7rem;
      opacity: 0;
      transform: translateY(-2rem) scale(0);
      transition: all 1s;
    }

    .cloud_left {
      width: 4.79rem;
      position: absolute;
      left: 0;
      top: 4.5rem;
      opacity: 0;
      transform: translate(-5rem);
      transition: all 1s;
    }

    .cloud_right {
      width: 5.9rem;
      position: absolute;
      right: 0;
      top: 3.5rem;
      opacity: 0;
      transform: translate(6rem);
      transition: all 1s;
    }

  }

  .start_maps_box {
    .maps {
      transform: rotateX(0deg) scale(1);
      transition: all 2s;
    }

    .maps_coordinate {
      opacity: 1;
      transform: translateY(0rem) scale(0.7);
      transition: all 2s 1s;
      animation: maps_coordinate_a 3s 3s linear infinite;

      @keyframes maps_coordinate_a {
        0% {
          transform: translateY(0rem) scale(0.7);
        }

        50% {
          transform: translateY(-0.5rem) scale(0.8);
          ;
        }

        100% {
          transform: rotateY(0deg) scale(0.7);
          ;
        }
      }
    }

    .cloud_left {
      opacity: 1;
      transform: translate(0rem);
      transition: all 2s;
    }

    .cloud_right {
      opacity: 1;
      transform: translate(0rem);
      transition: all 2s;
    }
  }

}

// <!-- 群人欢聚 -->
.pages8 {
  flex-shrink: 0;
  // align-self: flex-end;
  width: 7.5rem;
  height: 13.34rem;
  position: relative;
  background: url('https://img.alicdn.com/imgextra/i2/O1CN01OGmaJW1GW82sPkeyd_!!6000000000629-0-tps-1125-2009.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  .peoples_box {
    position: absolute;
    top: 1.3rem;
    left: 0;
    width: 7.5rem;
    height: 12rem;
    overflow: hidden;

    .people_left_box {
      position: absolute;
      width: 3.75rem;
      left: 0;
      top: 0rem;
      opacity: 0;
      transform: translate(-4rem);
      transition: all 1s;

      .people_left {
        width: 3.19rem;
        position: absolute;
        left: 0.6rem;
        top: 3.4rem;

      }

      .brand_left {
        width: 2.09rem;
        position: absolute;
        left: 0.2rem;
        top: 2.5rem;
      }
    }

    .people_right_box {
      position: absolute;
      width: 3.75rem;
      right: 0;
      top: 0rem;
      opacity: 0;
      transform: translate(4rem);
      transition: all 1s;

      .people_right {
        width: 3.62rem;
        position: absolute;
        top: 2.8rem;
        right: 0.2rem;

      }

      .brand_right {
        width: 1.9rem;
        position: absolute;
        right: 0.2rem;
        top: 1.9rem;
      }
    }

    .fall {
      width: 7.5rem;
      height: 11.9rem;
      position: absolute;
      top: 0.1rem;
      left: 0;
      overflow: hidden;

      li {
        opacity: 0;
        position: absolute;

        img {

          width: 100%;
        }
      }
    }


  }

  .start_peoples_box {
    .people_left_box {
      opacity: 1;
      transform: translate(0rem);
      transition: all 2s;
    }

    .people_right_box {
      opacity: 1;
      transform: translate(0rem);
      transition: all 2s;
    }

    .fall {
      transition: all 1s;
      opacity: 1;

      .down1 {
        animation: down 2s ease-out forwards;
      }

      .down2 {
        animation: down 4.5s ease-out forwards;
      }

      .down3 {
        animation: down 3s ease-out forwards;
      }

      .down4 {
        animation: down 2.2s ease-out forwards;
      }

      @keyframes down {
        0% {
          opacity: 1;
          transform: translateY(-1rem);
        }

        70% {
          opacity: 1;
        }

        100% {
          opacity: 0;
          transform: translateY(10rem);
        }
      }
    }

  }


}

// <!-- 自助打车机器 -->
.pages9 {
  flex-shrink: 0;
  // align-self: flex-end;
  width: 7.5rem;
  height: 13.34rem;
  position: relative;
  background: url('https://img.alicdn.com/imgextra/i1/O1CN01ioZcTv1KIcpdcwbJ4_!!6000000001141-0-tps-1125-2001.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  .serviceTaxi_box {
    position: absolute;
    width: 7.5rem;
    height: 5rem;
    overflow: hidden;
    left: 0;
    top: 5.8rem;

    .serviceTaxi {
      position: absolute;
      top: 0;
      right: 0.95rem;
      width: 5.57rem;
      opacity: 0;
      transform: translate(5rem, 0rem) scale(0.3);
      transition: all 1s;
    }
  }

  .start_serviceTaxi_box {
    .serviceTaxi {
      opacity: 1;
      transform: translate(0rem) scale(1);
      transition: all 2s;
    }
  }

}

// <!-- 地球 -->
.pages10 {
  flex-shrink: 0;
  // align-self: flex-end;
  width: 7.5rem;
  height: 13.34rem;
  position: relative;
  background: url('https://img.alicdn.com/imgextra/i2/O1CN01uUhktv25DOFBrsoGi_!!6000000007492-0-tps-1125-2001.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  .theSky_box {
    position: absolute;
    top: 1rem;
    left: 0;
    width: 7.5rem;
    height: 10rem;
    overflow: hidden;

    .theSky1 {
      width: 2.35rem;
      position: absolute;
      left: 1rem;
      top: 0rem;
      opacity: 0;
      transform: translate(-5rem, 0rem);
      transition: all 1s;
    }

    .theSky2 {
      width: 1.98rem;
      position: absolute;
      right: 0.7rem;
      top: 2.5rem;
      opacity: 0;
      transform: translate(5rem, 0rem);
      transition: all 1s;
    }

    .theSky3 {
      width: 0.98rem;
      position: absolute;
      left: 0.5rem;
      top: 4.5rem;
      opacity: 0;
      transform: translate(-5rem, 0rem);
      transition: all 1s;
    }

    .theSky4 {
      width: 1.99rem;
      position: absolute;
      left: 1rem;
      top: 5rem;
      opacity: 0;
      transform: translate(-5rem, 0rem);
      transition: all 1s;
    }

    .theSky5 {
      width: 2.76rem;
      position: absolute;
      right: 0.2rem;
      top: 5.5rem;
      opacity: 0;
      transform: translate(5rem, 0rem);
      transition: all 1s;
    }

    .theEarth_box {
      width: 5.53rem;
      height: 4.46rem;
      position: absolute;
      top: 2rem;
      left: 1rem;
      opacity: 0.5;
      transform: scale(1.5);
      transition: all 1s;

      .theEarth {
        width: 5.53rem;
        position: absolute;
        top: 0rem;
        left: 0rem;
      }

      .aircraft {
        width: 1.56rem;
        position: absolute;
        top: 2.75rem;
        left: 3.2rem;
        transform: translate(0rem, 0rem) rotateY(30deg);
        // transform: translate(-1.5rem,-0.4rem) rotate(30deg);
        // transform: translate(-3rem,-1.3rem) rotate(30deg);

        // transform: translate(-3.8rem,-2.5rem) rotateY(-100deg);
        // transform: translate(-3rem,-3rem) rotateY(-120deg);
        // transform: translate(1.2rem,-1.2rem) rotateY(80deg);
        // transform: translate(1.5rem,-0.1rem) rotateY(60deg);
        // transform: translate(0rem,0rem) rotateY(0deg);

        animation: aircraft_a 10s 0s linear infinite;

        @keyframes aircraft_a {
          0% {
            transform: translate(0rem, 0rem) rotateY(30deg);
          }

          12% {
            transform: translate(-1.5rem, -0.4rem) rotate(30deg);
          }

          24% {
            transform: translate(-3rem, -1.3rem) rotate(30deg);
          }

          36% {
            transform: translate(-3.8rem, -2.5rem) rotateY(-100deg);
            opacity: 1;
          }

          48% {
            transform: translate(-3rem, -3rem) rotateY(-120deg);
            opacity: 0;
          }

          60% {
            transform: translate(1.2rem, -1.2rem) rotateY(80deg);
            opacity: 0;
          }

          80% {
            transform: translate(1.5rem, -0.1rem) rotateY(60deg);
            opacity: 1;
          }

          100% {
            transform: translate(0rem, 0rem) rotateY(30deg);
          }
        }
      }

      .positioning1 {
        width: 0.54rem;
        position: absolute;
        top: 0.5rem;
        left: 1rem;
        animation: positioning_a 1s 0s linear infinite;

      }

      .positioning2 {
        width: 0.3rem;
        position: absolute;
        top: 0.3rem;
        left: 4rem;
        animation: positioning_a 1s 0.3s linear infinite;

      }

      .positioning3 {
        width: 0.4rem;
        position: absolute;
        top: 3.5rem;
        left: 2.8rem;
        animation: positioning_a 1s 0.6s linear infinite;
      }

      @keyframes positioning_a {
        0% {
          transform: translate(0rem, 0rem) scale(1);
        }

        50% {
          transform: translate(0rem, 0.1rem) scale(0.9);
        }

        100% {
          transform: translate(0rem, 0rem) scale(1);
        }
      }
    }

  }

  .start_theSky_box {
    .theSky {
      opacity: 1;
      transform: translate(0rem, 0rem);
      transition: all 2s;
    }

    .theEarth_box {
      opacity: 1;
      transform: scale(1);
      transition: all 2s;
    }
  }

}

// <!-- 旅游 -->
.pages11 {
  flex-shrink: 0;
  // align-self: flex-end;
  width: 7.5rem;
  height: 13.33rem;
  position: relative;
  background: url('https://img.alicdn.com/imgextra/i4/O1CN01SVlA171kkj8pxcZvm_!!6000000004722-0-tps-1125-2001.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  .satellite_box {
    width: 7.5rem;
    height: 10rem;
    position: absolute;
    left: 0rem;
    top: 0.2rem;
    overflow: hidden;

    .star1 {
      width: 5.32rem;
      position: absolute;
      right: 1rem;
      top: 4.25rem;
      opacity: 0;
      transform: translate(7rem, 0rem);
      transition: all 1s;
    }

    .star2 {
      width: 1.96rem;
      position: absolute;
      right: 1rem;
      top: 3rem;
      opacity: 0;
      transform: translate(5rem, 0rem);
      transition: all 1s;
    }

  }

  .start_satellite_box {
    .star1 {
      opacity: 1;
      transform: translate(-7rem, 0rem);
      transition: all 3s 1s;

    }

    .star2 {
      opacity: 1;
      transform: translate(0rem, 0rem);
      transition: all 2s;

    }

  }

}

// <!-- 交通情况（路上有很多小车） -->
.pages12 {
  flex-shrink: 0;
  // align-self: flex-end;
  width: 7.5rem;
  height: 13.34rem;
  position: relative;
  background: url('https://img.alicdn.com/imgextra/i4/O1CN01n2dXGw1RYqA96sNH0_!!6000000002124-0-tps-1125-2002.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  .traffic_box {
    position: absolute;
    width: 7.5rem;
    height: 10rem;
    left: 0;
    top: 0.95rem;
    overflow: hidden;

    .traffic1 {
      width: 5rem;
      position: absolute;
      left: 1.35rem;
      top: 3.15rem;
      z-index: 1;
    }

    .vehicleY1 {
      width: 0.8rem;
      position: absolute;
      top: 6.75rem;
      left: 0rem;
      // opacity: 0;
      transform: translate(-1rem, 0.6rem) scale(1);
      transition: all 1s;
      // transform: translate(7.5rem,-4.3rem) scale(0.4);
    }

    .vehicleY2 {
      width: 0.85rem;
      position: absolute;
      top: 6.75rem;
      left: 0rem;
      // transform: translate(7.5rem,-4.3rem) scale(0.4);
      transform: translate(7.5rem, -4.55rem) scale(0.4);

      transition: all 1s;

    }

    .vehicleY3 {
      width: 0.9rem;
      position: absolute;
      top: 6.75rem;
      left: 0rem;
      transform: translate(-1rem, 0.6rem) scale(1);
      transition: all 1s;

    }

    .vehicleX1 {
      width: 0.95rem;
      position: absolute;
      top: 7.7rem;
      right: 0rem;
      // opacity: 0;
      transform: translate(1.2rem, 0.6rem) scale(1);
      transition: all 1s;
    }

    .vehicleX2 {
      width: 0.95rem;
      position: absolute;
      top: 7.7rem;
      right: 0rem;
      transform: translate(1.2rem, 0.6rem) scale(1);
      transition: all 1s;
    }

    .vehicleX3 {
      width: 0.6rem;
      position: absolute;
      top: 4.3rem;
      right: 0rem;
      transform: translate(0.75rem, 0.22rem) scale(1);


      transition: all 1s;
    }
  }

  .start_traffic_box {
    .vehicleY1 {
      // transform: translate(7.5rem, -4.3rem) scale(0.4);
      // transition: all 5s;
      animation: vehicleY1_a 5s 0s linear infinite;

      @keyframes vehicleY1_a {
        0% {
          transform: translate(-1rem, 0.6rem) scale(1);
        }


        100% {
          transform: translate(7.5rem, -4.3rem) scale(0.4);
        }
      }
    }

    .vehicleY2 {
      // transform: translate(-1.9rem, 0rem);
      // transition: all 5s 2s;
      animation: vehicleY2_a 5s 0s linear infinite;

      @keyframes vehicleY2_a {
        0% {
          transform: translate(7.5rem, -4.55rem) scale(0.4);
        }


        100% {
          transform: translate(-1.9rem, 0rem);
        }
      }
    }

    .vehicleY3 {
      // transform: translate(7.5rem, -4.3rem) scale(0.4);
      // transition: all 5s 1s;
      animation: vehicleY3_a 5s 1s linear infinite;

      @keyframes vehicleY3_a {
        0% {
          transform: translate(-1rem, 0.6rem) scale(1);
        }


        100% {
          transform: translate(7.5rem, -4.3rem) scale(0.4);
        }
      }
    }

    .vehicleX1 {
      // transform: translate(-7.5rem, -3.8rem) scale(0.4);
      // transition: all 5s 0s;
      animation: vehicleX1_a 5s 1s linear infinite;

      @keyframes vehicleX1_a {
        0% {
          transform: translate(1.2rem, 0.6rem) scale(1);
        }


        100% {
          transform: translate(-7.5rem, -3.8rem) scale(0.4);
        }
      }
    }

    .vehicleX2 {
      // transform: translate(-7.5rem, -3.8rem) scale(0.4);
      // transition: all 5s 1s;
      animation: vehicleX2_a 5s 2s linear infinite;

      @keyframes vehicleX2_a {
        0% {
          transform: translate(1.2rem, 0.6rem) scale(1);
        }

        100% {
          transform: translate(-7.5rem, -3.8rem) scale(0.4);
        }
      }
    }

    .vehicleX3 {
      // transform: translate(-7.5rem, -2.12rem) scale(0.3);
      // transition: all 7s 0s;
      animation: vehicleX3_a 5s 1s linear infinite;

      @keyframes vehicleX3_a {
        0% {
          transform: translate(0.75rem, 0.22rem) scale(1);
        }

        100% {
          transform: translate(-7.5rem, -2.12rem) scale(0.3);
        }
      }
    }

  }

}

// <!-- 红绿灯 -->
.pages13 {
  flex-shrink: 0;
  // align-self: flex-end;
  width: 7.5rem;
  height: 13.34rem;
  position: relative;
  background: url('https://img.alicdn.com/imgextra/i2/O1CN01Q9bhiY1wVPHc3Lya9_!!6000000006313-0-tps-1125-2001.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  .trafficLight {
    position: absolute;
    width: 7.5rem;
    height: 10rem;
    left: 0;
    top: 1.15rem;
    overflow: hidden;

    .navigationLine {
      background: url('https://img.alicdn.com/imgextra/i2/O1CN01aDhh2Y24uc0AHJf7H_!!6000000007451-2-tps-284-971.png') no-repeat;
      background-size: cover;
      background-position: center;
      width: 1.89rem;
      height: 6.47rem;
      position: absolute;
      top: 0rem;
      left: 2.55rem;

    }

    .navigationLine_ico {
      background: url('https://img.alicdn.com/imgextra/i3/O1CN01XjNRL51hiUMByCK7e_!!6000000004311-2-tps-298-282.png') no-repeat;
      background-size: cover;
      background-position: center;
      width: 1.98rem;
      height: 1.88rem;
      position: absolute;
      top: 4.9rem;
      left: 3.03rem;


    }

    .Information {
      background: url('https://img.alicdn.com/imgextra/i4/O1CN0124uf7j1lq3MVCwMXE_!!6000000004869-2-tps-479-239.png') no-repeat;
      background-size: cover;
      background-position: center;
      width: 3.19rem;
      height: 1.59rem;
      position: absolute;
      top: 3.1rem;
      left: 0.3rem;
    }

    .redLight {
      background: url('https://img.alicdn.com/imgextra/i4/O1CN01106bBP1HugthLYPmY_!!6000000000818-2-tps-347-224.png') no-repeat;
      background-size: cover;
      background-position: center;
      width: 2.31rem;
      height: 1.49rem;
      position: absolute;
      top: 2.8rem;
      left: 4rem;

      .lights {
        background: url('https://img.alicdn.com/imgextra/i4/O1CN01S3BqA41ym0s8IXahs_!!6000000006620-2-tps-111-110.png') no-repeat;
        background-size: cover;
        background-position: center;
        width: 0.74rem;
        height: 0.73rem;
        position: absolute;
        top: 0.2rem;
        left: 0.42rem;
      }

      .lightsSum {
        position: absolute;
        width: 1rem;
        // background-color: rgba(255, 23, 23, 0.5);
        right: 0.2rem;
        height: 1.12rem;
        line-height: 1.12rem;
        font-size: 0.5rem;
        text-align: center;
        font-family: nameNum;
        color: rgb(239, 54, 54);
      }
    }


  }

  .start_trafficLight {
    .navigationLine {
      height: 0rem;
      transform: translate(-0.75rem, -0rem) scale(0.7);
      // opacity: 1;
      // transform: translate(-1.1rem, -4.95rem) scale(0.5);
      transition: all 3s 4s;
      opacity: 0;

    }

    .navigationLine_ico {
      opacity: 0;
      transform: translate(-1.35rem, -6.2rem) scale(0.4);
      transition: all 3s 4s;

    }

    .Information {
      opacity: 0;
      transition: all 2s 5s;
    }

    .redLight {
      opacity: 0;
      transition: all 2s 5s;

      .lights {
        background: url('https://img.alicdn.com/imgextra/i4/O1CN01LJbVfs1LfM6Gtfk2h_!!6000000001326-2-tps-111-110.png') no-repeat;
        background-size: cover;
        background-position: center;
        transition: all 0.2s 3s;
      }

      .lightsSum {
        color: rgba(46, 255, 185, 0.687);
        transition: all 0.2s 3s;
      }
    }

  }
}

// <!-- 村庄定位 -->
.pages14 {
  flex-shrink: 0;
  // align-self: flex-end;
  width: 7.5rem;
  height: 13.34rem;
  position: relative;
  background: url('https://img.alicdn.com/imgextra/i3/O1CN01MKFthc1oLm1mFKHnf_!!6000000005209-0-tps-1125-2001.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  .village_box {
    position: absolute;
    top: 0.14rem;
    left: 0;
    width: 7.5rem;
    height: 10rem;
    overflow: hidden;

    .village_ico1 {
      position: absolute;
      width: 1.56rem;
      height: 1.56rem;
      top: 1rem;
      left: 0.5rem;

      background: url('https://img.alicdn.com/imgextra/i2/O1CN01OPN2dq1eakorxdfaC_!!6000000003888-2-tps-235-235.png') no-repeat;
      background-size: cover;
      background-position: center;

      div {
        font-size: 0.2rem;
        // font-family: nameR;
        position: absolute;
        top: 1.21rem;
        color: #fff;
        right: 0.75rem;
      }
    }

    .village_ico2 {
      position: absolute;
      width: 0.73rem;
      height: 0.73rem;
      top: 2.9rem;
      left: 0.5rem;

      background: url('https://img.alicdn.com/imgextra/i4/O1CN01eqgjRt1OBX9qLx6Aj_!!6000000001667-2-tps-110-110.png') no-repeat;
      background-size: cover;
      background-position: center;
    }

    .village_ico3 {
      position: absolute;
      width: 0.78rem;
      height: 0.78rem;
      top: 3.9rem;
      left: 0.5rem;

      background: url('https://img.alicdn.com/imgextra/i1/O1CN01mSAdu21IrlzNYKkSq_!!6000000000947-2-tps-118-118.png') no-repeat;
      background-size: cover;
      background-position: center;
      font-size: 0.3rem;
      line-height: 0.6rem;
      // font-weight: bold;
      font-family: nameH;
      color: #06c5ff;
    }

    .village_vehicle1 {
      width: 1.6rem;
      position: absolute;
      top: 7.4rem;
      left: -2rem;

      // transform: translate(5.8rem, -3.3rem) scale(0.6);
      // transform: translate(6.5rem, -3.8rem) scale(0.5) rotate(-10deg);



    }

    .village_vehicle2 {
      width: 1.6rem;
      position: absolute;
      top: 7.5rem;
      left: -2rem;
      opacity: 0;
      // transform: translate(6.5rem, -3.8rem) scale(0.5) rotate(20deg);

      // transform: translate(6.5rem, -4.1rem) scale(0.4) rotate(0deg);
      // transform: translate(5.5rem, -4.6rem) scale(0.2) rotate(0deg);
      // transform: translate(5rem, -4.8rem) scale(0.1) rotate(0deg);





    }

    .route1 {
      position: absolute;
      height: 6.3rem;
      width: 1.3rem;
      // background-color: rgba(0, 0, 0, 0.5);
      overflow: hidden;
      left: 2.02rem;
      top: 2.6rem;
      transform: rotate(67deg);
      transition: all 0s;

      img {
        position: absolute;
        width: 5.36rem;
        transform: rotate(-67deg);
        left: -1.9rem;
        top: 1.58rem;
      }

    }

    .route2 {
      position: absolute;
      height: 2.4rem;
      width: 0.5rem;
      // background-color: rgba(0, 0, 0, 0.5);
      overflow: hidden;
      left: 4.5rem;
      top: 2.7rem;
      transform: rotate(-65deg);
      transition: all 0s;

      img {
        position: absolute;
        width: 1.99rem;
        transform: rotate(65deg);
        left: -0.75rem;
        top: 0.2rem;
      }

    }
  }

  .start_village_box {
    .village_vehicle1 {
      // opacity: 1;
      // transform: translate(0rem, 0rem) scale(1);
      // transition: all 2s;
      animation: village_vehicle1_a 2s 0s linear forwards;


      @keyframes village_vehicle1_a {
        0% {
          transform: translate(0rem, 0rem) scale(1);
        }

        70% {
          transform: translate(5.8rem, -3.3rem) scale(0.6);
        }

        99% {
          transform: translate(6.5rem, -3.8rem) scale(0.5) rotate(-10deg);
          opacity: 1;
        }

        100% {
          transform: translate(6.5rem, -3.8rem) scale(0.5) rotate(-10deg);
          opacity: 0;
        }
      }

    }

    .village_vehicle2 {
      // opacity: 1;
      // transform: translate(0rem, 0rem) scale(1);
      // transition: all 2s;
      animation: village_vehicle2_a 2s 2s linear forwards;


      @keyframes village_vehicle2_a {
        0% {
          opacity: 0;
          transform: translate(6.5rem, -3.8rem) scale(0.5) rotate(20deg);
        }

        1% {
          opacity: 1;
          transform: translate(6.5rem, -3.8rem) scale(0.5) rotate(20deg);
        }

        20% {
          transform: translate(6.5rem, -4.1rem) scale(0.4) rotate(0deg);
        }

        70% {
          transform: translate(5.5rem, -4.6rem) scale(0.2) rotate(0deg);
          opacity: 1;
        }

        100% {
          transform: translate(5rem, -4.8rem) scale(0.1) rotate(0deg);
          opacity: 0;
        }
      }

    }

    .route1 {
      // height: 0rem;
      // transform: translate(2.85rem, 1.95rem) rotate(67deg);
      // transition: all 2s 0.3s;
      animation: route1_a 1.3s 0.3s linear forwards;

      @keyframes route1_a {
        0% {
          height: 6.3rem;
          transform: translate(0rem, 0rem) rotate(67deg);
        }


        100% {
          height: 0rem;
          transform: translate(2.89rem, 1.95rem) rotate(67deg);
        }
      }
    }

    .route2 {
      opacity: 0;
      height: 0rem;
      transform: translate(-1.03rem, 0.7rem) rotate(-65deg);
      transition: all 3s 1.8s;

    }
  }

}

// <!-- 坐轮椅 -->
.pages15 {
  flex-shrink: 0;
  // align-self: flex-end;
  width: 7.5rem;
  height: 16.29rem;
  position: relative;
  background: url('https://img.alicdn.com/imgextra/i2/O1CN019ZQEo31YI4yqOAQCb_!!6000000003035-0-tps-1125-2446.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  .wheelchair_box {
    position: absolute;
    top: -1rem;
    left: 0;
    width: 7.5rem;
    height: 16rem;
    overflow: hidden;

    .bubble1 {
      width: 3.44rem;
      position: absolute;
      top: 1rem;
      right: 0rem;
      opacity: 0;
      transform: translate(4rem, 0rem);
      transition: all 1s;
    }

    .bubble2 {
      width: 2.58rem;
      position: absolute;
      left: 0.2rem;
      top: 5rem;
      opacity: 0;
      transform: translate(-4rem, 0rem);
      transition: all 1s;
    }

    .bubble3 {
      width: 1.82rem;
      position: absolute;
      top: 8.5rem;
      right: 0.5rem;
      opacity: 0;
      transform: translate(4rem, 0rem);
      transition: all 1s;
    }

    .wheelchair {
      width: 3.4rem;
      height: 4.92rem;
      position: absolute;
      left: 0.2rem;
      top: 8rem;
      opacity: 0;
      transform: translate(-1rem, 3rem);
      transition: all 1s;

      .img1 {
        position: absolute;
        width: 3.4rem;
        top: 0;
        left: 0;
      }

      .screen {
        transition: all 0.5s;
        background: url('https://img.alicdn.com/imgextra/i3/O1CN01AByFSz1dNBT595FlK_!!6000000003723-2-tps-252-274.png') no-repeat;
        background-size: cover;
        background-position: center;
        width: 1.68rem;
        height: 1.82rem;
        position: absolute;
        top: -1rem;
        left: 2.2rem;

        // opacity: 0;
        // transform: translate(-0.7rem, 1.2rem) scale(0.2);
        // transition: all 1s;
        .dinate {
          width: 0.29rem;
          position: absolute;
          left: 0.35rem;
          top: 0.22rem;
        }

        .dinates {
          width: 0.22rem;
          position: absolute;
          left: 0.8rem;
          top: 1.2rem;
          transition: all 2s;

        }
      }

      .screen2 {
        opacity: 0;
        transition: all 0.5s;
        background: url('https://img.alicdn.com/imgextra/i2/O1CN016PG8s01mTRQCWRIXL_!!6000000004955-2-tps-277-287.png') no-repeat;
        background-size: cover;
        background-position: center;
        width: 1.68rem;
        height: 1.82rem;
        position: absolute;
        top: -1rem;
        left: 2.2rem;


        .screen2_ico1 {
          width: 0.62rem;
          position: absolute;
          top: 0.5rem;
          left: 0.55rem;
          transform: rotate(0);
          transform-origin: left center;
          animation: screen2_ico1_a 1.5s 0s linear infinite;

          @keyframes screen2_ico1_a {
            0% {
              transform: rotate(0deg);
            }

            50% {
              transform: rotate(20deg);
            }

            100% {
              transform: rotate(0deg);

            }
          }
        }

        .screen2_ico2 {
          width: 0.18rem;
          position: absolute;
          top: 0.4rem;
          left: 0.6rem;
        }

        .screen2_tit {
          font-size: 0.22rem;
          position: absolute;
          bottom: 0.3rem;
          width: 100%;
          text-align: center;
          color: #033088;
          animation: screen2_tit_a 0.5s 3s linear infinite;

          @keyframes screen2_tit_a {
            0% {
              opacity: 1;
            }

            50% {
              opacity: 0;

            }

            100% {
              opacity: 1;

            }
          }
        }

        .handClickws {
          width: 2.75rem;
          position: absolute;
          top: 0.7rem;
          left: 1rem;
          z-index: 2;
          transform: translate(0.2rem) rotate(20deg);
          animation: handClick_a 2s 2s linear infinite;

          @keyframes handClick_a {
            0% {
              transform: translate(0.2rem) rotate(20deg);

            }

            50% {
              transform: rotate(0);

            }

            100% {
              transform: translate(0.2rem) rotate(20deg);

            }
          }
        }
      }
    }



    .cardiacLandmark_box1 {
      position: absolute;
      width: 0.68rem;
      top: 4.9rem;
      left: 3.8rem;
      transform: scale(0.8);

      .cardiacLandmark {
        position: absolute;
        width: 0.68rem;
        z-index: 1;
        animation: cardiacLandmark_a 1.5s 0s linear infinite;

        @keyframes cardiacLandmark_a {
          0% {
            transform: translate(0rem, 0rem) scale(1);
          }

          50% {
            transform: translate(0rem, -0.1rem) scale(0.9);
          }

          100% {
            transform: translate(0rem, 0rem) scale(1);
          }
        }
      }

      .cardiacLandmarky {
        width: 0.62rem;
        position: absolute;
        top: 0.8rem;
        left: 0.37rem;
      }
    }

    .cardiacLandmark_box2 {
      position: absolute;
      width: 0.68rem;
      top: 7.6rem;
      left: 0.3rem;
      transform: scale(1);

      .cardiacLandmark {
        position: absolute;
        width: 0.68rem;
        z-index: 1;
        animation: cardiacLandmark_a 1.5s 0s linear infinite;

        @keyframes cardiacLandmark_a {
          0% {
            transform: translate(0rem, 0rem) scale(1);
          }

          50% {
            transform: translate(0rem, -0.1rem) scale(0.9);
          }

          100% {
            transform: translate(0rem, 0rem) scale(1);
          }
        }
      }

      .cardiacLandmarky {
        width: 0.62rem;
        position: absolute;
        top: 0.8rem;
        left: 0.37rem;
      }
    }

    .cardiacLandmark_box3 {
      position: absolute;
      width: 0.68rem;
      top: 10.2rem;
      left: 6rem;
      transform: scale(0.9);

      .cardiacLandmark {
        position: absolute;
        width: 0.68rem;
        z-index: 1;
        animation: cardiacLandmark_a 1.5s 0s linear infinite;

        @keyframes cardiacLandmark_a {
          0% {
            transform: translate(0rem, 0rem) scale(1);
          }

          50% {
            transform: translate(0rem, -0.1rem) scale(0.9);
          }

          100% {
            transform: translate(0rem, 0rem) scale(1);
          }
        }
      }

      .cardiacLandmarky {
        width: 0.62rem;
        position: absolute;
        top: 0.8rem;
        left: 0.37rem;
      }
    }
  }

  .start_wheelchair_box {
    .bubble1 {
      opacity: 1;
      transform: translate(0rem, 0rem);
      transition: all 2s 0s;
    }

    .bubble2 {
      opacity: 1;
      transform: translate(0rem, 0rem);
      transition: all 2s 0s;
    }

    .bubble3 {
      opacity: 1;
      transform: translate(0rem, 0rem);
      transition: all 2s 0s;
    }

    .wheelchair {
      opacity: 1;
      transform: translate(0.5rem, -1rem);
      transition: all 2s;

      .screen {
        opacity: 0;
        transition: all 0.5s 2s;

        .dinates {
          transform: translate(0rem, -0.5rem);
          transition: all 2s;
        }

      }

      .screen2 {
        opacity: 1;
        transition: all 0.5s 2s;


      }
    }

    // .screen {
    //   opacity: 1;
    //   transform: translate(0rem, 0rem) scale(1);
    //   transition: all 1s 2s;
    // }
  }

}

// 导航条
.progres_box {

  position: fixed;
  left: calc(50vw - 3.75rem);
  height: 10rem;
  width: 7.5rem;
  top: 50%;
  transform: translateY(-50%);
  // z-index: 4;
  pointer-events: none;

  .progresa {
    opacity: 0;
    transition: all 1s;
    position: absolute;
    top: 0;
    height: 100%;
    width: 0.23rem;
    right: 0.1rem;
    background-color: #ced8ec;
    border: 0.05rem solid #fff;
    border-radius: 0.3rem;

    // opacity: 0.8;
    // overflow: hidden;
    .progres_x {
      width: 100%;
      height: 100%;
      background-color: #61d486;
      border-radius: 0.3rem;
      position: relative;

      img {
        width: 0.5rem;
        position: absolute;
        left: calc(50% - 0.25rem);
        bottom: -0.25rem;
      }
    }
  }
}

.progresa_s {
  opacity: 0.8 !important;
  transition: all 1s;
}







.home {

  // <!-- 文案 -->
  // .title1,
  // .title2,
  // .title3,
  // .title4,
  // .title5,
  // .title6,
  // .title7,
  // .title8,
  // .title9,
  // .title10,
  // .title11,
  // .title12,
  // .title13,
  // .title14,
  // .title15,
  .title15 {
    font-size: 0.2500rem;
    position: absolute;

    text-align: center;
    width: 7.1rem;
    left: 0.2rem;
    z-index: 3;
    // font-weight: bold;
    // color: #033088;
    color: #1162c1;
    letter-spacing: 0.01rem;
    font-family: nameR;

    div {
      height: 0.6rem;

      div {
        transform: translate(-100%, 0rem) skew(-3deg);
        opacity: 0;
        height: 0.6rem;
        overflow: hidden;
        line-height: 0.6rem;
        transition: all 1s;

      }

      span {
        font-family: nameH;
        font-size: 0.35rem;
        // font-style: italic;
        transform: skew(-4deg);
        display: inline-block;
        color: #fdfeff;
        letter-spacing: 0.01rem;

        span {
          font-family: nameR;
          font-size: 0.2500rem;
          color: #2368cc;
          font-style: normal;
          letter-spacing: 0rem;
          //  transform: skew(-3deg);
          //  display: inline-block;
          // color: #0489fe;
        }
      }
    }
  }



  .title1 {
    font-size: 0.3000rem;
    position: absolute;

    text-align: left;
    width: 7.1rem;
    left: 0.4rem;
    z-index: 3;
    // font-weight: bold;
    // color: #033088;
    color: #1162c1;
    letter-spacing: 0.01rem;
    font-family: nameR;

    .titszz1 {
      position: absolute;
      left: 0;
      top: 0;

      div {
        height: 0.6rem;

        div {
          transform: translate(-100%, 0rem) skew(-3deg);
          opacity: 0;
          height: 0.6rem;
          overflow: hidden;
          line-height: 0.6rem;
          transition: all 1s;

        }

        span {
          font-family: nameH;
          font-size: 0.38rem;
          // font-style: italic;
          transform: skew(-4deg);
          display: inline-block;
          color: #fdfeff;

          span {
            font-family: nameR;
            font-size: 0.3000rem;
            color: #2368cc;
            font-style: normal;
            //  transform: skew(-3deg);
            //  display: inline-block;
            // color: #0489fe;
          }
        }
      }
    }

    .titszz2 {
      position: absolute;
      left: 0;
      top: 0;

      div {
        height: 0.6rem;

        div {
          transform: translate(-100%, 0rem) skew(-3deg);
          opacity: 0;
          height: 0.6rem;
          overflow: hidden;
          line-height: 0.6rem;
          transition: all 1s;

        }

        span {
          font-family: nameH;
          font-size: 0.38rem;
          // font-style: italic;
          transform: skew(-4deg);
          display: inline-block;
          color: #fdfeff;

          span {
            font-family: nameR;
            font-size: 0.3000rem;
            color: #2368cc;
            font-style: normal;
            //  transform: skew(-3deg);
            //  display: inline-block;
            // color: #0489fe;
          }
        }
      }
    }


  }

  .start_title1 {
    .titszz1 {
      div {
        div {
          transform: translate(0rem) skew(-3deg);
          opacity: 1;
          height: 0.6rem;
          transition: all 0.8s;
          animation: titszz1_a 1s 2.8s linear forwards;

          @keyframes titszz1_a {
            0% {
              transform: translate(0rem) skew(-3deg);
              opacity: 1;
            }

            100% {
              transform: translate(100%) skew(-3deg);
              opacity: 0;
            }
          }
        }
      }
    }

    .titszz2 {
      div {
        div {
          transform: translate(0rem) skew(-3deg);
          opacity: 1;
          height: 0.6rem;
          transition: all 1.5s 3s;
        }
      }
    }
  }

  //从左侧出
  .title5,
  .title7,
  .title10,
  .title12,
  .title14 {
    font-size: 0.3000rem;
    position: absolute;

    text-align: left;
    width: 7.1rem;
    left: 0.4rem;
    z-index: 3;
    // font-weight: bold;
    // color: #033088;
    color: #1162c1;
    letter-spacing: 0.01rem;
    font-family: nameR;

    div {
      height: 0.6rem;

      div {
        transform: translate(-100%, 0rem) skew(-3deg);
        opacity: 0;
        height: 0.6rem;
        overflow: hidden;
        line-height: 0.6rem;
        transition: all 1s;

      }

      span {
        font-family: nameH;
        font-size: 0.38rem;
        // font-style: italic;
        transform: skew(-4deg);
        display: inline-block;
        color: #fdfeff;

        span {
          font-family: nameR;
          font-size: 0.3000rem;
          color: #2368cc;
          font-style: normal;
          //  transform: skew(-3deg);
          //  display: inline-block;
          // color: #0489fe;
        }
      }
    }
  }

  // 从右侧出
  .title2,
  .title3,
  .title4,
  .title6,
  .title8,
  .title9,
  .title11,
  .title13 {
    font-size: 0.3000rem;
    position: absolute;

    text-align: right;
    width: 7.0rem;
    right: 0.5rem;
    z-index: 3;
    // font-weight: bold;
    // color: #033088;
    color: #1162c1;
    letter-spacing: 0.01rem;
    font-family: nameR;

    div {
      height: 0.6rem;

      div {

        transform: translate(100%, 0rem) skew(-3deg);
        opacity: 0;
        height: 0.6rem;
        overflow: hidden;
        line-height: 0.6rem;
        transition: all 1s;

      }

      span {
        font-family: nameH;
        font-size: 0.38rem;
        // font-style: italic;
        transform: skew(-4deg);
        display: inline-block;
        color: #fdfeff;

        span {
          font-family: nameR;
          font-size: 0.3000rem;
          color: #2368cc;
          font-style: normal;
          //  transform: skew(-3deg);
          //  display: inline-block;
          // color: #0489fe;
        }
      }
    }
  }


  .start_title1,
  .start_title2,
  .start_title3,
  .start_title4,
  .start_title5,
  .start_title6,
  .start_title7,
  .start_title8,
  .start_title9,
  .start_title10,
  .start_title11,
  .start_title12,
  .start_title13,
  .start_title14,
  .start_title15 {
    div {
      div {
        transform: translate(0rem) skew(-3deg);
        opacity: 1;
        height: 0.6rem;
        transition: all 1.5s;
      }
    }
  }

  .title1 {
    top: 8.8rem;
  }

  .title2 {
    top: 7.1rem;
  }

  .title3 {
    top: 8.2rem;
  }

  .title4 {
    top: 8.2rem;
  }

  .title5 {
    top: 8.7rem;
  }

  .title6 {
    top: 7.8rem;
  }

  .title7 {
    top: 8.2rem;
  }

  .title8 {
    top: 8.2rem;
  }

  .title9 {
    top: 9.2rem;
  }

  .title10 {
    top: 8.2rem;
  }

  .title11 {
    top: 8.2rem;
  }

  .title12 {
    top: 9.1rem;
  }

  .title13 {
    top: 8.15rem;
  }

  .title14 {
    top: 8.3rem;
  }

  .title15 {
    top: 11rem;
  }
}
</style>
<template>
  <div>
    <Cover ref="childRef" @coverFun="coverFun" v-show="covers"></Cover>
    <Loading v-if="loading" @loadingFun="loadingFun"></Loading>
    <TrailerPage ref="trailerPagRef" v-if="trailerPage"></TrailerPage>

    <Excess ref="excessRef" @excessFun="excessFun" v-if="excessShow"></Excess>
    <WallPage ref="wallPageRef" @wallPagFun="wallPagFun" v-if="wallPage"></WallPage>
    <div class="home" id="home" v-show="home">
      <!-- 视频页面 -->
      <div  class="pages pages0">
        <div class="event0">
          <!-- 视频 -->
          <div class="video_box" data-hide="0">
            <div class="video">
              <video
                poster='https://img.alicdn.com/imgextra/i2/6000000001987/O1CN01Gejn2D1QY5tWwM212_!!6000000001987-0-tbvideo.jpg'
                class="myVideo" id="myVideo" style="width: 100%;display: block;" ref="easyPlayer" preload="true"
                webkit-playsinline="true" playsinline="playsinline" x5-playsinline="x5-playsinline"
                x-webkit-airplay="allow" x5-video-player-type="h5" x5-video-player-fullscreen="true"
                x5-video-orientation="portrait" x5-video-ignore-metadata="true" crossorigin="Anonymous"
                x5-crossorigin="Anonymous" :autoplay="false" tabindex="-1" :src="mp4Url" 
                @loadeddata="onLoadedData"
                @click="videoM"></video>
              <div class="video_m" v-if="!video_m">
                <img src="https://res.xiaoxxx.com/urlGather/20231211d43b79f9074aefde936965.png" v-if="play" alt=""
                  @click="palys(false)">
                <img src="https://res.xiaoxxx.com/urlGather/20231211119ab2658f4a8c94999418.png" v-if="!play" alt=""
                  @click="palys(true)">
              </div>
            </div>
          </div>

          <!-- <div class="guidedSliding_box">
            <img class="guidedSliding1"
              src="https://img.alicdn.com/imgextra/i3/O1CN01qgNv0J1exCDt1Iq00_!!6000000003937-2-tps-67-44.png" alt="">
            <img class="guidedSliding2"
              src="https://img.alicdn.com/imgextra/i3/O1CN01qgNv0J1exCDt1Iq00_!!6000000003937-2-tps-67-44.png" alt="">
            <img class="guidedSliding"
              src="https://img.alicdn.com/imgextra/i3/O1CN01a3jZhd1DACDzx300Z_!!6000000000175-2-tps-229-60.png" alt="">
          </div> -->
          <div class="guidedSliding_box">
            <img class="glide_tit"
              src="https://img.alicdn.com/imgextra/i2/O1CN01amTrjW1z2xWwgTK2N_!!6000000006657-2-tps-213-80.png" alt="">
            <img class="glide_ico1"
              src="https://img.alicdn.com/imgextra/i3/O1CN013szXy527NaHzzm40P_!!6000000007785-2-tps-169-169.png" alt="">
            <img class="glide_ico2"
              src="https://img.alicdn.com/imgextra/i1/O1CN01NuFhLW1N5I9BX7J95_!!6000000001518-2-tps-87-163.png" alt="">
          </div>

          <!-- 灯笼1 -->
          <img class="lantern1"
            src="https://img.alicdn.com/imgextra/i1/O1CN01a9CBjM25pP6vCBLlS_!!6000000007575-2-tps-296-605.png" alt="">
          <!-- 灯笼2 -->
          <img class="lantern2"
            src="https://img.alicdn.com/imgextra/i1/O1CN01HBL4h429V1xEAaIax_!!6000000008072-2-tps-199-498.png" alt="">
          <!-- 福字和他的底座 -->
          <div class="blessing_box" @click="blessingClick">
            <img class="handClick2"
              src="https://img.alicdn.com/imgextra/i4/O1CN01wfLby31QBC5n0p7kl_!!6000000001937-2-tps-200-200.png" alt="">
            <img class="handClick1"
              src="https://img.alicdn.com/imgextra/i1/O1CN01H0tY1P1PAuE7M9xzG_!!6000000001801-2-tps-120-120.png" alt="">
            <img class="handClick"
              src="https://img.alicdn.com/imgextra/i3/O1CN012JuUpg1otfKKTTgMw_!!6000000005283-2-tps-214-217.png" alt="">
            <img class="blessing blessing_f"
              src="https://img.alicdn.com/imgextra/i1/O1CN01NHwYzM1NcGefnyIvB_!!6000000001590-2-tps-429-429.png" alt="">
            <img class="blessing_d"
              src="https://img.alicdn.com/imgextra/i3/O1CN01rgGEAz1u0bP0PFo0q_!!6000000005975-2-tps-94-32.png" alt="">
          </div>
          <!-- 坐标1 -->
          <div class="coordinate_box">
            <img class="coordinate"
              src="https://img.alicdn.com/imgextra/i1/O1CN01FpR5kS1BxXfAGWBRD_!!6000000000012-2-tps-107-150.png" alt="">
            <img class="coordinate_d"
              src="https://img.alicdn.com/imgextra/i4/O1CN01t5KPaH1tlwj7SIUMp_!!6000000005943-2-tps-50-17.png" alt="">

          </div>
          <!-- 坐标2 -->
          <div class="coordinate_box2">
            <img class="coordinate"
              src="https://img.alicdn.com/imgextra/i1/O1CN01FpR5kS1BxXfAGWBRD_!!6000000000012-2-tps-107-150.png" alt="">
            <img class="coordinate_d"
              src="https://img.alicdn.com/imgextra/i4/O1CN01t5KPaH1tlwj7SIUMp_!!6000000005943-2-tps-50-17.png" alt="">

          </div>
          <!-- 烟花1 -->
          <img class="fireworks"
            src="https://img.alicdn.com/imgextra/i1/O1CN01DamkFB1G56fRSax4Z_!!6000000000570-2-tps-313-362.png" alt="">

          <!-- 烟花2 -->
          <img class="fireworks2"
            src="https://img.alicdn.com/imgextra/i1/O1CN01DamkFB1G56fRSax4Z_!!6000000000570-2-tps-313-362.png" alt="">

          <!-- 烟花3 -->
          <img class="fireworks3"
            src="https://img.alicdn.com/imgextra/i1/O1CN01DamkFB1G56fRSax4Z_!!6000000000570-2-tps-313-362.png" alt="">

        </div>
      </div>
      <div  class="pages pages0_s" v-if="false == true">
        <div class="title0_s" data-class="title0_s" data-offset="100" data-hide="0">
          <div>
            <div>每当你问我在高德是做什么的</div>
          </div>
          <div>
            <div>我想说 我就像一个魔法师</div>
          </div>
          <div>
            <div>让你的出行和生活充满惊喜！</div>
          </div>
          <!-- <div>
            <div><span><span>每当你问我在高德是做什么的</span></span></div>
          </div>
          <div>
            <div><span><span>我想说 我就像一个魔法师</span></span></div>
          </div>
          <div>
            <div><span><span>让你的出行和生活充满惊喜！</span></span></div>
          </div> -->
          <!-- <div>
            <div>打卡平时我工作、吃饭的地方</div>
          </div> -->
          <!-- <div style="margin-top: 0.1rem;">
            <div><span>共赴<p class="projectit">高德FamilyDay</p>的美好之约</span></div>
          </div>
          <div style="margin-top: 0.1rem;">
            <div>看着你眼角的笑意</div>
          </div>
          <div>
            <div>我真的很幸福</div>
          </div> -->
        </div>
      </div>
      <!-- 一家三口拍照 -->
      <div data-pages-shifting="-40" class="pages pages6" data-pages="pages6" v-if="false == true">
        <div class="billboard_box" data-class="billboard_box" data-offset="150">
          <img class="billboard"
            src="https://img.alicdn.com/imgextra/i4/O1CN01Puh7Pk1GsZRiqE0AG_!!6000000000678-2-tps-1066-842.png" alt="">
          <img class="clouds" src="https://img.alicdn.com/imgextra/i1/O1CN01Vs80OE1WRZxNdZfby_!!6000000002785-2-tps-1125-406.png
" alt="">
          <img class="familys"
            src="https://img.alicdn.com/imgextra/i4/O1CN01DKQEQM1YWHFddCIJ1_!!6000000003066-2-tps-575-772.png" alt="">
          <img class="column"
            src="https://img.alicdn.com/imgextra/i4/O1CN01TXvZiW1udX4YBZPHe_!!6000000006060-2-tps-276-625.png" alt="">
        </div>
        <!-- 文案《事件2》 -->
        <div class="title6" data-class="title6" data-offset="150" data-hide="0">
          <div>
            <div>每当你问我在高德是做什么的</div>
          </div>
          <div>
            <div>我想说 我就像一个魔法师</div>
          </div>
          <div>
            <div>让你的出行和生活充满惊喜！</div>
          </div>
          <!-- <div>
            <div>打卡平时我工作、吃饭的地方</div>
          </div> -->
          <!-- <div style="margin-top: 0.1rem;">
            <div><span>共赴<p class="projectit">高德FamilyDay</p>的美好之约</span></div>
          </div>
          <div style="margin-top: 0.1rem;">
            <div>看着你眼角的笑意</div>
          </div>
          <div>
            <div>我真的很幸福</div>
          </div> -->
        </div>
      </div>
      <!-- 火车开出 -->
      <div data-pages-shifting="-40" class="pages pages1" data-pages="pages1">
        <!-- 火车 -->
        <div class="train" data-class="train" data-offset="380">
          <img src="https://img.alicdn.com/imgextra/i2/O1CN01tMwdo61kyvPmLuvVI_!!6000000004753-2-tps-2417-1374.png"
            alt="">
        </div>

        <!-- 文案 -->
        <div class="title1" data-class="title1" data-offset="50" data-hide="0">

          <div class="titszz1">
            <div>
              <div>每当你问我在高德是做什么的</div>
            </div>
            <div>
              <div>我想说 我就像一个魔法师</div>
            </div>
            <div>
              <div>让你的出行和生活充满惊喜！</div>
            </div>
          </div>

          <div class="titszz2">
            <div>
              <div><span><span>2023年2月</span></span></div>
            </div>
            <div>
              <div><span>高德发布一体化出行服务平台</span></div>
            </div>
            <div>
              <div>用一站式服务</div>
            </div>
            <div>
              <div>丰富更好的日常</div>
            </div>
          </div>
        </div>

      </div>
      <!-- 伸手递咖啡 -->
      <div data-pages-shifting="-40" class="pages pages2" data-pages="pages2"  v-if="false == true">
        <!-- 车中手《事件2》 -->
        <div class="reachOut" data-class="reachOut" data-offset="150">
          <div class="reachOut_img">

          </div>
        </div>
        <!-- 递咖啡手《事件2》 -->
        <div class="transfer">
          <img class="transfer_img"
            src="https://img.alicdn.com/imgextra/i3/O1CN01ugX01p1YkTX3bbYfp_!!6000000003097-2-tps-487-641.png" alt="">
        </div>


        <!-- 文案《事件2》 -->
        <div class="title2" data-class="title2" data-offset="150" data-hide="0">
          <!-- <div>
            <div>有时候</div>
          </div>
          <div>
            <div>我的工作就像一个<span><span>魔法师</span></span></div>
          </div>
          <div>
            <div>让日常出行的过程变得不一样</div>
          </div> -->
          <div>
            <div><span><span>2023年3月</span></span></div>
          </div>
          <div>
            <div><span>高德联合星巴克推出咖啡 沿街取 服务</span></div>
          </div>
          <div>
            <div>用一杯不用等的咖啡</div>
          </div>
          <div>
            <div>与生活和解</div>
          </div>
        </div>
      </div>
      <!-- 手机打出租车 -->
      <div data-pages-shifting="-40" class="pages pages3" data-pages="pages3" v-if="1 + 1 == 2">
        <!-- 小车《事件3》 -->
        <img class="takingTaxi" data-class="takingTaxi" data-offset="100"
          src="https://img.alicdn.com/imgextra/i3/O1CN01Bxfjcb1sxZA8YxzsO_!!6000000005833-2-tps-757-356.png" alt="">
        <!-- 手拿着手机出现《事件3》 -->
        <div class="handPhone_box">
          <img class="handPhone"
            src="https://img.alicdn.com/imgextra/i1/O1CN01IXm8XY1UcuWVlPbjt_!!6000000002539-2-tps-599-414.png" alt="">
          <!-- 大拇指《事件3》 -->
          <img class="thumb"
            src="https://img.alicdn.com/imgextra/i1/O1CN01VtVXUI1pqI1W76jDH_!!6000000005411-2-tps-164-204.png" alt="">
        </div>
        <!-- 手机提示通知《事件3》 -->
        <img class="mobileNotifications"
          src="https://img.alicdn.com/imgextra/i2/O1CN01poWagS1wpYhYFxeJM_!!6000000006357-2-tps-178-209.png" alt="">
        <!-- 文案《事件3》 -->
        <div class="title3" data-class="title3" data-offset="150" data-hide="0">
          <div>
            <div><span><span>2023年4月</span></span></div>
          </div>
          <div>
            <div><span>高德发布 好的出租2.0 </span></div>
          </div>
          <div>
            <div><span>全面升级巡网融合解决方案</span></div>
          </div>
          <div>
            <div>用更快的到达和舒心的服务</div>
          </div>
          <div>
            <div>为出行减负</div>
          </div>
        </div>
      </div>
      <!-- 路口盲区会车 -->
      <div data-pages-shifting="-40" class="pages pages5" data-pages="pages5" v-if="1 + 1 == 2">
        <div class="earlyWarning_box" data-class="earlyWarning_box" data-offset="300">
          <img class="earlyWarning_ico"
            src="https://img.alicdn.com/imgextra/i4/O1CN01oHkPVB291ibrbii7B_!!6000000008008-2-tps-450-488.png" alt="">
          <img class="earlyWarning"
            src="https://img.alicdn.com/imgextra/i3/O1CN01Q4jXvo1nBrq2TI4Ll_!!6000000005052-2-tps-258-285.png" alt="">
          <img class="warning"
            src="https://img.alicdn.com/imgextra/i2/O1CN01vHHjJZ1T0bmvjZ0q7_!!6000000002320-2-tps-357-146.png" alt="">
        </div>
        <!-- 文案《事件3》 -->
        <div class="title5" data-class="title5" data-offset="150" data-hide="0">
          <div>
            <div><span><span>2023年4月</span></span></div>
          </div>
          <div>
            <div><span>高德推出 盲区会车预警服务</span></div>
          </div>
          <div>
            <div>用预知风险</div>
          </div>
          <div>
            <div>为盲区驾驶保驾护航</div>
          </div>
        </div>
      </div>
      <!-- 去淄博吃烧烤 -->
      <div data-pages-shifting="-40" class="pages pages4" data-pages="pages4" v-if="1 + 1 == 2">
        <div class="grilledSkewers_box" data-class="grilledSkewers_box" data-offset="150">
          <img class="grilledSkewers1"
            src="https://img.alicdn.com/imgextra/i4/O1CN016HS5d41DSyTFPhNJf_!!6000000000216-2-tps-257-709.png" alt="">
          <img class="grilledSkewers2"
            src="https://img.alicdn.com/imgextra/i1/O1CN01QdqH2v1azhwKzJPI2_!!6000000003401-2-tps-912-447.png" alt="">
        </div>
        <!-- 文案《事件4》 -->
        <div class="title4" data-class="title4" data-offset="150" data-hide="0">
          <div>
            <div><span><span>2023年5月</span></span></div>
          </div>
          <div>
            <div><span>高德推出“用餐先订座 省心不排队”</span></div>
          </div>
          <div>
            <div><span>的美食到店服务</span></div>
          </div>
          <div>
            <div>用提前定座</div>
          </div>
          <div>
            <div>让美食更快与味蕾相遇</div>
          </div>
        </div>
      </div>
      <!-- 一张地图 -->
      <div data-pages-shifting="-40" class="pages pages7" data-pages="pages7" v-if="1 + 1 == 2">
        <div class="maps_box" data-class="maps_box" data-offset="300">
          <img class="maps"
            src="https://img.alicdn.com/imgextra/i2/O1CN01u4KR8G1TFirESzt2H_!!6000000002353-2-tps-1125-713.png" alt="">
          <img class="maps_coordinate"
            src="https://img.alicdn.com/imgextra/i1/O1CN01ugf00U1NSBwg1tOE2_!!6000000001568-2-tps-306-375.png" alt="">
          <img class="cloud_left"
            src="https://img.alicdn.com/imgextra/i1/O1CN01FEXeKk1ESnvx1IwtR_!!6000000000351-2-tps-719-559.png" alt="">
          <img class="cloud_right"
            src="https://img.alicdn.com/imgextra/i1/O1CN01IYjNG31Dy7Od2Xuor_!!6000000000284-2-tps-885-635.png" alt="">
        </div>
        <!-- 文案《事件3》 -->
        <div class="title7" data-class="title7" data-offset="150" data-hide="0">

          <div>
            <div><span><span>2023年6月</span></span></div>
          </div>
          <div>
            <div><span>高德发布HQ Live MAP</span></div>
          </div>

          <div>
            <div>用一张活地图</div>
          </div>
          <div>
            <div>和生态伙伴共同推动</div>
          </div>
          <div>
            <div>高级辅助驾驶技术发展</div>
          </div>

        </div>
      </div>
      <!-- 自助打车机器 -->
      <div data-pages-shifting="-40" class="pages pages9" data-pages="pages9" v-if="1 + 1 == 2">
        <div class="serviceTaxi_box" data-class="serviceTaxi_box" data-offset="150">
          <img class="serviceTaxi"
            src="https://img.alicdn.com/imgextra/i1/O1CN012IsHW31ZyVHDWRKFP_!!6000000003263-2-tps-836-557.png" alt="">
        </div>
        <!-- 文案《事件3》 -->
        <div class="title9" data-class="title9" data-offset="150" data-hide="0">
          <div>
            <div><span><span>2023年9月</span></span></div>
          </div>
          <div>
            <div><span>高德发起“放心选 安心坐”服务行动 </span></div>
          </div>
          <div>
            <div>用更安全的服务</div>
          </div>
          <div>
            <div>守护每一次打车出行</div>
          </div>
        </div>
      </div>

      <!-- 地球 -->
      <div data-pages-shifting="-40" class="pages pages10" data-pages="pages10" v-if="1 + 1 == 2">
        <div class="theSky_box" data-class="theSky_box" data-offset="300">
          <img class="theSky1 theSky"
            src="https://img.alicdn.com/imgextra/i4/O1CN018GtIwW1YMevyXzDOB_!!6000000003045-2-tps-353-204.png" alt="">
          <img class="theSky2 theSky"
            src="https://img.alicdn.com/imgextra/i3/O1CN0101hHKe1epPUYoRobi_!!6000000003920-2-tps-298-172.png" alt="">
          <img class="theSky3 theSky"
            src="https://img.alicdn.com/imgextra/i1/O1CN01I8cBvj1NboGOqyeTm_!!6000000001589-2-tps-146-80.png" alt="">
          <!-- 地球 -->
          <div class="theEarth_box">
            <img class="theEarth"
              src="https://img.alicdn.com/imgextra/i3/O1CN01HfdZ1b22biQaYDCaO_!!6000000007139-2-tps-830-668.png" alt="">
            <img class="aircraft"
              src="https://img.alicdn.com/imgextra/i4/O1CN013L268Q1hOKvRzJ9Wt_!!6000000004267-2-tps-235-222.png" alt="">
            <img class="positioning1"
              src="https://img.alicdn.com/imgextra/i3/O1CN01yjPozc1Q69jnYFdob_!!6000000001926-2-tps-82-133.png" alt="">
            <img class="positioning2"
              src="https://img.alicdn.com/imgextra/i3/O1CN01yjPozc1Q69jnYFdob_!!6000000001926-2-tps-82-133.png" alt="">
            <img class="positioning3"
              src="https://img.alicdn.com/imgextra/i3/O1CN01yjPozc1Q69jnYFdob_!!6000000001926-2-tps-82-133.png" alt="">

          </div>


          <img class="theSky4 theSky"
            src="https://img.alicdn.com/imgextra/i2/O1CN01Y1Nbz11fRslIwmoKW_!!6000000004004-2-tps-299-188.png" alt="">
          <img class="theSky5 theSky"
            src="https://img.alicdn.com/imgextra/i1/O1CN018uVgJW1vLV5ymmc4O_!!6000000006156-2-tps-414-260.png" alt="">
        </div>
        <!-- 文案《事件10》 -->
        <div class="title10" data-class="title10" data-offset="150" data-hide="0">

          <div>
            <div><span><span>2023年9月</span></span></div>
          </div>
          <div>
            <div><span>高德世界地图正式上线</span></div>
          </div>

          <div>
            <div>用一张世界地图</div>
          </div>
          <div>
            <div>与远方温暖相逢</div>
          </div>
        </div>
      </div>
      <!-- 群人欢聚 -->
      <div data-pages-shifting="-40" class="pages pages8" data-pages="pages8" v-if="1 + 1 == 2">
        <div class="peoples_box" data-class="peoples_box" data-offset="300">
          <div class="people_left_box">
            <img class="people_left"
              src="https://img.alicdn.com/imgextra/i3/O1CN01tu6nQE1udzT9pUmZm_!!6000000006061-2-tps-479-559.png" alt="">
            <img class="brand_left"
              src="https://img.alicdn.com/imgextra/i1/O1CN01wxrYO625Zpe5ZyaIq_!!6000000007541-2-tps-314-283.png" alt="">
          </div>
          <div class="people_right_box">
            <img class="people_right"
              src="https://img.alicdn.com/imgextra/i2/O1CN012jenIF1DfLAJ0SLYU_!!6000000000243-2-tps-544-703.png" alt="">
            <img class="brand_right"
              src="https://img.alicdn.com/imgextra/i3/O1CN012S1cEc1uuTj3XGZOz_!!6000000006097-2-tps-286-197.png" alt="">
          </div>

          <ul class="fall">
            <li v-for="item in list" :key="item.id" :class="item.className"
              :style="`width: ${item.width}px; animation-delay:${item.an}s;top: ${item.width}px; left: ${item.left}px;`">
              <img :src="item.img" alt="">
            </li>
          </ul>

        </div>
        <!-- 文案《事件8》 -->
        <div class="title8" data-class="title8" data-offset="150" data-hide="0">
          <div style="margin-top: 0.1rem;">
            <div><span><span>2023年10月</span></span></div>
          </div>
          <div>
            <div><span>高德开启全民出行节</span></div>
          </div>
          <div>
            <div>用更好的出行体验</div>
          </div>
          <div>
            <div>为黄金周锦上添花</div>
          </div>
        </div>
      </div>
      <!-- 交通情况（路上有很多小车） -->
      <div data-pages-shifting="-40" class="pages pages12" data-pages="pages12"  v-if="1 + 1 == 2">
        <div class="traffic_box" data-class="traffic_box" data-offset="300">
          <img class="traffic1"
            src="https://img.alicdn.com/imgextra/i3/O1CN015c61PB1zBCebxxSMf_!!6000000006675-2-tps-752-452.png" alt="">
          <!-- <img class="traffic2" src="https://img.alicdn.com/imgextra/i3/O1CN015c61PB1zBCebxxSMf_!!6000000006675-2-tps-752-452.png" alt=""> -->
          <img class="vehicleY1"
            src="https://img.alicdn.com/imgextra/i2/O1CN01UyFi9P236rMLHrxJS_!!6000000007207-2-tps-64-46.png" alt="">
          <img class="vehicleY2"
            src="https://img.alicdn.com/imgextra/i4/O1CN01SrPs9N1EsS6v4en8j_!!6000000000407-2-tps-124-77.png" alt="">
          <img class="vehicleY3"
            src="https://img.alicdn.com/imgextra/i2/O1CN01y5WTDg29VULt99lTX_!!6000000008073-2-tps-164-105.png" alt="">
          <img class="vehicleX1"
            src="https://img.alicdn.com/imgextra/i3/O1CN01lwDJiu1xajV0LVorv_!!6000000006460-2-tps-99-58.png" alt="">
          <img class="vehicleX2"
            src="https://img.alicdn.com/imgextra/i4/O1CN01M1aPmL271bH8n4k3i_!!6000000007737-2-tps-140-90.png" alt="">
          <img class="vehicleX3"
            src="https://img.alicdn.com/imgextra/i1/O1CN01g1nbFc1mKHV0fCbCc_!!6000000004935-2-tps-85-54.png" alt="">

        </div>
        <!-- 文案《事件12》 -->
        <div class="title12" data-class="title12" data-offset="150" data-hide="0">
          <div>
            <div><span><span>2023年11月</span></span></div>
          </div>
          <div>
            <div><span>高德发布 云睿 产业大模型</span></div>
          </div>

          <div>
            <div>用时空信息</div>
          </div>
          <div>
            <div>帮助各行业用人工智能高效预测和决策</div>
          </div>

        </div>
      </div>
      <!-- 红绿灯 -->
      <div data-pages-shifting="-0" class="pages pages13" data-pages="pages13" v-if="1 + 1 == 2">
        <div class="trafficLight" data-class="trafficLight" data-offset="300">

          <!-- 导航辅助线 -->
          <div class="navigationLine"></div>

          <!-- 导航定位坐标 -->
          <div class="navigationLine_ico"></div>

          <!-- 左侧提示标志 -->
          <div class="Information"></div>

          <!-- 右提示标 -->
          <div class="redLight">
            <div class="lights"></div>
            <div class="lightsSum" id="lightsSum">5</div>
          </div>
          <!-- <img class="cloud1"
          src="https://img.alicdn.com/imgextra/i4/O1CN01WXwYAS1jZRldrQWaJ_!!6000000004562-2-tps-560-868.png" alt="">
        <img class="cloud2"
          src="https://img.alicdn.com/imgextra/i4/O1CN01qXop5n1kMS98sqDaN_!!6000000004669-2-tps-801-546.png" alt=""> -->

          <!-- <div class="light_b"> -->
          <!-- <img class="light-hong"
            src="https://img.alicdn.com/imgextra/i4/O1CN01LQlIJh1NsIWSdSahN_!!6000000001625-2-tps-126-143.png" alt="">
          <img class="light-huang"
            src="https://img.alicdn.com/imgextra/i4/O1CN01FQlm4i1K3y9R4Miw2_!!6000000001109-2-tps-122-143.png" alt="">
          <img class="light-lv"
            src="https://img.alicdn.com/imgextra/i3/O1CN01xjBJRt1s4bdgW9QRX_!!6000000005713-2-tps-124-139.png" alt="">
          <img class="slight-hong"
            src="https://img.alicdn.com/imgextra/i4/O1CN01TLUTT51p6woZrHDgE_!!6000000005312-2-tps-126-143.png" alt="">
          <img class="slight-huang"
            src="https://img.alicdn.com/imgextra/i2/O1CN01IRhXkx22jxYb4GipL_!!6000000007157-2-tps-122-144.png" alt="">
          <img class="slight-lv"
            src="https://img.alicdn.com/imgextra/i1/O1CN018tQgfM1xFfHORRGjM_!!6000000006414-2-tps-124-140.png" alt=""> -->
          <!-- </div> -->
        </div>
        <!-- 文案《事件3》 -->
        <div class="title13" data-class="title13" data-offset="150" data-hide="0">
          <div>
            <div><span><span>截止2023年12月</span></span></div>
          </div>
          <div>
            <div><span>高德智能红绿灯每日读秒超20亿次</span></div>
          </div>
          <div>
            <div>用确定的倒计时</div>
          </div>
          <div>
            <div>与不确定的等待握手言和</div>
          </div>
        </div>
      </div>
      <!-- 村庄定位 -->
      <div data-pages-shifting="-40" class="pages pages14" data-pages="pages14" v-if="1 + 1 == 2">
        <div class="village_box" data-class="village_box" data-offset="300">
          <div class="village_ico1">
            <div id="kilometers">2.1</div>
          </div>
          <div class="village_ico2"></div>
          <div class="village_ico3">40</div>


          <div class="route1">
            <img src="https://img.alicdn.com/imgextra/i3/O1CN01K8lWwF240jgLBWNNJ_!!6000000007329-2-tps-805-485.png"
              alt="">
          </div>
          <div class="route2">
            <img src="https://img.alicdn.com/imgextra/i1/O1CN01YV2LRq1ryC5yG3HnA_!!6000000005699-2-tps-299-173.png"
              alt="">
          </div>
          <img class="village_vehicle1"
            src="https://img.alicdn.com/imgextra/i1/O1CN01EtgKg81sBTa23IDSQ_!!6000000005728-2-tps-240-180.png" alt="">
          <img class="village_vehicle2"
            src="https://img.alicdn.com/imgextra/i1/O1CN01qMSqcm1cMtbQUaI0q_!!6000000003587-2-tps-240-180.png" alt="">
          <!-- <img class="village"
          src="https://img.alicdn.com/imgextra/i3/O1CN010hrCld1GG6B2D0Ca5_!!6000000000594-2-tps-893-1000.png" alt=""> -->
        </div>
        <!-- 文案《事件14》 -->
        <div class="title14" data-class="title14" data-offset="150" data-hide="0">

          <div>
            <div><span><span>2023年12月</span></span></div>
          </div>
          <div>
            <div><span>高德达成全球最大规模</span></div>
          </div>
          <div>
            <div><span>北斗车道级导航覆盖</span></div>
          </div>
          <div>
            <div>用99%的城乡覆盖</div>
          </div>
          <div>
            <div>抹平技术与日常的鸿沟</div>
          </div>

          <!-- <div style="margin-top: 0.1rem;">
            <div>未来无论你行驶在城市还是乡镇</div>
          </div>
          <div>
            <div>无论是在高速快速路还是普通路段</div>
          </div>
          <div>
            <div>都可以享受到沉浸式的新一代导航啦!</div>
          </div> -->

        </div>
      </div>
      <!-- 旅游 -->
      <div data-pages-shifting="-40" class="pages pages11" data-pages="pages11" v-if="1 + 1 == 2">
        <div class="satellite_box" data-class="satellite_box" data-offset="300">
          <img class="star1"
            src="https://img.alicdn.com/imgextra/i4/O1CN01GJkL6I24SDS0UCelN_!!6000000007389-2-tps-798-457.png" alt="">
          <img class="star2"
            src="https://img.alicdn.com/imgextra/i3/O1CN01cowzo01nzn10dukDg_!!6000000005161-2-tps-294-731.png" alt="">
        </div>
        <!-- 文案《事件3》 -->
        <div class="title11" data-class="title11" data-offset="150" data-hide="0">

          <div>
            <div><span><span>2023年</span></span></div>
          </div>
          <div>
            <div><span>高德在直签景区、文旅探索实现新突破</span></div>
          </div>
          <div>
            <div>用每一次与世界更好的相逢</div>
          </div>
          <div>
            <div>守护对山与海的热爱</div>
          </div>
        </div>
      </div>
      <!-- 坐轮椅 -->
      <div data-pages-shifting="-40" class="pages pages15" v-if="1 + 1 == 2">
        <div class="wheelchair_box" data-class="wheelchair_box" data-offset="500">
          <img class="bubble1"
            src="https://img.alicdn.com/imgextra/i4/O1CN01uljZVm1y0Ng93J39n_!!6000000006516-2-tps-517-713.png" alt="">
          <img class="bubble2"
            src="https://img.alicdn.com/imgextra/i4/O1CN01o7IdaX1r0CCy3CUd1_!!6000000005568-2-tps-388-388.png" alt="">
          <img class="bubble3"
            src="https://img.alicdn.com/imgextra/i4/O1CN01WqepRj25lHY1ivbTg_!!6000000007566-2-tps-274-274.png" alt="">
          <div class="wheelchair">
            <img class="img1"
              src="https://img.alicdn.com/imgextra/i1/O1CN01hKuz4b1xty8DSr3Kg_!!6000000006502-2-tps-511-739.png" alt="">
            <div class="screen">
              <img class="dinate"
                src="https://img.alicdn.com/imgextra/i3/O1CN014JtPXn1n0PwHT2L7O_!!6000000005027-2-tps-44-55.png" alt="">
              <img class="dinates"
                src="https://img.alicdn.com/imgextra/i3/O1CN01khkIBo1h8lSiC4os0_!!6000000004233-2-tps-34-34.png" alt="">
            </div>
            <div class="screen2" @click="jumpingExcessively(true)">
              <img class="screen2_ico1"
                src="https://img.alicdn.com/imgextra/i4/O1CN017w7ZsL1tOaXBruApI_!!6000000005892-2-tps-93-97.png" alt="">
              <img class="screen2_ico2"
                src="https://img.alicdn.com/imgextra/i2/O1CN01BijLyw1g6e0p6nJtV_!!6000000004093-2-tps-27-26.png" alt="">
              <div class="screen2_tit">为高德点赞</div>
              <img class="handClickws"
                src="https://img.alicdn.com/imgextra/i3/O1CN012JuUpg1otfKKTTgMw_!!6000000005283-2-tps-214-217.png" alt="">
            </div>

          </div>


          <div class="cardiacLandmark_box1">
            <img class="cardiacLandmark"
              src="https://img.alicdn.com/imgextra/i4/O1CN01XrDsfq1umEbDpipd2_!!6000000006079-2-tps-102-130.png" alt="">
            <img class="cardiacLandmarky"
              src="https://img.alicdn.com/imgextra/i3/O1CN01het6bG20pnNC3k3oC_!!6000000006899-2-tps-94-24.png" alt="">
          </div>
          <div class="cardiacLandmark_box2">
            <img class="cardiacLandmark"
              src="https://img.alicdn.com/imgextra/i4/O1CN01XrDsfq1umEbDpipd2_!!6000000006079-2-tps-102-130.png" alt="">
            <img class="cardiacLandmarky"
              src="https://img.alicdn.com/imgextra/i3/O1CN01het6bG20pnNC3k3oC_!!6000000006899-2-tps-94-24.png" alt="">
          </div>
          <div class="cardiacLandmark_box3">
            <img class="cardiacLandmark"
              src="https://img.alicdn.com/imgextra/i4/O1CN01XrDsfq1umEbDpipd2_!!6000000006079-2-tps-102-130.png" alt="">
            <img class="cardiacLandmarky"
              src="https://img.alicdn.com/imgextra/i3/O1CN01het6bG20pnNC3k3oC_!!6000000006899-2-tps-94-24.png" alt="">
          </div>
        </div>
        <!-- 文案《事件3》 -->
        <div class="title15" data-class="title15" data-offset="150" data-hide="0">
          <div>
            <div><span>你知道吗 科技其实是有温度的</span></div>
          </div>
          <div>
            <div><span>过去一年 我们</span></div>
          </div>
          <div style="margin-top: 0.1rem;">
            <div>•为患病的小宝贝一路护航 开启绿色生命通道</div>
          </div>
          <div style="margin-top: 0.1rem;">
            <div>•升级“积水地图”为遭遇台风的用户提供安全保障</div>
          </div>
          <div style="margin-top: 0.1rem;">
            <div>•上线30座城市的轮椅导航 让更多轮椅人士“椅起去远方”</div>
          </div>
          <!-- <div style="margin-top: 0.1rem;">
            <div>升级<span>“积水地图”</span>为遭遇台风的用户提供安全保障</div>
          </div>
          <div style="margin-top: 0.1rem;">
            <div>上线30座城市的轮椅导航 让更多轮椅人士“椅起去远方”</div>
          </div> -->
          <!-- <div>
            <div>让更多轮椅人士</div>
          </div>
          <div>
            <div>“椅起去远方”</div>
          </div> -->
          <div>
            <div style="letter-spacing: -0.1rem;">···<span style='opacity: 0;'>.</span> ···</div>
          </div>
          <div>
            <div><span>用一点一滴的努力 让世界更美好</span></div>
          </div>

        </div>
      </div>
      <!-- ip小人 -->
      <div class="logoIp" v-show="true">
        <div class="logoIp_img"></div>
      </div>
    </div>
    <!-- 进度条 -->
    <div class="progres_box">
      <div class="progresa">
        <div class="progres_x">
          <img class="progres_ico"
            src="https://img.alicdn.com/imgextra/i1/O1CN01rhgkwq1use8xCmcKp_!!6000000006093-2-tps-65-65.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cover from '../components/Cover.vue'
import Loading from '../components/Loading.vue'
import Excess from '../components/Excess.vue'
import WallPage from '../components/WallPage.vue'
import TrailerPage from '../components/TrailerPage.vue'
import { scrollingAnimation } from './scrollingAnimation.js';
export default {
  components: {
    Cover,
    Loading,
    Excess,
    WallPage,
    TrailerPage
  },
  data() {
    return {
      loading:true,//加载页面
      covers: true,//首页封面


      home: true,//事件页面
      excessShow: false,//过度页
      wallPage: false,//照片墙页面
      trailerPage: false,//结束页面

      play: false,//视频是否是在播放中
      video_m: false,//播放蒙层
      // time: null,//
      mp4Url: 'https://mapdownload.autonavi.com/voiceip/new_year/video_common.mp4',
      blessinga: true,//是否隐藏视频
      topYs: 0,

      list: [],
      imgs: [
        'https://img.alicdn.com/imgextra/i2/O1CN01JHdlGr1cdNrO9Dekl_!!6000000003623-2-tps-55-44.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01Z4MRru25UKtTpmE4U_!!6000000007529-2-tps-50-36.png',
        'https://img.alicdn.com/imgextra/i3/O1CN01yHwyBm1XAvAQtrCja_!!6000000002884-2-tps-34-34.png',
        'https://img.alicdn.com/imgextra/i2/O1CN01BlBRuB1lawHzB1a14_!!6000000004836-2-tps-51-28.png',
      ],
      className: [
        'down1',
        'down2',
        'down3',
        'down4',
      ],
      mp4_mp3_true: false,//是否是因为视频播放才暂停的音乐播放

      jumping: false,

      stoptime: null,//倒计时
    };
  },
  watch: {

  },
  created() {
    // console.log()
    // setInterval(() => {
    for (var s = 0; s < 10; s++) {
      for (let i = 0; i < this.imgs.length; i++) {
        let width = Math.floor(Math.random() * 30) + 10
        let top = Math.floor(Math.random() * 50) + 20
        let left = Math.floor(Math.random() * (document.body.clientWidth - width))
        let className = this.className[Math.floor(Math.random() * this.className.length)]
        this.list.push({
          id: this.count,
          img: this.imgs[Math.floor(Math.random() * this.imgs.length)],
          width: width,
          left: left,
          top: -top,
          an: Math.random() + 1,
          className: className
        })
        // setTimeout(() => {
        // this.list.shift()
        // }, 3000)
        // this.count += 1
      }
    }
    // }, 200);
  },
  mounted() {
    // console.log(this.$myGlobalValue)
    if (this.$myGlobalValue) {
      document.getElementById('app').style.width = '100vw'
      document.documentElement.style.overflow = 'auto'
      document.body.style.overflow = 'auto'
      document.documentElement.style.height = '100%'
      document.body.style.height = '100%'
      document.documentElement.style.position = 'relative'
      document.body.style.position = 'relative'
      window.addEventListener('load', () => {
        setTimeout(() => {
          document.body.scrollTo(0, 0);
          setTimeout(() => {
            document.body.style.overflow = 'hidden'
            scrollingAnimation('downward', this.$myGlobalValue)
          }, 10)
        }, 10)
      });
    } else {
      // 页面滚动到最底部
      window.addEventListener('load', () => {
        setTimeout(() => {
          window.scrollTo(0, 0);
          setTimeout(() => {
            document.body.style.overflow = 'hidden'
            scrollingAnimation('downward', this.$myGlobalValue)
          }, 10)
        }, 10)

      });
    }




    // setTimeout(()=>{
    //   let logoIp_img=document.getElementsByClassName('logoIp_img')[0]
    //   let sum=0
    //   let time=null
    //   time=setInterval(()=>{
    //     sum++
    //     logoIp_img.style.backgroundPositionY=`${-7.06+sum*3.54}rem`
    //     console.log(logoIp_img,`${-7.06+sum*3.53}rem;`)
    //     if(sum==2){
    //       clearInterval(time)
    //     }
    //   },16)
    // },1000)

    // console.log(document.getElementsByClassName('logoIp')[0].getBoundingClientRect())

  },
  methods: {
    onLoadedData() {
      // console.log("视频已经加载完成");
      // alert('2')
      // 这里可以进行其他操作或调用相关函数
    },
    // 照片页面结束
    wallPagFun() {
      // console.log('滑动到最右侧1')
      setTimeout(()=>{
        this.$refs.trailerPagRef.trailerPagAnimation()
      },1000)
      setTimeout(() => {
        this.wallPage = false
        


      }, 2000)
      setTimeout(() => {
        this.$root.audioPlay('pause')
        window.createjs.Sound.registerSound("https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/202401198a13cf3bf235c7f6391680.mp3", "bgSound")
        // window.createjs.Sound.registerSound("http://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/202401177dc1e151cd4ce20b616653.mp3", "bgSound")
      }, 1000)
      this.trailerPage = true
    },
    // 过度页结束
    excessFun() {
      this.wallPage = true
      console.log('结束过度')
      setTimeout(() => {
        this.$refs.wallPageRef.wallPageAnimation()
      }, 1500)
      setTimeout(() => {
        this.excessShow = false


      }, 2000)
    },
    blessingClick() {
      if (this.blessinga) {
        this.blessinga = false
        document.getElementsByClassName('blessing_box')[0].classList.add(`blessing_boxs`) //小人接信动画
        document.getElementsByClassName('video_box')[0].style.display = 'block'
        setTimeout(() => {
          document.getElementsByClassName('video_box')[0].classList.add(`video_boxs`) //小人接信动画
          setTimeout(() => {
            this.palys(true)
            document.body.style.overflowY = 'auto'
            document.body.style.width = '100vw'
            document.getElementsByClassName('guidedSliding_box')[0].style.display = 'block'
            setTimeout(() => {
              document.getElementsByClassName('guidedSliding_box')[0].classList.add(`guidedSliding_boxs`) //小人接信动画
              this.topYs = document.getElementsByClassName('guidedSliding_box')[0].getBoundingClientRect().y

              let dody = window
              if (this.$myGlobalValue) {
                dody = document.body
              }
              let $this = this
              dody.addEventListener("scroll", function () {
                if ($this.topYs-50 > document.getElementsByClassName('guidedSliding_box')[0].getBoundingClientRect().y) {
                  document.getElementsByClassName('guidedSliding_box')[0].style.opacity = '0'
                  // document.getElementsByClassName('guidedSliding_box')[0].style.display = 'none'

                }
                // 获取当前的可视窗口高度 (viewPortHeight 兼容所有浏览器写法)
                const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
                const offsetTop = document.getElementsByClassName('video_box')[0].getBoundingClientRect()

                if ((offsetTop.y+offsetTop.height)<0) {
                  $this.palys(false)
                }
                // console.log(document.documentElement.scrollTop,document.body.scrollTop,$this.$myGlobalValue)
                let ua = navigator.userAgent.toLowerCase(); //判断浏览器的类型
                let u = navigator.userAgent;
                let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      // if (ua.indexOf("micromessenger") > -1) {
                // console.log(viewPortHeight+document.documentElement.scrollTop,document.getElementById('home').scrollHeight)
                if ($this.$myGlobalValue||(isAndroid&&(ua.indexOf("micromessenger") > -1))) {
                  // if ((document.body.scrollTop+viewPortHeight) == document.getElementById('home').scrollHeight) {
                    if ((document.body.scrollTop+viewPortHeight)+5 >=document.getElementById('home').scrollHeight&&(document.body.scrollTop+viewPortHeight)-5 <=document.getElementById('home').scrollHeight) {
                    $this.jumpingExcessively(false)
                  }
                } else {
                  if ((document.documentElement.scrollTop+viewPortHeight)+5 >=document.getElementById('home').scrollHeight&&(document.documentElement.scrollTop+viewPortHeight)-5 <=document.getElementById('home').scrollHeight) {
                    $this.jumpingExcessively(false)
                  }
                }

              })
            }, 100)

          }, 900)
        }, 100)



      }

    },
    // 事件页面结束，准备进入过渡页
    excess_c() {
      // return
      document.body.style.overflow = 'hidden'
      let dody = window
      if (this.$myGlobalValue) {
        dody = document.body
      }
      dody.scrollTo({
        top: document.getElementById('home').scrollHeight,
        behavior: 'smooth',
        // transitionDuration: '3s' // 设置滚动动画的持续时间为2秒  
      });

      document.getElementsByClassName('logoIp')[0].classList.add(`logoIps`) //高小德
      document.getElementsByClassName('home')[0].classList.add(`homes`) //高小德
      // this.excessShow = true
      this.wallPage = true
      
      document.getElementsByClassName('progres_box')[0].style.transition = 'all 2s'
      document.getElementsByClassName('progres_box')[0].style.opacity = '0'
      setTimeout(() => {
        this.home = false
        // this.$root.audioPlay('pause')
        // window.createjs.Sound.registerSound("https://res.xiaoxxx.com/urlGather/20240110b852f17d241ec0c3273907.mp3", "bgSound")
      }, 2000)
      setTimeout(() => {
        this.$refs.wallPageRef.wallPageAnimation()
      }, 1800)

    },
    // 滑动到最顶部
    jumpingExcessively(a) {
      // console.log(a,789789789123)
      // return
      if (a == true) {
        this.jumping = true
        clearTimeout(this.stoptime)
        this.stoptime = null
        // console.log('跳转')
        this.excess_c()
      } else if (!a && !this.jumping) {
        this.jumping = true
        this.stoptime = setTimeout(() => {
          // console.log('跳转')
          this.excess_c()

        }, 2000)
      }


    },
    loadingFun() {
      this.loading = false
      this.$refs.childRef.startAnimation()
    },
    // 首页动画执行完调用此函数
    coverFun(a) {
      // console.log(a)
      this.mp4Url = a

      let dody = window
      if (this.$myGlobalValue) {
        dody = document.body
      }
      // 等首页动画快执行完的时候再次让页面滚动到最底部
      setTimeout(() => {
        //   const homeElement = document.getElementById('home');
        //   if (homeElement) {
        dody.scrollTo(0, 0);
        //     setTimeout(() => {
        //       homeElement.scrollIntoView(false)
        //     }, 10)
        //   }
        this.$root.audioPlay('pause')
        window.createjs.Sound.registerSound("https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/202401196a8a2533a75ec818726803.mp3", "bgSound")

      }, 800)
      setTimeout(() => {
        this.covers = false
        // console.log(1);
        setTimeout(() => {
          this.blessingClick()
        }, 2000)
      }, 1800)
    },
    palys(a) {
      let video = document.getElementById("myVideo");
      if (a) {
        
          video.play();
        this.mp4_mp3_true = true
        this.$root.audioPlay('pause')

        this.play = true
        this.video_m = true
        let $this = this
        video.addEventListener('ended', function () {
          // 在这里编写您希望在视频播放完后执行的代码  
          console.log('视频播放完毕');
          video.pause();
          if ($this.mp4_mp3_true) {
            $this.mp4_mp3_true = false
            $this.$root.audioPlay('play')
          }
          clearTimeout($this.time);
          $this.play = false
          $this.video_m = false
        });
      } else {
        video.pause();
        if (this.mp4_mp3_true) {
          this.mp4_mp3_true = false
          this.$root.audioPlay('play')
        }
        clearTimeout(this.time);
        this.play = false
        this.video_m = false
        //  清除播放结束后的事件监听器  
        video.removeEventListener('ended', function () {
          console.log('清除视频播放完监间');
        });
      }
    },
    videoM() {
      this.video_m = false
      this.time = setTimeout(() => {
        this.video_m = true
      }, 1500)
    },

    coverClick() {
      document.getElementsByClassName('cover')[0].style.animation = `fm 1s linear forwards`
      setTimeout(() => {
        document.getElementsByClassName('cover')[0].style.display = 'none'
        document.getElementsByClassName('logoIp')[0].style.animation = `ip 2s linear infinite`
      }, 1000)

    },
    // --------------------------------------------------------------------------------
  },

}

</script>

