import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'vant/lib/index.css';
const app = createApp(App)  
// 定义全局变量 

let ua = navigator.userAgent.toLowerCase(); //判断浏览器的类型
let u = navigator.userAgent;
// console.log(u,'乐乐乐',ua)
// alert(ua)

let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
app.config.globalProperties.$myGlobalValue =isAndroid&&((ua.indexOf("amap") > -1))
// console.log(!(isAndroid&&((ua.indexOf("amap") > -1)||(ua.indexOf("micromessenger") > -1))))
// alert(isAndroid&&((ua.indexOf("amap") > -1)||(ua.indexOf("micromessenger") > -1)))
app.use(store).use(router)  
app.mount('#app')
// createApp(App).use(store).use(router).mount('#app')
