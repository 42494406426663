<template>
  <div>


    <div class="bg loading" v-show="lodingShow">
      <div class="bgz">
        <img src="https://img.alicdn.com/imgextra/i4/O1CN01ec642S1NsIWO4aW20_!!6000000001625-2-tps-432-121.png" alt=""
          class="logo">
        <!-- <div class="title">出游故事 正在髪生...</div> -->
        <img class="title"
          src="https://img.alicdn.com/imgextra/i2/O1CN01rWBs1U1yP74FBs0de_!!6000000006570-2-tps-525-498.png" alt="">

        <div class="progk">
          <div class="prog">
            <div class="progres" :style="'transform: translate' + `(${ssa + pre}%)`"></div>
          </div>
        </div>
        <div class="perc">高德家书 即将抵达...</div>

        <div class="percentage">{{ ssa + pre }}%</div>
        <div class="promptCopy">
          &nbsp; 请关闭手机【<span>静音模式</span>】<br>沉浸式阅读高德家书
        </div>
      </div>
    </div>
    <div class="jzmusic" v-show="!lodingShow">
      <div class="loadingsa">
        <div class="loader-03">
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
// import { Notify } from 'vant';
import store from '@/store';
export default {
  name: 'Loading',
  data() {
    return {
      // lodingShow:false,
      count: 0,//数量加载出来的数量
      imgl: 0,//加载图片的数量
      ssa: 0,//当前缓冲进度
      timer: null,//缓冲的定时器
      pre: 0,//当前实际的加载进度
    };
  },
  computed: {
    lodingShow() {

      if (store.state.showLast) {
        this.preload();
        this.buffer()
        // console.log(123,store.state.showLast)
      }
      return store.state.showLast
    },
  },
  mounted: function () {

  },
  watch: {
    ssa: function () {
      if (this.ssa >= 60) {
        clearInterval(this.timer);
        if (this.count === this.imgl) {
          // console.log("跳转")
          this.loadCompleted()
        }

      }
    },
    count: function (val) {
      // console.log(val)
      if (val === this.imgl) {
        // console.log("wwww",this.ssa,this.timer)
        if (this.ssa >= 60) {
          this.loadCompleted()
          // console.log("跳转")

        }

      }
    },
  },
  methods: {
    wangs() {
      console.log(123456789)
    },
    // 加载完成后被调用
    loadCompleted() {
      // return
      document.getElementsByClassName('loading')[0].classList.add(`loadings`) //页面隐藏动画
      document.getElementsByClassName('promptCopy')[0].style.display = 'none'
      // promptCopy
      setTimeout(() => {
        this.$emit('loadingFun', 'load')
      }, 1000)


    },
    buffer() {
      this.timer = setInterval(() => {
        this.ssa++
      }, 100)

    },
    preload: function () {
      let imgs = [
        'https://img.alicdn.com/imgextra/i4/O1CN01Y6TL7U1MNK76pwnnW_!!6000000001422-2-tps-618-561.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01Q1ssTv20EEtLFunSm_!!6000000006817-2-tps-618-562.png',
        'https://img.alicdn.com/imgextra/i3/O1CN01y5PcRY24wtyd82nCM_!!6000000007456-2-tps-618-562.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01uKdIME1s0U4QWWl6R_!!6000000005704-2-tps-618-562.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01MDFKaH1vx3ZMATdDa_!!6000000006238-2-tps-618-562.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01BFPirf1GfHxMStK8L_!!6000000000649-2-tps-397-1881.png',
        // 'https://img.alicdn.com/imgextra/i2/O1CN01SVo8hv25s9UERPhBm_!!6000000007581-0-tps-1125-4071.jpg',
        'https://img.alicdn.com/imgextra/i2/O1CN01MSSiQE28B33bMSWxE_!!6000000007893-2-tps-866-831.png',
        // 'https://img.alicdn.com/imgextra/i4/O1CN01AnBlCE1S1h6il3GP4_!!6000000002187-0-tps-1125-5463.jpg',

        // 'https://img.alicdn.com/imgextra/i1/O1CN01befTd31To4ZDWb4P2_!!6000000002428-0-tps-1125-2012.jpg',
        // 'https://img.alicdn.com/imgextra/i1/O1CN01lkFtUm1apdE3yvxm5_!!6000000003379-0-tps-1125-2001.jpg',
        'https://img.alicdn.com/imgextra/i3/O1CN01UVewHX1HcpRyDilWG_!!6000000000779-0-tps-1125-2001.jpg',
        'https://img.alicdn.com/imgextra/i2/O1CN01f0zKNh1Ii9ftB3koq_!!6000000000926-0-tps-1125-2001.jpg',
        // 'https://img.alicdn.com/imgextra/i4/O1CN01OilDxK1emf7So6bd4_!!6000000003914-0-tps-1125-2001.jpg',
        // 'https://img.alicdn.com/imgextra/i3/O1CN01rF3etY1Zvku8zJ7iq_!!6000000003257-0-tps-1125-2001.jpg',
        'https://img.alicdn.com/imgextra/i2/O1CN0177cQkZ1jTx1Bwk2vb_!!6000000004550-0-tps-1125-2001.jpg',
        // 'https://img.alicdn.com/imgextra/i2/O1CN01rjb1n01IDT8kcL18u_!!6000000000859-0-tps-1125-2005.jpg',

        // 'https://img.alicdn.com/imgextra/i4/O1CN01IwASG81ZhYcnbu22B_!!6000000003226-2-tps-200-200.png',




        'https://img.alicdn.com/imgextra/i2/O1CN01YcMB091fEbHV49h0N_!!6000000003975-0-tps-1125-2436.jpg',
        // 'https://img.alicdn.com/imgextra/i2/O1CN01SI89DU1iwW6gp8x1y_!!6000000004477-2-tps-213-80.png',
        'https://img.alicdn.com/imgextra/i3/O1CN011w6zA61QDwTVq7uy4_!!6000000001943-0-tps-1125-2001.jpg',
        'https://img.alicdn.com/imgextra/i2/O1CN01D0OpyO1Jr94D4KQWY_!!6000000001081-0-tps-1125-2002.jpg',
        'https://img.alicdn.com/imgextra/i1/O1CN01MBguIr1PqaHFjvJ8t_!!6000000001892-0-tps-1125-2001.jpg',
        'https://img.alicdn.com/imgextra/i1/O1CN01ioZcTv1KIcpdcwbJ4_!!6000000001141-0-tps-1125-2001.jpg',
        'https://img.alicdn.com/imgextra/i3/O1CN01LwSFaE1PKWYE8ozVJ_!!6000000001822-0-tps-1125-2001.jpg',
        'https://img.alicdn.com/imgextra/i4/O1CN01qotpVn1nOEXRsydtP_!!6000000005079-0-tps-1125-2001.jpg',
        'https://img.alicdn.com/imgextra/i2/O1CN01OGmaJW1GW82sPkeyd_!!6000000000629-0-tps-1125-2009.jpg',
        'https://img.alicdn.com/imgextra/i2/O1CN01xHiVTc1GeNAqINajA_!!6000000000647-0-tps-1125-2001.jpg',
        'https://img.alicdn.com/imgextra/i4/O1CN01T5zHAh1aP4Fq5Yqq7_!!6000000003321-0-tps-1125-2001.jpg',
        'https://img.alicdn.com/imgextra/i2/O1CN01uUhktv25DOFBrsoGi_!!6000000007492-0-tps-1125-2001.jpg',
'https://img.alicdn.com/imgextra/i3/O1CN01MKFthc1oLm1mFKHnf_!!6000000005209-0-tps-1125-2001.jpg',
'https://img.alicdn.com/imgextra/i2/O1CN019ZQEo31YI4yqOAQCb_!!6000000003035-0-tps-1125-2446.jpg',
'https://img.alicdn.com/imgextra/i2/O1CN01Q9bhiY1wVPHc3Lya9_!!6000000006313-0-tps-1125-2001.jpg',
'https://img.alicdn.com/imgextra/i4/O1CN01SVlA171kkj8pxcZvm_!!6000000004722-0-tps-1125-2001.jpg',
'https://img.alicdn.com/imgextra/i4/O1CN01n2dXGw1RYqA96sNH0_!!6000000002124-0-tps-1125-2002.jpg',




        // 'https://img.alicdn.com/imgextra/i2/O1CN01Sd41QL1KQrxS6V6pz_!!6000000001159-0-tps-1125-2001.jpg',
        // 'https://img.alicdn.com/imgextra/i2/O1CN01Vjo3qr1MSMTsmEIpc_!!6000000001433-0-tps-1125-2001.jpg',
        'https://img.alicdn.com/imgextra/i4/O1CN01106bBP1HugthLYPmY_!!6000000000818-2-tps-347-224.png',
        'https://img.alicdn.com/imgextra/i3/O1CN01XjNRL51hiUMByCK7e_!!6000000004311-2-tps-298-282.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01YV2LRq1ryC5yG3HnA_!!6000000005699-2-tps-299-173.png',
        'https://img.alicdn.com/imgextra/i2/O1CN01aDhh2Y24uc0AHJf7H_!!6000000007451-2-tps-284-971.png',
        'https://img.alicdn.com/imgextra/i3/O1CN01K8lWwF240jgLBWNNJ_!!6000000007329-2-tps-805-485.png',
        'https://img.alicdn.com/imgextra/i4/O1CN01LJbVfs1LfM6Gtfk2h_!!6000000001326-2-tps-111-110.png',
        'https://img.alicdn.com/imgextra/i4/O1CN01S3BqA41ym0s8IXahs_!!6000000006620-2-tps-111-110.png',
        'https://img.alicdn.com/imgextra/i4/O1CN0124uf7j1lq3MVCwMXE_!!6000000004869-2-tps-479-239.png',


        'https://img.alicdn.com/imgextra/i2/O1CN01uyyKeA1IqrCUrql3H_!!6000000000945-2-tps-937-195.png',
        'https://img.alicdn.com/imgextra/i3/O1CN01cr3xEY1hyycBfzMPg_!!6000000004347-2-tps-842-268.png',
        'https://img.alicdn.com/imgextra/i2/O1CN01amTrjW1z2xWwgTK2N_!!6000000006657-2-tps-213-80.png',
        'https://img.alicdn.com/imgextra/i3/O1CN013szXy527NaHzzm40P_!!6000000007785-2-tps-169-169.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01NuFhLW1N5I9BX7J95_!!6000000001518-2-tps-87-163.png',

        'https://img.alicdn.com/imgextra/i4/O1CN01yznAR725UKtgFxaLN_!!6000000007529-2-tps-998-1079.png',
        'https://img.alicdn.com/imgextra/i4/O1CN01oPx6zD1E2ExwrKd1w_!!6000000000293-2-tps-811-1129.png',
        'https://img.alicdn.com/imgextra/i3/O1CN01GExojp1pVDoGLN8zF_!!6000000005365-2-tps-937-349.png',
        'https://img.alicdn.com/imgextra/i1/O1CN018fSjWb1PyN0Q1SWcQ_!!6000000001909-2-tps-349-354.png',
        'https://img.alicdn.com/imgextra/i4/O1CN01odn9rk1q9z3leXkmn_!!6000000005454-2-tps-775-197.png',
        'https://img.alicdn.com/imgextra/i3/O1CN01vAZKQf1x10bkhRFCc_!!6000000006382-2-tps-937-384.png',
        'https://img.alicdn.com/imgextra/i2/O1CN01kKMOCH1NkVnAD0ABF_!!6000000001608-2-tps-711-133.png',
        'https://img.alicdn.com/imgextra/i3/O1CN01Q4Eul727oberpYAUV_!!6000000007844-2-tps-188-105.png',

        'https://img.alicdn.com/imgextra/i3/O1CN01SVwGRl1S5ogJPZsbr_!!6000000002196-2-tps-1125-224.png',

        'https://img.alicdn.com/imgextra/i4/O1CN01n6huVo1nAUekDE2Rs_!!6000000005049-2-tps-1275-6719.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01zCToeW29K2SDh2De6_!!6000000008048-2-tps-375-661.png',
        'https://img.alicdn.com/imgextra/i4/O1CN013ha6Qa29E5Ix9sp6C_!!6000000008035-2-tps-148-184.png',
        'https://img.alicdn.com/imgextra/i3/O1CN01LKVuQy1hM2ws0qaDX_!!6000000004262-2-tps-344-615.png',
        'https://img.alicdn.com/imgextra/i3/O1CN01v5FRxR1Z4cxaPw5tz_!!6000000003141-2-tps-344-615.png',
      'https://img.alicdn.com/imgextra/i1/O1CN019VhAzf1H2Bls0yubt_!!6000000000699-2-tps-1125-733.png',

        // 'https://img.alicdn.com/imgextra/i1/O1CN01k3EyDK1sW5P8vl42A_!!6000000005773-0-tps-1125-2436.jpg',
        'https://img.alicdn.com/imgextra/i3/O1CN01718d7h29RpBY3iDtC_!!6000000008065-0-tps-1125-2436.jpg',
        'https://img.alicdn.com/imgextra/i4/O1CN01QhPlNW1lwvIgILGUY_!!6000000004884-2-tps-419-678.png',
        'https://img.alicdn.com/imgextra/i3/O1CN01S4Qspd1Ty9HJPalFe_!!6000000002450-2-tps-343-812.png',
        'https://img.alicdn.com/imgextra/i3/O1CN01IuUILB1qZdEq1WwXY_!!6000000005510-2-tps-456-456.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01AMnEm81U2jF0Vv9I7_!!6000000002460-2-tps-401-401.png',
        'https://img.alicdn.com/imgextra/i4/O1CN01YEkt6V1xVEkU1Tqjk_!!6000000006448-2-tps-412-402.png',
        'https://img.alicdn.com/imgextra/i1/O1CN013XBsdm1UNL3roTLca_!!6000000002505-2-tps-168-169.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01m63YQg1ik9PQKIJDa_!!6000000004450-2-tps-169-168.png',
        'https://img.alicdn.com/imgextra/i2/O1CN014bVrpD1SzEbKAJQbS_!!6000000002317-2-tps-101-101.png',
        'https://img.alicdn.com/imgextra/i3/O1CN016MXudH1KXHVAeS7HW_!!6000000001173-2-tps-56-56.png',

        // 过渡页
        'https://img.alicdn.com/imgextra/i1/O1CN01cp6SLS20VdwkX7bZL_!!6000000006855-0-tps-1125-2436.jpg',
        'https://img.alicdn.com/imgextra/i3/O1CN01RAZlB21NY962FWM4i_!!6000000001581-2-tps-233-249.png',

        // 滚动条
        'https://img.alicdn.com/imgextra/i1/O1CN01rhgkwq1use8xCmcKp_!!6000000006093-2-tps-65-65.png',

        // 轮椅
        'https://img.alicdn.com/imgextra/i4/O1CN01uljZVm1y0Ng93J39n_!!6000000006516-2-tps-517-713.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01hKuz4b1xty8DSr3Kg_!!6000000006502-2-tps-511-739.png',
        'https://img.alicdn.com/imgextra/i4/O1CN01o7IdaX1r0CCy3CUd1_!!6000000005568-2-tps-388-388.png',
        'https://img.alicdn.com/imgextra/i4/O1CN01WqepRj25lHY1ivbTg_!!6000000007566-2-tps-274-274.png',
        // 'https://img.alicdn.com/imgextra/i1/O1CN01TXt4K91c529imVefR_!!6000000003548-2-tps-252-274.png',
        'https://img.alicdn.com/imgextra/i4/O1CN01XrDsfq1umEbDpipd2_!!6000000006079-2-tps-102-130.png',
        'https://img.alicdn.com/imgextra/i3/O1CN01het6bG20pnNC3k3oC_!!6000000006899-2-tps-94-24.png',


        'https://img.alicdn.com/imgextra/i3/O1CN01AByFSz1dNBT595FlK_!!6000000003723-2-tps-252-274.png',
        'https://img.alicdn.com/imgextra/i2/O1CN016PG8s01mTRQCWRIXL_!!6000000004955-2-tps-277-287.png',
        'https://img.alicdn.com/imgextra/i3/O1CN01khkIBo1h8lSiC4os0_!!6000000004233-2-tps-34-34.png',
        'https://img.alicdn.com/imgextra/i4/O1CN017w7ZsL1tOaXBruApI_!!6000000005892-2-tps-93-97.png',
        'https://img.alicdn.com/imgextra/i3/O1CN014JtPXn1n0PwHT2L7O_!!6000000005027-2-tps-44-55.png',
        'https://img.alicdn.com/imgextra/i2/O1CN01BijLyw1g6e0p6nJtV_!!6000000004093-2-tps-27-26.png',
        // 村庄定位
        'https://img.alicdn.com/imgextra/i1/O1CN01qMSqcm1cMtbQUaI0q_!!6000000003587-2-tps-240-180.png',
        // 'https://img.alicdn.com/imgextra/i3/O1CN010hrCld1GG6B2D0Ca5_!!6000000000594-2-tps-893-1000.png',
        'https://img.alicdn.com/imgextra/i2/O1CN01OPN2dq1eakorxdfaC_!!6000000003888-2-tps-235-235.png',
        'https://img.alicdn.com/imgextra/i4/O1CN01eqgjRt1OBX9qLx6Aj_!!6000000001667-2-tps-110-110.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01EtgKg81sBTa23IDSQ_!!6000000005728-2-tps-240-180.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01mSAdu21IrlzNYKkSq_!!6000000000947-2-tps-118-118.png',
        // 红绿灯
        // 'https://img.alicdn.com/imgextra/i4/O1CN01qXop5n1kMS98sqDaN_!!6000000004669-2-tps-801-546.png',
        // 'https://img.alicdn.com/imgextra/i4/O1CN01WXwYAS1jZRldrQWaJ_!!6000000004562-2-tps-560-868.png',
        // 'https://img.alicdn.com/imgextra/i3/O1CN01xjBJRt1s4bdgW9QRX_!!6000000005713-2-tps-124-139.png',
        // 'https://img.alicdn.com/imgextra/i4/O1CN01LQlIJh1NsIWSdSahN_!!6000000001625-2-tps-126-143.png',
        // 'https://img.alicdn.com/imgextra/i4/O1CN01FQlm4i1K3y9R4Miw2_!!6000000001109-2-tps-122-143.png',
        // 'https://img.alicdn.com/imgextra/i2/O1CN01wuvH051VT7g1K3vYe_!!6000000002653-2-tps-453-1034.png',
        // 'https://img.alicdn.com/imgextra/i1/O1CN018tQgfM1xFfHORRGjM_!!6000000006414-2-tps-124-140.png',
        // 'https://img.alicdn.com/imgextra/i4/O1CN01TLUTT51p6woZrHDgE_!!6000000005312-2-tps-126-143.png',
        // 'https://img.alicdn.com/imgextra/i2/O1CN01IRhXkx22jxYb4GipL_!!6000000007157-2-tps-122-144.png',
        // 指挥交通
        'https://img.alicdn.com/imgextra/i3/O1CN015c61PB1zBCebxxSMf_!!6000000006675-2-tps-752-452.png',
        'https://img.alicdn.com/imgextra/i4/O1CN01M1aPmL271bH8n4k3i_!!6000000007737-2-tps-140-90.png',
        'https://img.alicdn.com/imgextra/i2/O1CN01UyFi9P236rMLHrxJS_!!6000000007207-2-tps-64-46.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01g1nbFc1mKHV0fCbCc_!!6000000004935-2-tps-85-54.png',
        'https://img.alicdn.com/imgextra/i2/O1CN01y5WTDg29VULt99lTX_!!6000000008073-2-tps-164-105.png',
        'https://img.alicdn.com/imgextra/i3/O1CN01lwDJiu1xajV0LVorv_!!6000000006460-2-tps-99-58.png',
        'https://img.alicdn.com/imgextra/i4/O1CN01SrPs9N1EsS6v4en8j_!!6000000000407-2-tps-124-77.png',

        // 卫星
        // 'https://img.alicdn.com/imgextra/i4/O1CN01zBozLv1PuhppSnwq3_!!6000000001901-2-tps-930-923.png',
        // 'https://img.alicdn.com/imgextra/i3/O1CN01rPHCX41SPVhknJt8Q_!!6000000002239-2-tps-1048-478.png',
        // 'https://img.alicdn.com/imgextra/i1/O1CN01j5qzYS1o5k9sBXYR6_!!6000000005174-2-tps-315-315.png',
        // 'https://img.alicdn.com/imgextra/i4/O1CN01kbg7V51mgiuEUuRho_!!6000000004984-2-tps-236-157.png',

        'https://img.alicdn.com/imgextra/i2/O1CN01ALjlwm1Vkz7fPJWsl_!!6000000002692-1-tps-1124-732.gif',
        'https://img.alicdn.com/imgextra/i4/O1CN01UBH5B91KqyX1amyep_!!6000000001216-2-tps-1124-732.png',
        // 事件1到事件5背景
        // 'https://img.alicdn.com/imgextra/i2/O1CN01H3HlSs24UxpG1mVxQ_!!6000000007395-0-tps-1125-2005.jpg',
        // 'https://img.alicdn.com/imgextra/i1/O1CN01meFw1k1sywM4fvzIF_!!6000000005836-0-tps-1125-2001.jpg',
        // 'https://img.alicdn.com/imgextra/i3/O1CN01jAViU81seKX3tla1C_!!6000000005791-0-tps-1125-2001.jpg',
        // 'https://img.alicdn.com/imgextra/i2/O1CN01Vjo3qr1MSMTsmEIpc_!!6000000001433-0-tps-1125-2001.jpg',
        // 'https://img.alicdn.com/imgextra/i1/O1CN01I9Lc491CRJPv5H9Wq_!!6000000000077-0-tps-1125-2443.jpg',

        // 地球
        'https://img.alicdn.com/imgextra/i4/O1CN018GtIwW1YMevyXzDOB_!!6000000003045-2-tps-353-204.png',
        'https://img.alicdn.com/imgextra/i3/O1CN0101hHKe1epPUYoRobi_!!6000000003920-2-tps-298-172.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01I8cBvj1NboGOqyeTm_!!6000000001589-2-tps-146-80.png',
        'https://img.alicdn.com/imgextra/i2/O1CN01Y1Nbz11fRslIwmoKW_!!6000000004004-2-tps-299-188.png',
        'https://img.alicdn.com/imgextra/i1/O1CN018uVgJW1vLV5ymmc4O_!!6000000006156-2-tps-414-260.png',
        'https://img.alicdn.com/imgextra/i3/O1CN01HfdZ1b22biQaYDCaO_!!6000000007139-2-tps-830-668.png',
        'https://img.alicdn.com/imgextra/i4/O1CN013L268Q1hOKvRzJ9Wt_!!6000000004267-2-tps-235-222.png',
        'https://img.alicdn.com/imgextra/i3/O1CN01yjPozc1Q69jnYFdob_!!6000000001926-2-tps-82-133.png',

        // 自助打车
        'https://img.alicdn.com/imgextra/i1/O1CN012IsHW31ZyVHDWRKFP_!!6000000003263-2-tps-836-557.png',
        // 'https://img.alicdn.com/imgextra/i1/O1CN01IkQKvT1llvnZll9oX_!!6000000004860-0-tps-1125-2001.jpg',

        // 群人欢呼丝带
        'https://img.alicdn.com/imgextra/i2/O1CN01JHdlGr1cdNrO9Dekl_!!6000000003623-2-tps-55-44.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01Z4MRru25UKtTpmE4U_!!6000000007529-2-tps-50-36.png',
        'https://img.alicdn.com/imgextra/i3/O1CN01yHwyBm1XAvAQtrCja_!!6000000002884-2-tps-34-34.png',
        'https://img.alicdn.com/imgextra/i2/O1CN01BlBRuB1lawHzB1a14_!!6000000004836-2-tps-51-28.png',

        // 群人欢呼
        'https://img.alicdn.com/imgextra/i3/O1CN01tu6nQE1udzT9pUmZm_!!6000000006061-2-tps-479-559.png',
        'https://img.alicdn.com/imgextra/i2/O1CN012jenIF1DfLAJ0SLYU_!!6000000000243-2-tps-544-703.png',
        'https://img.alicdn.com/imgextra/i3/O1CN012S1cEc1uuTj3XGZOz_!!6000000006097-2-tps-286-197.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01wxrYO625Zpe5ZyaIq_!!6000000007541-2-tps-314-283.png',

        // 地图
        'https://img.alicdn.com/imgextra/i2/O1CN01u4KR8G1TFirESzt2H_!!6000000002353-2-tps-1125-713.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01IYjNG31Dy7Od2Xuor_!!6000000000284-2-tps-885-635.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01FEXeKk1ESnvx1IwtR_!!6000000000351-2-tps-719-559.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01ugf00U1NSBwg1tOE2_!!6000000001568-2-tps-306-375.png',

        // 散步
        'https://img.alicdn.com/imgextra/i1/O1CN01Vs80OE1WRZxNdZfby_!!6000000002785-2-tps-1125-406.png',
        'https://img.alicdn.com/imgextra/i4/O1CN01DKQEQM1YWHFddCIJ1_!!6000000003066-2-tps-575-772.png',
        'https://img.alicdn.com/imgextra/i4/O1CN01TXvZiW1udX4YBZPHe_!!6000000006060-2-tps-276-625.png',
        'https://img.alicdn.com/imgextra/i4/O1CN01Puh7Pk1GsZRiqE0AG_!!6000000000678-2-tps-1066-842.png',

        // 预警
        'https://img.alicdn.com/imgextra/i4/O1CN01oHkPVB291ibrbii7B_!!6000000008008-2-tps-450-488.png',
        'https://img.alicdn.com/imgextra/i3/O1CN01Q4jXvo1nBrq2TI4Ll_!!6000000005052-2-tps-258-285.png',
        'https://img.alicdn.com/imgextra/i2/O1CN01vHHjJZ1T0bmvjZ0q7_!!6000000002320-2-tps-357-146.png',
        //  烤串
        // 'https://img.alicdn.com/imgextra/i4/O1CN01gLC8MX1LKHsLftVVf_!!6000000001280-2-tps-459-435.png',
        // 'https://img.alicdn.com/imgextra/i4/O1CN01ogwOQY1TManYAdJqN_!!6000000002368-2-tps-387-457.png',
        // 'https://img.alicdn.com/imgextra/i1/O1CN01ETNAr31JCNoHzSJOJ_!!6000000000992-2-tps-92-92.png',
        // 'https://img.alicdn.com/imgextra/i1/O1CN011enouN1FTYC3yVNSn_!!6000000000488-2-tps-65-65.png',
        // 'https://img.alicdn.com/imgextra/i1/O1CN01OuTzQS23W390zwLob_!!6000000007262-2-tps-164-149.png',


        'https://img.alicdn.com/imgextra/i3/O1CN01cowzo01nzn10dukDg_!!6000000005161-2-tps-294-731.png',
        'https://img.alicdn.com/imgextra/i4/O1CN016HS5d41DSyTFPhNJf_!!6000000000216-2-tps-257-709.png',
        'https://img.alicdn.com/imgextra/i4/O1CN01GJkL6I24SDS0UCelN_!!6000000007389-2-tps-798-457.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01QdqH2v1azhwKzJPI2_!!6000000003401-2-tps-912-447.png',


        'https://img.alicdn.com/imgextra/i1/O1CN01YfmSbX26m1oUKPptA_!!6000000007703-0-tps-1125-531.jpg',

        // 'https://img.alicdn.com/imgextra/i4/O1CN01mmXLZ129jEEyYxbBn_!!6000000008103-0-tps-1125-2001.jpg',
        // 'https://img.alicdn.com/imgextra/i1/O1CN01bRwgEB1Kzg3oFIKkT_!!6000000001235-0-tps-1125-2010.jpg',
        'https://img.alicdn.com/imgextra/i4/O1CN01pe1uh51hUkTZHTfDO_!!6000000004281-0-tps-1125-2001.jpg',
        //        'https://img.alicdn.com/imgextra/i1/O1CN01Ff7JQo1ffAFKpEk06_!!6000000004033-0-tps-1125-2437.jpg',
        // 'https://img.alicdn.com/imgextra/i2/O1CN019Kj1RG1XMpSrKGZ7t_!!6000000002910-0-tps-1125-2436.jpg',
        // 'https://img.alicdn.com/imgextra/i2/O1CN01iu5Nwq1zXBf7SZGeh_!!6000000006723-0-tps-1125-2436.jpg',

        'https://img.alicdn.com/imgextra/i4/O1CN01wfLby31QBC5n0p7kl_!!6000000001937-2-tps-200-200.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01H0tY1P1PAuE7M9xzG_!!6000000001801-2-tps-120-120.png',
        'https://img.alicdn.com/imgextra/i3/O1CN012JuUpg1otfKKTTgMw_!!6000000005283-2-tps-214-217.png',

        'https://img.alicdn.com/imgextra/i3/O1CN01qgNv0J1exCDt1Iq00_!!6000000003937-2-tps-67-44.png',
        // 'https://img.alicdn.com/imgextra/i3/O1CN01iZknTZ1BwATeNPeAi_!!6000000000009-2-tps-105-60.png',
        'https://img.alicdn.com/imgextra/i3/O1CN01a3jZhd1DACDzx300Z_!!6000000000175-2-tps-229-60.png',


        'https://img.alicdn.com/imgextra/i2/O1CN019B8YTZ1wXhFrv73Ur_!!6000000006318-2-tps-139-349.png',
        'https://img.alicdn.com/imgextra/i3/O1CN01ugX01p1YkTX3bbYfp_!!6000000003097-2-tps-487-641.png',
        'https://img.alicdn.com/imgextra/i3/O1CN01Bxfjcb1sxZA8YxzsO_!!6000000005833-2-tps-757-356.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01IXm8XY1UcuWVlPbjt_!!6000000002539-2-tps-599-414.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01VtVXUI1pqI1W76jDH_!!6000000005411-2-tps-164-204.png',
        'https://img.alicdn.com/imgextra/i2/O1CN01poWagS1wpYhYFxeJM_!!6000000006357-2-tps-178-209.png',

        'https://img.alicdn.com/imgextra/i2/O1CN01tMwdo61kyvPmLuvVI_!!6000000004753-2-tps-2417-1374.png',
        'https://img.alicdn.com/imgextra/i2/O1CN01FoD5ze23Q5zatUOnW_!!6000000007249-2-tps-515-471.png',
        // 'https://img.alicdn.com/imgextra/i2/O1CN01Z9x3Zk1LqnzmbZzrt_!!6000000001351-2-tps-1288-1221.png',
        // 'https://img.alicdn.com/imgextra/i2/6000000001987/O1CN01Gejn2D1QY5tWwM212_!!6000000001987-0-tbvideo.jpg',
        'https://res.xiaoxxx.com/urlGather/20231211d43b79f9074aefde936965.png',
        'https://res.xiaoxxx.com/urlGather/20231211119ab2658f4a8c94999418.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01a9CBjM25pP6vCBLlS_!!6000000007575-2-tps-296-605.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01HBL4h429V1xEAaIax_!!6000000008072-2-tps-199-498.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01NHwYzM1NcGefnyIvB_!!6000000001590-2-tps-429-429.png',
        'https://img.alicdn.com/imgextra/i3/O1CN01rgGEAz1u0bP0PFo0q_!!6000000005975-2-tps-94-32.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01FpR5kS1BxXfAGWBRD_!!6000000000012-2-tps-107-150.png',
        'https://img.alicdn.com/imgextra/i4/O1CN01t5KPaH1tlwj7SIUMp_!!6000000005943-2-tps-50-17.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01FpR5kS1BxXfAGWBRD_!!6000000000012-2-tps-107-150.png',
        'https://img.alicdn.com/imgextra/i4/O1CN01t5KPaH1tlwj7SIUMp_!!6000000005943-2-tps-50-17.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01DamkFB1G56fRSax4Z_!!6000000000570-2-tps-313-362.png',
        // 首页
        'https://img.alicdn.com/imgextra/i4/O1CN01K7xHee1LEn7OOTrHT_!!6000000001268-0-tps-1125-2436.jpg',
        'https://img.alicdn.com/imgextra/i4/O1CN01K7xHee1LEn7OOTrHT_!!6000000001268-0-tps-1125-2436.jpg',
        'https://img.alicdn.com/imgextra/i4/O1CN01nSTzGF1v1LfCq0o7O_!!6000000006112-2-tps-264-271.png',
        'https://img.alicdn.com/imgextra/i1/O1CN01Rgfsbg1TYxUJY0ySw_!!6000000002395-2-tps-363-527.png',
        'https://img.alicdn.com/imgextra/i2/O1CN01EhyMsQ26ip2RXY3Jh_!!6000000007696-2-tps-998-1079.png',
        'https://img.alicdn.com/imgextra/i2/O1CN01Uma56p1DHyxI2ftIR_!!6000000000192-2-tps-813-151.png',
        // 'https://img.alicdn.com/imgextra/i2/O1CN01EjeRqg29J7edExgsU_!!6000000008046-2-tps-193-60.png',
        'https://img.alicdn.com/imgextra/i3/O1CN01f9CiDE1YdbaTttN84_!!6000000003082-2-tps-711-133.png',
        // 'https://img.alicdn.com/imgextra/i4/O1CN01p4DLO828iwMr04plO_!!6000000007967-0-tps-1125-2437.jpg',
      ]
      let $this = this
      $this.imgl = imgs.length
      for (let img of imgs) {
        let image = new Image();
        image.src = img;
        image.onload = () => {
          $this.count++;
          let pers = Math.floor(($this.count / $this.imgl) * 40)
          // console.log(pers)
          this.pre = pers
        };
      }
    },
  },


};
</script>
<style>
.promptCopy {
  font-size: 0.35rem;
  position: absolute;
  bottom: 1rem;
  width: 100%;
  text-align: center;
  color: rgb(255, 255, 255);
  font-family: nameR;
  animation: loadings_as1 1s 0s linear infinite;
}

.promptCopy span {
  /* color: #f9833a; */
}

@keyframes loadings_as1 {
  0% {
    opacity: 1;
  }

  20% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.loadings {
  animation: loadings_a 1s 0s linear forwards;


}

@keyframes loadings_a {
  0% {

    opacity: 1;


  }

  100% {
    opacity: 0;
  }
}
</style>
<style scoped>
.perc {
  font-size: 0.35rem;
  position: absolute;
  width: 5rem;
  transform: translate(-50%);
  left: 50%;
  text-align: center;
  top: 6.5rem;
  color: #ffffff;
  line-height: 0.46rem;
  font-family: nameR;
  letter-spacing: 0.02rem;
}



.percentage {
  font-style: italic;
  font-weight: lighter;

  font-size: 0.4rem;
  position: absolute;
  width: 5rem;
  transform: translate(-50%);
  left: 50%;
  text-align: center;
  top: 8.2rem;
  color: #ffffff;

}

.progres {
  position: absolute;
  width: 4.94rem;
  height: 0.24rem;
  /* background-color: #00DBDE; */
  background-image: linear-gradient(90deg, #ff0000 30%, #f08674 100%);

  border-radius: 0.35rem;
  top: 0;
  left: -100%;
  /* transform: translate(1%); */
}

.prog {
  position: absolute;
  width: 4.94rem;
  height: 0.24rem;
  background-color: rgb(255, 255, 255);
  border-radius: 0.35rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.progk {
  width: 5rem;
  height: 0.3rem;
  background-color: #fff;
  position: absolute;
  top: 7.3rem;
  left: 50%;
  transform: translate(-50%);
  border-radius: 0.35rem;
  /* padding: 0.01rem; */

}

.title {
  position: absolute;
  width: 3.5rem;
  left: 50%;
  transform: translate(-50%);
  top: 3rem;
}

.logo {
  position: absolute;
  width: 2.88rem;
  left: 50%;
  transform: translate(-50%);
  top: 1rem;
}

.bgz {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  height: 13.34rem;
  transform: translate(-50%, -50%);
  /* overflow: auto; */
}

.bg {
  /* 
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: 13.34rem;
  background-size: cover;
  background-position: center; */
  /* background-color: #0093E9;
  background-image: linear-gradient(135deg, #BDC4F7 0%, #f3e7e5 50%, #F5EF8D 100%); */

  background: url('https://img.alicdn.com/imgextra/i2/O1CN016BGmT526V59beEZFr_!!6000000007666-0-tps-1125-2436.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #FA574E;
  position: fixed;
  width: 7.5rem;
  height: 100%;
  top: 0;
  left: calc(50% - 3.75rem);
  /* z-index: 2; */
  overflow: hidden;
  min-height: 13.34rem;
  z-index: 9;
}

.loader-03 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.3rem;
  height: 0.3rem;
  /* font-weight: bold; */
  border: 0.05rem solid currentcolor;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: 1s loader-03 linear infinite;
  color: #82C6F4;
  /* position: relative; */
}

@keyframes loader-03 {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.loadingsa {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  position: absolute;
  top: 0.5rem;
  background-color: #FAFAFA;
  left: 50%;
  transform: translate(-50%);
  box-shadow: 0 0 0.1rem 0.01rem rgba(49, 49, 49, 0.2);
}

.jzmusic {
  position: fixed;
  width: 7.5rem;
  height: 100%;
  top: 0;
  left: calc(50% - 3.75rem);
  /* z-index: 2; */
  overflow: hidden;
  min-height: 13.34rem;
  z-index: 100;
  background-color: #ffffff;

}</style>