import { createStore } from 'vuex'

export default createStore({
  state: {
    showLast:false,
  },
  getters: {
  },
  mutations: {
    shows(a,b){
      // console.log(this.state);
      // console.log(a,b);
      this.state.showLast=b

    },
  },
  actions: {
  },
  modules: {
  }
})
